import {
	PlanningTable,
	PlanningTableContainer,
} from "src/features/planning/components/PlanningTable";
import { NotConnectedCompanyInfo } from "./NotConnectedCompanyInfo";
import { useTranslation } from "react-i18next";
import { invariant } from "src/common/utils/invariant";
import { Text } from "components/typography/Text.tsx";
import { QueriesResult } from "components/queryResult/QueriesResult";
import { useCurrentCompanyDomain } from "src/common/company-domain/useCurrentCompanyDomain";
import type { MergeSystem } from "src/common/service/fusionHub/types";
import {
	useGetMergeAccountingSystemsQuery,
	useGetMergeAccountingTokensAggregatedQuery,
} from "src/common/service/fusionHub/integrations/mergeAccountingApi";

type Props = {
	system: MergeSystem;
};
export const NotConnectedMergeAccountingDataSourcesTable = ({
	system,
}: Props) => {
	const { t } = useTranslation();

	const { companyDomain } = useCurrentCompanyDomain();

	return (
		<QueriesResult
			queries={{
				mergeAccountingTokensAggregated:
					useGetMergeAccountingTokensAggregatedQuery({
						companyDomainId: companyDomain.id,
					}),
				mergeAccountingSystems: useGetMergeAccountingSystemsQuery(),
			}}
			render={({
				mergeAccountingSystems: { systems },
				mergeAccountingTokensAggregated: { tokens },
			}) => {
				const unconnectedCompanyInfosWithToken = tokens
					.filter(({ token }) => token.system === system.slug)
					.flatMap(({ companyInfos, integrationConfigs, token }) => {
						return companyInfos
							.filter(
								(companyInfo) =>
									!integrationConfigs.some(
										(config) => config.companyInfoMergeId === companyInfo.id,
									),
							)
							.map((companyInfo) => ({
								companyInfo,
								token,
								system: systems.find((system) => system.slug === token.system),
							}));
					});

				if (unconnectedCompanyInfosWithToken.length === 0) {
					return null;
				}

				return (
					<>
						<Text size="lg" className="mx-2 mb-4">
							{t("Unconnected companies")}
						</Text>
						<PlanningTableContainer>
							<PlanningTable aria-label={t("Unconnected companies")}>
								<thead>
									<tr>
										<th className="whitespace-nowrap">{t("Name")}</th>
										<th className="whitespace-nowrap">{t("Type")}</th>
										<th className="w-0" />
									</tr>
								</thead>
								<tbody>
									{unconnectedCompanyInfosWithToken.map(
										({ token, companyInfo, system }) => {
											invariant(system);
											return (
												<NotConnectedCompanyInfo
													token={token}
													companyInfo={companyInfo}
													key={token.id}
													system={system}
												/>
											);
										},
									)}
								</tbody>
							</PlanningTable>
						</PlanningTableContainer>
					</>
				);
			}}
		/>
	);
};
