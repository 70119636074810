import { createContext, useMemo } from "react";
import type {
	BaseQueryFn,
	TypedUseQueryHookResult,
	TypedUseMutationResult,
	FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import type { SerializedError } from "@reduxjs/toolkit";
import { useLegalEntityHierarchy } from "../../common/legal-entities/useLegalEntityHierarchy.js";
import { useCurrentLegalEntityOrThrow } from "../../common/legal-entities/useCurrentLegalEntity.js";
import { invariant } from "../../common/utils/invariant.js";
import { useLocalStorageState } from "src/common/utils/hooks/useLocalStorageState.js";
import type { LegalEntityEntity } from "src/common/service/nexus/types.js";
import type {
	HierarchyType,
	CreateEntityHierarchyGroup,
	UpdateEntityHierarchyGroup,
	EntityHierarchy,
	EntityHierachyResponse,
	EntityHierarchyGroup,
} from "src/common/service/nexus/utils/hierarchy.js";

const getItemsMapFromHierarchy = (
	hierarchy: EntityHierarchy<LegalEntityEntity, any>[],
	map: Record<string, LegalEntityEntity>,
) => {
	for (const group of hierarchy) {
		for (const item of group.items) {
			map[item.id] = item;
		}
		getItemsMapFromHierarchy(group.children, map);
	}
	return map;
};

type MutationArgs<G> = {
	companyDomainId: string;
	legalEntityId: string;
	group: G;
	type: HierarchyType;
};

type CreateMutationArgs<A> = MutationArgs<CreateEntityHierarchyGroup<A>>;

type UpdateMutationArgs<A> = MutationArgs<UpdateEntityHierarchyGroup<A>> & {
	groupId: string;
};

type DeleteMutationArgs = {
	companyDomainId: string;
	legalEntityId: string;
	groupId: string;
	type: HierarchyType;
};

type MutationResult<T extends LegalEntityEntity, A> =
	| { data: EntityHierarchy<T, A> }
	| { error: FetchBaseQueryError | SerializedError };

type HierarchyQueryOptions = {
	type: HierarchyType;
	used?: true | undefined;
};

type EntityHierarchyContextValue<
	T extends LegalEntityEntity = LegalEntityEntity,
	A = Record<string, never>,
> = {
	useHierarchyQuery: (
		options?: Partial<HierarchyQueryOptions>,
	) => TypedUseQueryHookResult<
		EntityHierachyResponse<T, A>,
		{
			companyDomainId: string;
			legalEntityId: string;
		} & HierarchyQueryOptions,
		BaseQueryFn
	>;
	createGroupMutation: readonly [
		(args: CreateMutationArgs<A>) => Promise<MutationResult<T, A>>,
		TypedUseMutationResult<
			EntityHierarchy<T, A>,
			CreateMutationArgs<A>,
			BaseQueryFn
		>,
	];
	updateGroupMutation: readonly [
		(args: UpdateMutationArgs<A>) => Promise<MutationResult<T, A>>,
		TypedUseMutationResult<
			EntityHierarchy<T, A>,
			UpdateMutationArgs<A>,
			BaseQueryFn
		>,
	];
	removeGroupMutation: readonly [
		(args: DeleteMutationArgs) => Promise<MutationResult<T, A>>,
		TypedUseMutationResult<
			EntityHierarchy<T, A>,
			DeleteMutationArgs,
			BaseQueryFn
		>,
	];
	getDefaultGroupAttributes?: (params: {
		parentNode: EntityHierarchyGroup<T, A> | null;
	}) => A;
	renderGroupAttributesCreate?: (params: {
		attributes: A;
		parentNode: EntityHierarchyGroup<T, A> | null;
		onChange: (attributes: A) => void;
		attributeErrors: Partial<Record<keyof A, string>> | null;
	}) => React.ReactNode;
	renderGroupAttributesUpdate?: (params: {
		attributes: A;
		parentNode: EntityHierarchyGroup<T, A> | null;
		onChange: (attributes: A) => void;
		attributeErrors: Partial<Record<keyof A, string>> | null;
	}) => React.ReactNode;
	validateAttributes?: (
		attributes: A,
	) => Partial<Record<keyof A, string>> | null;
	canHaveChildren: (group: EntityHierarchyGroup<T, A>) => boolean;
	canHaveItems: (group: EntityHierarchyGroup<T, A>) => boolean;
	canBeChildOf: (
		group: EntityHierarchyGroup<T, A>,
		parentGroup: EntityHierarchyGroup<T, A>,
	) => boolean;
	labels: {
		entityName: string;
		entityNamePlural: string;
	};
	entityColumns: { key: keyof T; label: string }[];
	getItemLabel: (item: T) => string;
	getGroupLabel?: (group: EntityHierarchy<T, A>) => React.ReactNode;
	type: HierarchyType;
	parentLegalEntityId: string;
	filterUsed: boolean;
	setFilterUsed: (value: boolean) => void;
	hierarchyItemsMap: Record<string, T> | undefined;
	usedHierarchyItemsMap: Record<string, T> | undefined;
	isLoading: boolean;
};

export const EntityHierarchyContext =
	createContext<EntityHierarchyContextValue | null>(null);

type PropsToContext<T extends LegalEntityEntity, A> = Pick<
	EntityHierarchyContextValue<T, A>,
	| "createGroupMutation"
	| "updateGroupMutation"
	| "removeGroupMutation"
	| "labels"
	| "entityColumns"
	| "getItemLabel"
	| "getGroupLabel"
	| "type"
	| "getDefaultGroupAttributes"
	| "renderGroupAttributesCreate"
	| "renderGroupAttributesUpdate"
	| "validateAttributes"
> &
	Partial<
		Pick<
			EntityHierarchyContextValue<T, A>,
			"canHaveChildren" | "canHaveItems" | "canBeChildOf"
		>
	> & {
		useHierarchyQuery: (params: {
			companyDomainId: string;
			legalEntityId: string;
			type: HierarchyType;
			used?: boolean | undefined;
		}) => TypedUseQueryHookResult<
			EntityHierachyResponse<T, A>,
			{
				companyDomainId: string;
				legalEntityId: string;
			},
			BaseQueryFn
		>;
	};

export type Props<T extends LegalEntityEntity, A> = PropsToContext<T, A> & {
	children: React.ReactNode;
};

export const EntityHierarchyProvider = <T extends LegalEntityEntity, A>({
	children,
	useHierarchyQuery,
	...rest
}: Props<T, A>) => {
	const { hierarchy } = useLegalEntityHierarchy();

	const { legalEntity } = useCurrentLegalEntityOrThrow();

	const parentLegalEntityId =
		rest.type === "legalEntity"
			? legalEntity.id
			: hierarchy?.hierarchy?.legalEntity.id;

	invariant(parentLegalEntityId);

	const [filterUsed, setFilterUsed] = useLocalStorageState<boolean>(
		"hierarchyFilterUsed",
		false,
		{
			serializer: JSON.stringify,
			deserializer: JSON.parse,
		},
	);

	const hierarchyQueryParams = {
		companyDomainId: legalEntity.companyDomainId,
		legalEntityId: parentLegalEntityId,
		type: rest.type,
	};

	const usedHierarchyQuery = useHierarchyQuery({
		...hierarchyQueryParams,
		used: true,
	});

	const hierarchyQuery = useHierarchyQuery({
		...hierarchyQueryParams,
	});

	const hierarchyItemsMap = useMemo(() => {
		if (hierarchyQuery.isLoading || !hierarchyQuery.data) {
			return undefined;
		}

		const map: Record<string, LegalEntityEntity> = getItemsMapFromHierarchy(
			hierarchyQuery.data.hierarchy,
			{},
		);

		for (const availableItem of hierarchyQuery.data.availableItems) {
			map[availableItem.id] = availableItem;
		}

		return map;
	}, [hierarchyQuery.data, hierarchyQuery.isLoading]);

	const usedHierarchyItemsMap = useMemo(() => {
		if (usedHierarchyQuery.isLoading || !usedHierarchyQuery.data) {
			return undefined;
		}

		const map: Record<string, LegalEntityEntity> = getItemsMapFromHierarchy(
			usedHierarchyQuery.data.hierarchy,
			{},
		);

		for (const availableItem of usedHierarchyQuery.data.availableItems) {
			map[availableItem.id] = availableItem;
		}

		return map;
	}, [usedHierarchyQuery.data, usedHierarchyQuery.isLoading]);

	return (
		<EntityHierarchyContext.Provider
			value={
				{
					...rest,
					canHaveChildren: rest.canHaveChildren ?? (() => true),
					canHaveItems: rest.canHaveItems ?? (() => true),
					canBeChildOf: rest.canBeChildOf ?? (() => true),
					parentLegalEntityId,
					useHierarchyQuery: (options?: HierarchyQueryOptions) =>
						useHierarchyQuery({
							...hierarchyQueryParams,
							...options,
						}),
					hierarchyItemsMap,
					usedHierarchyItemsMap,
					filterUsed,
					setFilterUsed,
					isLoading: hierarchyQuery.isLoading || usedHierarchyQuery.isLoading,
				} as unknown as EntityHierarchyContextValue
			}
		>
			{children}
		</EntityHierarchyContext.Provider>
	);
};
