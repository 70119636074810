import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Input } from "../../../common/components/atoms/input/Input.js";
import { Button } from "../../../common/components/atoms/button/Button.js";
import { useCurrentCompanyDomain } from "../../../common/company-domain/useCurrentCompanyDomain.js";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../../common/components/atoms/notifications/events.js";
import { useEffect, useId, useMemo, useState } from "react";
import type { LegalEntityEntity } from "../../../common/service/nexus/types.js";
import { Label } from "../../../common/components/atoms/label/Label.js";
import { useEntityHierarchy } from "../entityHierarchyProviderUtils";
import type {
	EntityHierarchy,
	EntityHierarchyGroup,
} from "src/common/service/nexus/utils/hierarchy.js";
import { Dialog } from "components/dialog/Dialog.js";
import { DialogContent } from "components/dialog/DialogContent.js";
import { DialogTitle } from "components/dialog/DialogTitle.js";
import { DialogActions } from "components/dialog/DialogActions.js";
import { DialogBody } from "components/dialog/DialogBody.js";
import { mergeRefs } from "src/common/utils/mergeRefs.js";

type Props = {
	parent?: EntityHierarchy<LegalEntityEntity, Record<string, never>> | null;
	group?: EntityHierarchyGroup;
	position?: number;
	isOpen: boolean;
	onClose: () => void;
};

export const EditGroupModal = ({
	parent,
	group,
	position,
	isOpen,
	onClose,
}: Props) => {
	const { companyDomain } = useCurrentCompanyDomain();

	const {
		createGroupMutation: [createGroup],
		updateGroupMutation: [updateGroup],
		parentLegalEntityId: legalEntityId,
		type,
		getDefaultGroupAttributes,
		renderGroupAttributesCreate,
		renderGroupAttributesUpdate,
		validateAttributes,
	} = useEntityHierarchy();

	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting, isValid },
		reset,
	} = useForm({
		defaultValues: {
			name: group?.name ?? "",
		},
	});

	useEffect(() => {
		if (isOpen) {
			reset({
				name: group?.name ?? "",
			});
		}
	}, [group?.name, isOpen, reset]);

	const [attributes, setAttributes] = useState(
		group
			? group.attributes
			: getDefaultGroupAttributes?.({
					parentNode: parent ?? null,
				}),
	);

	useEffect(() => {
		if (group) {
			setAttributes(group.attributes);
		} else {
			setAttributes(
				getDefaultGroupAttributes?.({
					parentNode: parent ?? null,
				}),
			);
		}
	}, [getDefaultGroupAttributes, group, parent]);

	const attributeErrors = useMemo(() => {
		if (!validateAttributes || !attributes) {
			return null;
		}
		return validateAttributes(attributes);
	}, [attributes, validateAttributes]);

	const formId = useId();
	const { ref: fieldRef, ...field } = register("name", {
		required: t("This field is required"),
	});

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogContent>
				{({ close, initialElementRef }) => {
					return (
						<>
							<DialogTitle>
								{group?.id ? t("Edit group") : t("Create group")}
							</DialogTitle>
							<DialogBody>
								<form
									className="pb-1"
									id={formId}
									onSubmit={handleSubmit(async (data) => {
										if (attributeErrors) return;

										if (group?.id) {
											const result = await updateGroup({
												companyDomainId: companyDomain.id,
												legalEntityId,
												groupId: group.id,
												type,
												group: {
													parentId: parent?.id ?? null,
													name: data.name,
													itemIds: group.items.map((item) => item.id),
													attributes: attributes ?? {},
												},
											});

											if ("error" in result) {
												showErrorNotification({
													message: t("Failed to update group"),
												});
											} else {
												showSuccessNotification({
													message: t("Group updated"),
												});
												close();
											}
										} else {
											const result = await createGroup({
												companyDomainId: companyDomain.id,
												legalEntityId,
												type,
												group: {
													name: data.name,
													parentId: parent?.id ?? null,
													sortOrder:
														(position ?? parent?.children.length ?? 0) * 1000 +
														1000,
													itemIds: [],
													attributes: attributes ?? {},
												},
											});

											if ("error" in result) {
												showErrorNotification({
													message: t("Failed to create group"),
												});
											} else {
												showSuccessNotification({
													message: t("Group created"),
												});
												close();
											}
										}
									})}
								>
									<Label htmlFor="name">{t("Group name")}</Label>
									<Input
										id="name"
										{...field}
										ref={mergeRefs([initialElementRef, fieldRef])}
										hint={errors.name?.message}
										aria-invalid={errors.name !== undefined}
									/>
									{attributes && (
										<div className="mt-4">
											{group
												? renderGroupAttributesUpdate?.({
														parentNode: parent ?? null,
														attributes,
														onChange: setAttributes,
														attributeErrors,
													})
												: renderGroupAttributesCreate?.({
														parentNode: parent ?? null,
														attributes,
														onChange: setAttributes,
														attributeErrors,
													})}
										</div>
									)}
								</form>
							</DialogBody>
							<DialogActions withCancelButton>
								<Button
									type="submit"
									form={formId}
									variant="primary"
									isLoading={isSubmitting}
									disabled={!isValid}
								>
									{t("Save")}
								</Button>
							</DialogActions>
						</>
					);
				}}
			</DialogContent>
		</Dialog>
	);
};
