import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { calcPipelineProgressPercent } from "./utils/calcPipelineProgressPercent";
import { getCurrentPipeline } from "../../common/utils/PipelineHelper";
import { IconCircleCheck, IconCircleX } from "@tabler/icons-react";
import { Text } from "../../common/components/atoms/typography/Text";
import { ProgressCircle } from "../../common/components/atoms/progressCircle/ProgressCircle";
import {
	useGetCompanyQuery,
	useGetPipelineRunsQuery,
} from "../../common/redux/api/exopenApi";
import { useLocale } from "src/locales/useLocale";
import { useCurrentCompanyDomainId } from "src/common/company-domain/useCurrentCompanyDomain.ts";

export const PipeLineProgressLoader = () => {
	const { companyDomainId } = useCurrentCompanyDomainId();

	const { data: pipelineRuns } = useGetPipelineRunsQuery(
		{ companyId: companyDomainId },
		{
			pollingInterval: 60 * 1000,
		},
	);

	const { data: companyInfo } = useGetCompanyQuery({
		companyId: companyDomainId,
	});

	const [loadPercent, setLoadPercent] = useState(0);
	const { t } = useTranslation();

	const currentPipeline = useMemo(
		() => getCurrentPipeline(pipelineRuns),
		[pipelineRuns],
	);

	const { formatRelative } = useLocale();

	useEffect(() => {
		if (currentPipeline && currentPipeline.status === "InProgress")
			setLoadPercent(calcPipelineProgressPercent(currentPipeline, 1));
	}, [currentPipeline]);

	if (!currentPipeline || !companyInfo || companyInfo.pumps.length === 0) {
		return null;
	}

	if (
		currentPipeline.status === "InProgress" ||
		currentPipeline.status === "Failed" ||
		currentPipeline.status === "Succeeded"
	) {
		return (
			<div className="mb-2 flex items-center gap-4 rounded-[8px] bg-grey-50 p-3">
				{currentPipeline.status === "Succeeded" ? (
					<>
						<div className="shrink-0">
							<IconCircleCheck className="text-primary-500" size={20} />
						</div>
						<Text size="xs" weight="regular" color="text-grey-600">
							{t("Data was fetched")}{" "}
							{currentPipeline.runEnd
								? formatRelative(currentPipeline.runEnd)
								: null}
						</Text>
					</>
				) : currentPipeline.status === "Failed" ? (
					<>
						<div className="shrink-0">
							<IconCircleX className="text-error-500" size={20} />
						</div>
						<Text size="xs" weight="regular" color="text-grey-600">
							{t("Last data fetch failed")}{" "}
							{formatRelative(currentPipeline.lastUpdated)}
						</Text>
					</>
				) : (
					<>
						<div className="shrink-0">
							<ProgressCircle size="sm" value={loadPercent} label={false} />
						</div>
						<Text size="xs" weight="regular" color="text-grey-600">
							{t("We are collecting data")}... ({loadPercent}%)
						</Text>
					</>
				)}
			</div>
		);
	}

	return null;
};
