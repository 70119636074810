import type { EntityHierarchy } from "src/common/service/nexus/utils/hierarchy.js";
import type { LegalEntityEntity } from "../../../common/service/nexus/types.js";

export type GroupWithParent = EntityHierarchy<
	LegalEntityEntity,
	Record<string, never>
> & {
	parent: GroupWithParent | null;
};

export const extractGroupsFromHierarchy = <T extends LegalEntityEntity>(
	hierarchy: EntityHierarchy<T, Record<string, never>>[],
	parent: GroupWithParent | null = null,
): GroupWithParent[] => {
	const groups: GroupWithParent[] = [];

	groups.push(
		...hierarchy.flatMap((hierarchy) => {
			const group: GroupWithParent = {
				...hierarchy,
				parent,
			};

			return [
				group,
				...hierarchy.children.flatMap((h) =>
					extractGroupsFromHierarchy([h], group),
				),
			];
		}),
	);

	return groups;
};

export const getGroupPath = (groupWithParent: GroupWithParent) => {
	const paths = [];

	let parent = groupWithParent.parent;

	while (parent) {
		paths.push(parent);
		parent = parent.parent;
	}

	return paths.reverse();
};
