import type { PipelineResponse } from "../../../common/service/pipeline";

const calcPercentProgress = (startDateTime: number, dateTimeLimit: number) => {
	const currentDateTime = Date.now();
	let maxLimit = dateTimeLimit;

	let percent = ((currentDateTime - startDateTime) / maxLimit) * 100;
	if (percent > 100) {
		// If percent goes above 100, double the maxLimit to keep below 100
		maxLimit *= Math.ceil(percent / 100);
		percent = ((currentDateTime - startDateTime) / maxLimit) * 100;
	}

	return Math.floor(percent);
};

export const calcPipelineProgressPercent = (
	pipelineData: PipelineResponse,
	maxHourLimit = 12,
) => {
	if (pipelineData.status === "InProgress") {
		const startDateTime = pipelineData.runStart.getTime();
		const dateTimeLimit = 1000 * 60 * 60 * maxHourLimit;
		const newPercent = calcPercentProgress(startDateTime, dateTimeLimit);
		return newPercent;
	}

	return 0;
};

// Tests: https://vitest.dev/guide/in-source.html

if (import.meta.vitest) {
	const { afterEach, expect, test, vi } = import.meta.vitest;

	const mockDateNow = (date: string) => {
		vi.spyOn(global.Date, "now").mockImplementation(() => {
			return new Date(date).valueOf();
		});
	};

	afterEach(() => {
		vi.restoreAllMocks();
	});

	test("calcPercentProgress Given max time is 1h when 30 minutes has passes, then percent should be 50", () => {
		mockDateNow("2021-11-10T03:30:00.0000000Z");
		const startDateTime = new Date("2021-11-10T03:00:00.0000000Z").valueOf();
		const dateTimeLimit = 60 * 60 * 1000;

		const result = calcPercentProgress(startDateTime, dateTimeLimit);

		expect(result).toBe(50);
	});

	test("calcPercentProgress Given max time is 1h when 1h and 30 minutes has passes, then percent should be 75", () => {
		mockDateNow("2021-11-10T04:30:00.0000000Z");
		const startDateTime = new Date("2021-11-10T03:00:00.0000000Z").valueOf();
		const dateTimeLimit = 60 * 60 * 1000;

		const result = calcPercentProgress(startDateTime, dateTimeLimit);

		expect(result).toBe(75);
	});

	test("calcPercentProgress Given max time is 1h when 1h and 15 minutes has passes, then percent should be 62", () => {
		mockDateNow("2021-11-10T04:15:00.0000000Z");
		const startDateTime = new Date("2021-11-10T03:00:00.0000000Z").valueOf();
		const dateTimeLimit = 60 * 60 * 1000;

		const result = calcPercentProgress(startDateTime, dateTimeLimit);

		expect(result).toBe(62);
	});

	test("calcPercentProgress Given max time is 1h when 1h and 45 minutes has passes, then percent should be 87", () => {
		mockDateNow("2021-11-10T04:45:00.0000000Z");
		const startDateTime = new Date("2021-11-10T03:00:00.0000000Z").valueOf();
		const dateTimeLimit = 60 * 60 * 1000;

		const result = calcPercentProgress(startDateTime, dateTimeLimit);

		expect(result).toBe(87);
	});

	test("calcPercentProgress Given max time is 1h when 2h and 30 minutes has passes, then percent should be 83", () => {
		mockDateNow("2021-11-10T05:30:00.0000000Z");
		const startDateTime = new Date("2021-11-10T03:00:00.0000000Z").valueOf();
		const dateTimeLimit = 60 * 60 * 1000;

		const result = calcPercentProgress(startDateTime, dateTimeLimit);

		expect(result).toBe(83);
	});
}
