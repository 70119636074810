import { type EntityState, createEntityAdapter } from "@reduxjs/toolkit";
import { emptyNexusApi } from "../../emptyNexusApi.js";
import type { PaginatedResponse } from "../../types.js";
import { loadAllPages } from "../../utils/loadAllPages.js";
import { buildHierarchyEndpoints } from "./hierarchyEndpoints.js";
import type { Account, AccountType, UpdateableAccount } from "./types.js";
import type {
	FetchBaseQueryError,
	FetchBaseQueryMeta,
	QueryReturnValue,
} from "@reduxjs/toolkit/query";

const nexusAccountAdapter = createEntityAdapter<Account>();
export const nexusAccountSelectors = nexusAccountAdapter.getSelectors();

const nexusAccountApi = emptyNexusApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllAccountsForLegalEntity: builder.query<
			EntityState<Account, string>,
			{
				companyDomainId: string;
				legalEntityId: string;
				accountType?: AccountType;
			}
		>({
			async queryFn(
				{ companyDomainId, legalEntityId, accountType },
				_api,
				_extraOptions,
				baseQuery,
			) {
				const result = await loadAllPages(({ take, skip }) => {
					return baseQuery({
						url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/account`,
						params: { type: accountType, take, skip },
					}) as Promise<
						QueryReturnValue<
							PaginatedResponse<Account>,
							FetchBaseQueryError,
							FetchBaseQueryMeta
						>
					>;
				});

				if ("error" in result) {
					return result;
				}

				return {
					...result,
					data: nexusAccountAdapter.addMany(
						nexusAccountAdapter.getInitialState(),
						result.data,
					),
				};
			},
			providesTags: (result, error) => {
				if (error ?? !result) {
					return ["Account"];
				}
				return [
					"Account",
					...result.ids.map((id) => ({
						type: "Account" as const,
						id: `${id}`,
					})),
				];
			},
		}),
		updateAccountForLegalEntity: builder.mutation<
			Account,
			{
				companyDomainId: string;
				legalEntityId: string;
				accountId: string;
				account: UpdateableAccount;
			}
		>({
			query: ({ companyDomainId, legalEntityId, accountId, account }) => ({
				url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/account/${accountId}`,
				method: "PUT",
				body: account,
			}),
		}),
		optimisticUpdateAccountForLegalEntity: builder.mutation<
			Account,
			{
				companyDomainId: string;
				legalEntityId: string;
				accountId: string;
				account: UpdateableAccount;
			}
		>({
			query: ({ companyDomainId, legalEntityId, accountId, account }) => ({
				url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/account/${accountId}`,
				method: "PUT",
				body: account,
			}),
			onQueryStarted: async (
				{ account, accountId, ...rest },
				{ dispatch, queryFulfilled },
			) => {
				// optimistic update logic
				dispatch(
					nexusAccountApi.util.updateQueryData(
						"getAllAccountsForLegalEntity",
						rest,
						(draft) => {
							nexusAccountAdapter.updateOne(draft, {
								id: accountId,
								changes: account,
							});
						},
					),
				);

				try {
					await queryFulfilled;
				} catch {
					dispatch(
						nexusAccountApi.util.invalidateTags([
							"Account",
							{ type: "Account", id: accountId },
						]),
					);
				}
			},
		}),
		...buildHierarchyEndpoints(builder),
	}),
});

export const {
	useGetAllAccountsForLegalEntityQuery,
	useUpdateAccountForLegalEntityMutation,
	useOptimisticUpdateAccountForLegalEntityMutation,
	useGetIncomeStatementHierarchyQuery,
	useGetAutoIncomeStatementHierarchyQuery,
	useGetIncomeStatementArrangementHierarchyQuery,
	useMigrateAutoIncomeStatementHierarchyMutation,
	useCreateIncomeStatementHierarchyGroupMutation,
	useUpdateIncomeStatementHierarchyGroupMutation,
	useRemoveIncomeStatementHierarchyGroupMutation,
	useGetBalanceSheetHierarchyQuery,
	useGetAutoBalanceSheetHierarchyQuery,
	useGetBalanceSheetArrangementHierarchyQuery,
	useMigrateAutoBalanceSheetHierarchyMutation,
	useCreateBalanceSheetHierarchyGroupMutation,
	useUpdateBalanceSheetHierarchyGroupMutation,
	useRemoveBalanceSheetHierarchyGroupMutation,
} = nexusAccountApi;
