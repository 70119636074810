import { useCallback } from "react";
import { IconCheck } from "@tabler/icons-react";
import { clsx } from "clsx";
import { PlanColorIndicator } from "../PlanColorIndicator.tsx";
import { Text } from "../../../../common/components/atoms/typography/Text.tsx";
import type { RenderSelectOption } from "../../../../common/components/atoms/select/Option.tsx";

export const useRenderComparisonPlanOption = (
	planById: Record<string, { float: number }>,
) => {
	return useCallback<RenderSelectOption>(
		({ isDisabled, isSelected, label, value }) => {
			const plan = planById[value];
			return (
				<>
					<PlanColorIndicator float={plan.float} className="mr-2" />
					<Text
						size="sm"
						className={clsx("grow truncate", isDisabled && "text-gray-300")}
					>
						{label}
					</Text>
					{isSelected && (
						<div className="shrink-0">
							<IconCheck className="text-purple-500" size={18} />
						</div>
					)}
				</>
			);
		},
		[planById],
	);
};
