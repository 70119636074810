import { lazy as reactLazy } from "react";
import { DynamicImportFailedError } from "./DynamicImportFailedError.tsx";

export const lazy = <T extends {}, U extends keyof T>(
	loader: (x?: string) => Promise<T>,
) =>
	new Proxy({} as unknown as T, {
		get: (_, componentName: string | symbol) => {
			if (typeof componentName === "string") {
				return reactLazy(async () => {
					try {
						const component = await loader(componentName);
						return {
							default: component[
								componentName as U
							] as any as React.ComponentType<any>,
						};
					} catch (e) {
						console.error(e);
						return { default: DynamicImportFailedError };
					}
				});
			}
		},
	});
