import {
	useState,
	useRef,
	type CSSProperties,
	type ForwardedRef,
	type MutableRefObject,
} from "react";
import { flushSync } from "react-dom";
import { mergeRefs } from "../../../utils/mergeRefs";
import { useDidUpdate } from "../../../utils/hooks/useDidUpdate";

function getAutoHeightDuration(height: number | string) {
	if (!height || typeof height === "string") {
		return 0;
	}
	const constant = height / 36;
	return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
}

function getElementHeight(
	el:
		| MutableRefObject<HTMLElement | null>
		| { current?: { scrollHeight: number } },
) {
	return el.current ? el.current.scrollHeight : "auto";
}

interface UseCollapseOptions {
	opened: boolean;
	transitionDuration?: number;
	transitionTimingFunction?: string;
}

interface GetCollapseProps {
	[key: string]: unknown;
	style?: CSSProperties | undefined;
	ref?: ForwardedRef<HTMLDivElement>;
}

export function useCollapse({
	transitionDuration,
	transitionTimingFunction = "ease",
	opened,
}: UseCollapseOptions): (props: GetCollapseProps) => Record<string, any> {
	const el = useRef<HTMLElement | null>(null);
	const collapsedHeight = "0px";
	const collapsedStyles = {
		display: "none",
		height: "0px",
		overflow: "hidden",
	};
	const [styles, setStylesRaw] = useState<CSSProperties>(
		opened ? {} : collapsedStyles,
	);
	const setStyles = (newStyles: {} | ((oldStyles: {}) => {})): void => {
		flushSync(() => setStylesRaw(newStyles));
	};

	const mergeStyles = (newStyles: {}): void => {
		setStyles((oldStyles) => ({ ...oldStyles, ...newStyles }));
	};

	function getTransitionStyles(height: number | string): {
		transition: string;
	} {
		const _duration = transitionDuration ?? getAutoHeightDuration(height);
		return {
			transition: `height ${_duration}ms ${transitionTimingFunction}`,
		};
	}

	useDidUpdate(() => {
		let isCanceled = false;
		if (opened) {
			window.requestAnimationFrame(() => {
				if (!isCanceled) {
					mergeStyles({
						willChange: "height",
						display: "block",
						overflow: "hidden",
					});
					window.requestAnimationFrame(() => {
						if (!isCanceled) {
							const height = getElementHeight(el);
							mergeStyles({ ...getTransitionStyles(height), height });
						}
					});
				}
			});
		} else {
			window.requestAnimationFrame(() => {
				if (!isCanceled) {
					const height = getElementHeight(el);
					mergeStyles({
						...getTransitionStyles(height),
						willChange: "height",
						height,
					});
					window.requestAnimationFrame(() => {
						if (!isCanceled) {
							mergeStyles({ height: collapsedHeight, overflow: "hidden" });
						}
					});
				}
			});
		}

		return () => {
			isCanceled = true;
		};
	}, [opened]);

	const handleTransitionEnd = (e: React.TransitionEvent): void => {
		if (e.target !== el.current || e.propertyName !== "height") {
			return;
		}

		if (opened) {
			const height = getElementHeight(el);

			if (height === styles.height) {
				setStyles({});
			} else {
				mergeStyles({ height });
			}
		} else if (styles.height === collapsedHeight) {
			setStyles(collapsedStyles);
		}
	};

	function getCollapseProps({ style = {}, ...rest }: GetCollapseProps = {}) {
		const theirRef = rest.ref;
		const refsToMerge: Parameters<typeof mergeRefs>["0"] = [el];
		if (theirRef !== undefined) {
			refsToMerge.push(theirRef);
		}
		return {
			"aria-hidden": !opened,
			...rest,
			ref: mergeRefs(refsToMerge),
			onTransitionEnd: handleTransitionEnd,
			style: { boxSizing: "border-box", ...style, ...styles },
		};
	}

	return getCollapseProps;
}
