import type { User } from "../service/Types/UserTypes.js";
import { COMPANYADMIN } from "../service/users.js";

export const isCompanyAdmin = (
	user: User | undefined,
	company?: number | string,
) => {
	return user?.accessGroups
		? user.accessGroups.some((role) => {
				return (
					role.companyId === Number(company) && role.name === COMPANYADMIN[0]
				);
			})
		: false;
};
