import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../../../common/components/atoms/input/Input.js";
import { Text } from "../../../common/components/atoms/typography/Text.js";
import {
	getGroupPath,
	type GroupWithParent,
} from "../utils/extractGroupsFromHierarchy.js";
import { Radio } from "../../../common/components/atoms/checkbox/Radio.js";
import { Pagination } from "../../../common/components/atoms/pagination/Pagination.js";
import { Chip } from "../../../common/components/atoms/chip/Chip.js";
import {
	PlanningTable2,
	PlanningTableContainer,
} from "../../planning/components/PlanningTable";

const ROWS_PER_PAGE = 20;

type Props = {
	groups: GroupWithParent[];
	isSearchable: boolean;
	isSelectable: boolean;
	selected?: GroupWithParent | null;
	onSelect?: (group: GroupWithParent | null) => void;
	onClick?: (group: GroupWithParent) => void;
};

export const HierarchyGroupTable = ({
	groups,
	isSearchable,
	isSelectable,
	selected,
	onSelect,
	onClick,
}: Props) => {
	const [filterTerm, setFilterTerm] = useState("");
	const [page, setPage] = useState(1);

	const { t } = useTranslation();

	const numberOfPages = Math.ceil(groups.length / ROWS_PER_PAGE);

	const filteredGroups = useMemo(
		() =>
			groups.filter((group) =>
				group.name.toLowerCase().includes(filterTerm.toLowerCase()),
			),
		[filterTerm, groups],
	);

	const hasPagination = filteredGroups.length > ROWS_PER_PAGE;

	return (
		<>
			<PlanningTableContainer>
				<PlanningTable2>
					<thead>
						<tr>
							{isSelectable && <PlanningTable2.Th className="w-0" />}
							<PlanningTable2.Th>{t("Group")}</PlanningTable2.Th>
							<PlanningTable2.Th>{t("Parent folders")}</PlanningTable2.Th>
						</tr>
						{(isSelectable || isSearchable) && (
							<tr>
								{isSelectable && <PlanningTable2.Th style={{ width: "5%" }} />}
								<PlanningTable2.Th>
									{isSearchable && (
										<Input
											placeholder={t("Filter in groups")}
											type="search"
											onChange={(e) => setFilterTerm(e.target.value)}
										/>
									)}
								</PlanningTable2.Th>
								<PlanningTable2.Th />
							</tr>
						)}
					</thead>
					<tbody>
						{filteredGroups.length === 0 ? (
							<tr>
								<PlanningTable2.Td colSpan={4} className="py-2">
									<Text size="sm">
										{t(
											'No match for "{{filterTerm}}" was found. Try another search',
											{
												filterTerm,
											},
										)}
									</Text>
								</PlanningTable2.Td>
							</tr>
						) : (
							filteredGroups
								.slice(
									(page - 1) * ROWS_PER_PAGE,
									(page - 1) * ROWS_PER_PAGE + ROWS_PER_PAGE,
								)
								.map((group) => {
									const inputId = `group-${group.id}`;
									const paths = getGroupPath(group);

									return (
										<tr key={group.id}>
											{isSelectable && (
												<PlanningTable2.Td className="w-0 py-2">
													<Radio
														checked={group.id === selected?.id}
														onChange={(e) =>
															onSelect?.(e.target.checked ? group : null)
														}
														value={group.id}
														id={inputId}
													/>
												</PlanningTable2.Td>
											)}
											<PlanningTable2.Td
												className="py-2"
												onClick={() => onClick?.(group)}
											>
												<label
													title={group.name}
													className={onClick ? "cursor-pointer" : ""}
													htmlFor={inputId}
												>
													{group.name}
												</label>
											</PlanningTable2.Td>
											<PlanningTable2.Td className="py-2">
												{paths.map((part, index) => (
													<Fragment key={index}>
														<Chip
															size="small"
															color="purple"
															className={onClick ? "cursor-pointer" : ""}
															onClick={() => onClick?.(part)}
														>
															{part.name}
														</Chip>
														{index < paths.length - 1 && " / "}
													</Fragment>
												))}
											</PlanningTable2.Td>
										</tr>
									);
								})
						)}
					</tbody>
				</PlanningTable2>
			</PlanningTableContainer>
			{hasPagination && (
				<Pagination totalPages={numberOfPages} page={page} onChange={setPage} />
			)}
		</>
	);
};
