import { useTranslation } from "react-i18next";
import { Divider } from "../../../common/components/atoms/divider/Divider.tsx";
import { Text } from "../../../common/components/atoms/typography/Text.tsx";
import { useEffect, useId } from "react";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../../common/components/atoms/input/Input.tsx";
import { Label } from "../../../common/components/atoms/label/Label.tsx";
import { useCurrentPlanOrThrow } from "../useCurrentPlan.ts";
import { Button } from "../../../common/components/atoms/button/Button.tsx";
import { Textarea } from "../../../common/components/atoms/input/Textarea.tsx";
import {
	useGetPlansQuery,
	useLazyCheckPlanNameQuery,
	useUpdatePlanMutation,
} from "../api/planApi.ts";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../../common/components/atoms/notifications/events.tsx";
import { ComparisonPlansSelector } from "../plans/CreatePlanPage/ComparisonPlansSelector.tsx";
import { QueriesResult } from "../../../common/components/atoms/queryResult/QueriesResult.tsx";
import { useGetCompanyModulesQuery } from "../api/planningApi.ts";
import { RHFModuleSelectableList } from "../components/RHFModuleSelectableList.tsx";
import { SwitchField } from "../../../common/components/atoms/checkbox/SwitchField.tsx";
import { HelpIcon } from "../../../common/components/atoms/help-icon/HelpIcon.tsx";
import { DeletePlanButton } from "../tasks/TaskDetailPage/delete-plan-button/DeletePlanButton.tsx";
import { usePersistedNavigate } from "../../../common/persistentRouterFunctions.tsx";
import { useLocale } from "../../../locales/useLocale.ts";

export const GeneralSettingsPage = () => {
	const plan = useCurrentPlanOrThrow();
	const planNameInputId = useId();
	const planDescriptionInputId = useId();
	const comparisonInputId = useId();
	const startDateInputId = useId();
	const endDateInputId = useId();
	const form = useForm({
		defaultValues: {
			name: plan.name,
			description: plan.description,
			comparisonPlans: plan.comparisonPlans.map(
				(comparisonPlan) => comparisonPlan.planId,
			),
			modules: plan.modules.map((module) => module.id),
			formatInThousand: plan.thousands,
		},
	});
	const { t } = useTranslation();
	const [updatePlan] = useUpdatePlanMutation();
	useEffect(() => {
		if (form.formState.isSubmitSuccessful) {
			form.reset({
				name: plan.name,
				description: plan.description,
				comparisonPlans: plan.comparisonPlans.map(
					(comparisonPlan) => comparisonPlan.planId,
				),
				modules: plan.modules.map((module) => module.id),
				formatInThousand: plan.thousands,
			});
		}
	}, [
		form,
		plan.comparisonPlans,
		plan.description,
		plan.modules,
		plan.name,
		plan.thousands,
	]);
	const [checkName] = useLazyCheckPlanNameQuery();
	const readOnly = plan.status === "approved" || plan.status === "done";
	const navigate = usePersistedNavigate();
	const { format } = useLocale();

	return (
		<>
			<h1 className="mb-4 grow">
				<Text size="lg" weight="bold" className="pb-2">
					{t("General")}
				</Text>
				<Divider />
			</h1>
			<QueriesResult
				queries={{
					plans: useGetPlansQuery({
						legalEntityId: plan.nexusLegalEntityId,
						companyDomainId: plan.companyId,
					}),
					companyModules: useGetCompanyModulesQuery({
						companyId: plan.companyId,
					}),
				}}
				render={({ plans, companyModules }) => {
					return (
						<form
							onSubmit={form.handleSubmit(async (data) => {
								const result = await updatePlan({
									companyDomainId: plan.companyId,
									legalEntityId: plan.nexusLegalEntityId,
									planId: plan.id,
									name: data.name,
									description: data.description,
									thousands: data.formatInThousand,
									comparisonPlans: data.comparisonPlans,
									modules: data.modules,
								});
								if ("error" in result) {
									showErrorNotification({ message: t("Something failed...") });
								} else {
									showSuccessNotification({
										message: t("The changes are saved"),
									});
								}
							})}
						>
							<Label htmlFor={planNameInputId}>{t("Plan Name")}</Label>
							<Input
								{...form.register("name", {
									required: t("This field is required"),
									async validate(value) {
										if (value === plan.name) {
											return;
										}
										const result = await checkName({
											companyDomainId: plan.companyId,
											legalEntityId: plan.nexusLegalEntityId,
											name: value,
										});
										if (!result.data) {
											return t(
												"The plan {{name}} already exist in this legal entity",
												{ name: value },
											);
										}
									},
								})}
								id={planNameInputId}
								containerProps={{ className: "mb-4 max-w-96" }}
								aria-invalid={form.formState.errors.name !== undefined}
								hint={form.formState.errors.name?.message}
								disabled={readOnly}
							/>
							<Label htmlFor={planDescriptionInputId} optional>
								{t("Plan Description")}
							</Label>
							<Textarea
								{...form.register("description")}
								id={planDescriptionInputId}
								className="mb-4"
								rows={4}
								disabled={readOnly}
							/>
							<div className="grid grid-cols-2 gap-4">
								<div>
									<Label htmlFor={startDateInputId}>{t("Starting date")}</Label>
									<Input
										id={startDateInputId}
										disabled
										containerProps={{ className: "mb-4" }}
										value={format(new Date(plan.periodFrom), {
											dateStyle: "medium",
										})}
									/>
								</div>
								<div>
									<Label htmlFor={endDateInputId}>{t("Ending date")}</Label>
									<Input
										id={endDateInputId}
										disabled
										containerProps={{ className: "mb-4" }}
										value={format(new Date(plan.periodTo), {
											dateStyle: "medium",
										})}
									/>
								</div>
							</div>

							<Label id={comparisonInputId}>{t("Comparison plans")}</Label>
							<Controller
								control={form.control}
								name="comparisonPlans"
								render={({ field: { onChange, value } }) => {
									return (
										<ComparisonPlansSelector
											className="mb-4"
											plans={plans}
											onChange={onChange}
											comparisonPlans={value}
											currentPlanId={plan.id}
											disabled={readOnly}
											aria-labelledby={comparisonInputId}
										/>
									);
								}}
							/>

							<Label>{t("Modules")}</Label>
							<RHFModuleSelectableList
								className="mb-4"
								modules={companyModules}
								control={form.control}
								disabled={readOnly}
							/>

							<Label className="flex items-center gap-2">
								{t("Currency Rounding")}
								<HelpIcon
									label={t(
										"Choose whether the amounts in the visualizations should be displayed in thousands or not.",
									)}
								/>
							</Label>
							<SwitchField
								{...form.register("formatInThousand")}
								disabled={readOnly}
								label={t("Show in thousands")}
								labelProps={{ className: "pb-4" }}
							/>

							<div className="flex justify-end">
								<Button
									type="submit"
									variant="primary"
									isLoading={form.formState.isSubmitting}
									disabled={!form.formState.isDirty}
								>
									{t("Save changes")}
								</Button>
							</div>
						</form>
					);
				}}
			/>

			<DeletePlanButton
				plan={plan}
				disabled={readOnly}
				onDelete={() => {
					navigate("./../../../../../../planning");
				}}
			/>
		</>
	);
};
