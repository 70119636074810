export const ExopenSingleLetterLogo = (props: { className?: string }) => {
	return (
		<svg
			className={props.className}
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M17.5447 9.90835L16.1818 11.7091L11.3178 4.63709H5.40945L13.2727 15.6814L10.5905 19.3178C9.83512 20.4344 8.91571 21.4309 7.86327 22.2734C6.88145 23.04 5.54763 23.4545 4 23.4545V28C9.86617 28 12.4073 25.0807 14.4931 22.0916L21.4545 12.6822C22.208 11.5644 23.1277 10.5682 24.1818 9.72799C25.1636 8.95999 26.4523 8.54545 28 8.54545V4C22.1338 4 19.632 6.91927 17.5447 9.90835Z" />
			<path d="M28.0002 27.3644L20.8453 17.3993C20.3789 17.5809 19.9413 17.8293 19.5464 18.1367C18.6602 18.8405 17.8704 19.6576 17.1973 20.5673L21.9085 27.3644H28.0002Z" />
		</svg>
	);
};
