import { useUser } from "../../common/context/userContextUtils";
import type { CompanyInfo } from "../../common/service/companies";
import type { EwrPackageInfo } from "../../common/service/ewr";
import EwrPackageCard from "./EwrPackageCard";

type EwrReportListProps = {
	companyInfo: CompanyInfo;
};

export const EwrReportList = ({
	companyInfo,
}: EwrReportListProps): JSX.Element => {
	const { userIsSuperAdmin } = useUser();
	const filter = userIsSuperAdmin()
		? (p: EwrPackageInfo) => {
				return p.package.isPublicPackage;
			}
		: (p: EwrPackageInfo) => {
				return p.package.isPublicPackage && !p.package.isInternalPackage;
			};

	return (
		<div className="mb-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
			{companyInfo.ewrPackages.filter(filter).map((p) => {
				return (
					<EwrPackageCard
						key={p.package.id}
						companyInfo={companyInfo}
						ewrPackage={p}
					/>
				);
			})}
		</div>
	);
};
