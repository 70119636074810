import { forwardRef, type ComponentProps } from "react";
import { ButtonLike, type ButtonLikeProps } from "./ButtonLike.js";
import { getButtonLikeProps } from "./getButtonLikeProps.js";
import type { Except } from "type-fest";

type ButtonProps = Except<ComponentProps<"button">, "children"> &
	ButtonLikeProps;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	function Button(props, ref) {
		const {
			buttonLikeProps,
			rest: { className, disabled, ...other },
		} = getButtonLikeProps<ButtonProps>(props);
		const isDisabled = buttonLikeProps.isLoading === true || disabled;

		return (
			<ButtonLike className={className} {...buttonLikeProps}>
				<button disabled={isDisabled} {...other} ref={ref} />
			</ButtonLike>
		);
	},
);
