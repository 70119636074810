import { QueryResult } from "components/queryResult/QueryResult.tsx";
import { useGetErDocumentationQuery } from "src/common/service/fusionHub/fusionHubApi.ts";
import type { ErNote } from "src/common/service/fusionHub/types.ts";
import { Card, CardHeader } from "src/features/planning/components/Card.tsx";
import { useLocale } from "src/locales/useLocale.ts";
import { Text } from "components/typography/Text.tsx";
import { useState } from "react";
import { SourceSystemImage } from "./ConnectorsTable/SourceSystemImage.tsx";
import { Collapse } from "components/collapse/Collapse.tsx";
import { IconCaretDown, IconCaretUp } from "@tabler/icons-react";
import { Button } from "components/button/Button.tsx";
import { useTranslation } from "react-i18next";

type Props = {
	sourceSystemName: string;
	notes: string[];
};

const SourceDocumentation = ({ sourceSystemName, notes }: Props) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const { t } = useTranslation();

	if (notes.length === 0) {
		return null;
	}

	return (
		<Card>
			<div className="flex items-center justify-between border-b px-4 py-2">
				<SourceSystemImage sourceSystemName={sourceSystemName} />
				<Button
					onClick={() => setIsExpanded((expanded) => !expanded)}
					icon={isExpanded ? <IconCaretUp /> : <IconCaretDown />}
					variant="ghost"
				>
					{isExpanded ? t("Show less") : t("Show more")}
				</Button>
			</div>
			<Collapse in={isExpanded}>
				<div className="flex flex-col gap-2 p-4">
					{notes.map((note) => (
						<Text key={note} size="sm">
							{note}
						</Text>
					))}
				</div>
			</Collapse>
		</Card>
	);
};

export const Documentation = () => {
	const { lang } = useLocale();

	const getTranslation = (note: ErNote) => (lang === "sv" ? note.sv : note.en);

	return (
		<QueryResult
			query={useGetErDocumentationQuery()}
			render={(documentation) => {
				return (
					<div className="flex flex-col gap-4">
						{Object.entries(documentation).map(([name, part]) => {
							return (
								<Card key={name}>
									<CardHeader>
										<Text size="lg">{getTranslation(part.title)}</Text>
									</CardHeader>
									<div className="flex flex-col gap-4 p-4">
										<div className="flex flex-col gap-2">
											{part.notes.map((note) => (
												<Text key={note.en} size="sm">
													{getTranslation(note)}
												</Text>
											))}
										</div>
										{Object.entries(part.sources).map(
											([sourceSystemName, sourceDocumentation]) => {
												return (
													<SourceDocumentation
														key={sourceSystemName}
														sourceSystemName={sourceSystemName}
														notes={sourceDocumentation.notes.map(
															getTranslation,
														)}
													/>
												);
											},
										)}
									</div>
								</Card>
							);
						})}
					</div>
				);
			}}
		/>
	);
};
