import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { invariant } from "../utils/invariant.js";
import { useCompanyDomainsOrThrow } from "./useCompanyDomains.js";

export const useCurrentCompanyDomainId = () => {
	const { companyId, companyDomainId } = useParams();

	const companyDomainIdToUse = companyDomainId ?? companyId;

	invariant(companyDomainIdToUse);

	return {
		companyDomainId: companyDomainIdToUse,
		type: companyDomainId ? "default" : "legacy",
	};
};

export const useCurrentCompanyDomain = () => {
	const { companyDomainId } = useCurrentCompanyDomainId();

	invariant(companyDomainId);

	const { getById } = useCompanyDomainsOrThrow();

	const companyDomain = getById(companyDomainId);

	invariant(companyDomain, "Company domain not found");

	return useMemo(
		() => ({
			companyDomain,
		}),
		[companyDomain],
	);
};

export const useCurrentCompanyDomainExists = () => {
	const { companyDomainId } = useCurrentCompanyDomainId();

	invariant(companyDomainId);

	const { getById } = useCompanyDomainsOrThrow();

	const companyDomain = getById(companyDomainId);

	return Boolean(companyDomain);
};
