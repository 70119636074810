import { useCallback, useMemo } from "react";
import { useGetLegalEntitiesQuery } from "../service/nexus/nexusApi.js";
import { invariant } from "../utils/invariant.js";
import { useCurrentCompanyDomain } from "../company-domain/useCurrentCompanyDomain.js";

export const useLegalEntities = () => {
	const { companyDomain } = useCurrentCompanyDomain();

	const {
		data: legalEntities,
		isFetching,
		isLoading,
		isError,
	} = useGetLegalEntitiesQuery(companyDomain.id);

	const getById = useCallback(
		(legalEntityId: string) =>
			(legalEntities ?? []).find(
				(legalEntity) => legalEntity.id === legalEntityId,
			) ?? null,
		[legalEntities],
	);

	const getByLeMasterKey = useCallback(
		(leMasterKey: string) =>
			(legalEntities ?? []).find(
				(legalEntity) => legalEntity.legalEntityMasterKey === leMasterKey,
			) ?? null,
		[legalEntities],
	);

	return useMemo(
		() => ({
			legalEntities: legalEntities ?? null,
			isLoading,
			isFetching,
			isError,
			getById,
			getByLeMasterKey,
		}),
		[getById, getByLeMasterKey, isError, isFetching, isLoading, legalEntities],
	);
};

export const useLegalEntitiesOrThrow = () => {
	const { legalEntities, ...rest } = useLegalEntities();

	invariant(legalEntities, "Legal entities are not loaded yet");

	return useMemo(
		() => ({
			legalEntities,
			getById: rest.getById,
			getByLeMasterKey: rest.getByLeMasterKey,
		}),
		[legalEntities, rest.getById, rest.getByLeMasterKey],
	);
};
