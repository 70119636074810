import { clsx } from "clsx";
import { useContext } from "react";
import { Text } from "../../common/components/atoms/typography/Text";
import { SidebarContext } from "./SidebarContext";

interface NavItemDividerProps {
	label?: string;
	className?: string;
}

export const NavItemDivider = ({ label, className }: NavItemDividerProps) => {
	const { open } = useContext(SidebarContext);

	return (
		<div className={clsx("my-2 flex items-center gap-2", className)}>
			{open && label && (
				<Text size="xs" weight="regular" color="text-grey-600">
					{label}
				</Text>
			)}
			<div className="grow">
				<div className="h-[1px] bg-grey-200" />
			</div>
		</div>
	);
};
