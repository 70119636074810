import { buildCompanyDomainEndpoints } from "./endpoints/companyDomain/index.js";
import { buildPurchasePriceAllocationEndpoints } from "./endpoints/purchasePriceAllocation/index.js";
import { buildCurrencyExchangeStrategyEndpoints } from "./endpoints/currencyExchangeStrategy/index.js";
import { buildProductEndpoints } from "./endpoints/product/index.js";
import { buildCostCenterEndpoints } from "./endpoints/costCenter/index.js";
import { buildProjectEndpoints } from "./endpoints/project/index.js";
import { buildCustomerEndpoints } from "./endpoints/customer/index.js";
import { buildSupplierEndpoints } from "./endpoints/supplier/index.js";
import { buildCurrencyExchangeRateEndpoints } from "./endpoints/currencyExchangeRate/index.js";
import { buildFiscalYearEndpoints } from "./endpoints/fiscalYear/index.js";
import { buildVoucherEndpoints } from "./endpoints/voucher/index.js";
import { buildIncomingBalanceEndpoints } from "./endpoints/incomingBalance/index.js";
import { buildCustomerInvoiceEndpoints } from "./endpoints/customer-invoice/index.js";
import { emptyNexusApi } from "./emptyNexusApi.js";
import { buildSupplierInvoiceEndpoints } from "./endpoints/supplier-invoice/index.js";
import { buildIntegrationEndpoints } from "./endpoints/integration/index.js";

export const nexusApi = emptyNexusApi.injectEndpoints({
	endpoints: (builder) => ({
		...buildCompanyDomainEndpoints(builder),
		...buildCostCenterEndpoints(builder),
		...buildCurrencyExchangeRateEndpoints(builder),
		...buildCurrencyExchangeStrategyEndpoints(builder),
		...buildCustomerEndpoints(builder),
		...buildCustomerInvoiceEndpoints(builder),
		...buildFiscalYearEndpoints(builder),
		...buildIncomingBalanceEndpoints(builder),
		...buildIntegrationEndpoints(builder),
		...buildProductEndpoints(builder),
		...buildProjectEndpoints(builder),
		...buildPurchasePriceAllocationEndpoints(builder),
		...buildSupplierEndpoints(builder),
		...buildSupplierInvoiceEndpoints(builder),
		...buildVoucherEndpoints(builder),
	}),
});

export const {
	useGetLegalEntitiesQuery,
	useGetLegalEntityQuery,
	useDeleteLegalEntityMutation,
	useGetCompanyHierarchyQuery,
	useUpdateCompanyHierarchyMutation,
	useRemoveCompanyHierarchyMutation,
	useGetCurrencyExchangeStrategiesQuery,
	useGetPurchasePriceAllocationsQuery,
	useGetPurchasePriceAllocationQuery,
	useCreatePurchasePriceAllocationMutation,
	useUpdatePurchasePriceAllocationMutation,
	useCreatePurchasePriceAllocationExcessAccountMutation,
	useUpdatePurchasePriceAllocationExcessAccountMutation,
	useDeletePurchasePriceAllocationExcessAccountMutation,
	useCalculatePurchasePriceAllocationAcquisitionQuery,
	useGetPurchasePriceAllocationDraftVouchersQuery,
	useUpdateCurrencyExchangeStrategyMutation,
	useGetCurrencyExchangeRateQuery,
	useGetCostCenterHierarchyQuery,
	useCreateCostCenterHierarchyGroupMutation,
	useUpdateCostCenterHierarchyGroupMutation,
	useRemoveCostCenterHierarchyGroupMutation,
	useGetProjectHierarchyQuery,
	useCreateProjectHierarchyGroupMutation,
	useUpdateProjectHierarchyGroupMutation,
	useRemoveProjectHierarchyGroupMutation,
	useGetCustomerHierarchyQuery,
	useCreateCustomerHierarchyGroupMutation,
	useUpdateCustomerHierarchyGroupMutation,
	useRemoveCustomerHierarchyGroupMutation,
	useGetSupplierHierarchyQuery,
	useCreateSupplierHierarchyGroupMutation,
	useUpdateSupplierHierarchyGroupMutation,
	useRemoveSupplierHierarchyGroupMutation,
	useGetProductHierarchyQuery,
	useCreateProductHierarchyGroupMutation,
	useUpdateProductHierarchyGroupMutation,
	useRemoveProductHierarchyGroupMutation,
	useGetFiscalYearsForLegalEntityQuery,
	useGetAllVoucherAggregateQuery,
	useGetIncomingBalancesQuery,
	useGetCustomerInvoiceByIdQuery,
	useGetCustomerInvoicesQuery,
	useGetSupplierInvoiceByIdQuery,
	useGetSupplierInvoicesQuery,
	useGetIntegrationsQuery,
	useUpsertLegalEntityFromFusionhubIntegrationsMutation,
	useAddIntegrationToLegalEntityMutation,
	useGetCompanyDomainsQuery,
	useGetCustomersQuery,
	useGetSuppliersQuery,
	useLazyGetCustomerInvoiceFileQuery,
	useLazyGetSupplierInvoiceFileQuery,
	useGetCustomerInvoiceFileQuery,
	useGetSupplierInvoiceFileQuery,
} = nexusApi;
