import type { Builder } from "../../types.js";
import type {
	UpdateCurrencyExchangeStrategyRequest,
	CurrencyExchangeStrategy,
} from "./types.js";

export const buildCurrencyExchangeStrategyEndpoints = (builder: Builder) => ({
	getCurrencyExchangeStrategies: builder.query<
		CurrencyExchangeStrategy[],
		string
	>({
		query: (companyDomainId) =>
			`/v1/company-domain/${companyDomainId}/currency-exchange-strategy`,
		providesTags: () => ["CorporateGroupCurrencyExchangeStrategies"],
	}),

	updateCurrencyExchangeStrategy: builder.mutation<
		void,
		{
			companyDomainId: string;
			strategyId: string;
			currencyExchangeStrategy: UpdateCurrencyExchangeStrategyRequest;
		}
	>({
		query({ companyDomainId, strategyId, currencyExchangeStrategy }) {
			return {
				url: `/v1/company-domain/${companyDomainId}/currency-exchange-strategy/${strategyId}`,
				method: "PUT",
				body: currencyExchangeStrategy,
			};
		},
		invalidatesTags: ["CorporateGroupCurrencyExchangeStrategies"],
	}),
});
