import { clsx } from "clsx";
import type { ComponentProps } from "react";

export const ToggleTabButtonGroup = ({
	className,
	children,
	...props
}: ComponentProps<"div">) => {
	return (
		<div
			className={clsx(
				"overflow-x-auto overflow-y-auto rounded-lg shadow",
				className,
			)}
			{...props}
		>
			<div className="flex min-w-fit gap-2 bg-gray-100 p-1">{children}</div>
		</div>
	);
};
