import type { Builder } from "../../types.js";
import {
	buildGetHierarchyEndpoint,
	buildCreateHierarchyGroupEndpoint,
	buildUpdateHierarchyGroupEndpoint,
	buildRemoveHierarchyGroupEndpoint,
} from "../../utils/hierarchy.js";
import type { Customer } from "./types.js";

export const buildHierarchyEndpoints = (builder: Builder) => ({
	getCustomerHierarchy: buildGetHierarchyEndpoint<Customer>(
		"Customer",
		"customer",
		builder,
	),
	createCustomerHierarchyGroup: buildCreateHierarchyGroupEndpoint<Customer>(
		"Customer",
		"customer",
		builder,
	),
	updateCustomerHierarchyGroup: buildUpdateHierarchyGroupEndpoint<Customer>(
		"Customer",
		"customer",
		builder,
	),
	removeCustomerHierarchyGroup: buildRemoveHierarchyGroupEndpoint<Customer>(
		"Customer",
		"customer",
		builder,
	),
});
