import type { Builder } from "../../types.js";
import {
	buildGetHierarchyEndpoint,
	buildCreateHierarchyGroupEndpoint,
	buildUpdateHierarchyGroupEndpoint,
	buildRemoveHierarchyGroupEndpoint,
} from "../../utils/hierarchy.js";
import type { CostCenter } from "./types.js";

export const buildHierarchyEndpoints = (builder: Builder) => ({
	getCostCenterHierarchy: buildGetHierarchyEndpoint<CostCenter>(
		"CostCenter",
		"cost-center",
		builder,
	),
	createCostCenterHierarchyGroup: buildCreateHierarchyGroupEndpoint<CostCenter>(
		"CostCenter",
		"cost-center",
		builder,
	),
	updateCostCenterHierarchyGroup: buildUpdateHierarchyGroupEndpoint<CostCenter>(
		"CostCenter",
		"cost-center",
		builder,
	),
	removeCostCenterHierarchyGroup: buildRemoveHierarchyGroupEndpoint<CostCenter>(
		"CostCenter",
		"cost-center",
		builder,
	),
});
