import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";
import { clsx } from "clsx";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "../collapse/Collapse";
import { DndProvider, useDrag, useDragLayer, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { mergeRefs } from "../../../utils/mergeRefs.js";
import { CircleLoader } from "../loadingState/CircleLoader.js";
import type {
	CanBeDroppedChecker,
	DropHandler,
	ReorderHandler,
	ActionRenderer,
	AddionalExpandableRender,
	Column,
	Hierarchy,
	HierarchyItem,
} from "./types.js";
import { Button } from "../button/Button.js";

const BASE_COLUMNS = 2;

const calculateNumberOfColumns = <T,>(
	extraColumns: T[],
	renderActions?: ActionRenderer<any>,
) => {
	return {
		totalNumberOfColumns:
			BASE_COLUMNS + extraColumns.length + (renderActions ? 1 : 0),
	};
};

type EmptyRowProps<T extends HierarchyItem> = {
	isVisible?: boolean;
	onOver?: (isOver: boolean) => void;
	canBeDropped: CanBeDroppedChecker<T>;
	onDrop: DropHandler<T>;
	numberOfColumns: number;
};

const EmptyRow = <T extends HierarchyItem>({
	isVisible,
	onOver,
	onDrop,
	canBeDropped,
	numberOfColumns,
}: EmptyRowProps<T>) => {
	const { isDragging } = useDragLayer((monitor) => ({
		isDragging: monitor.isDragging() && canBeDropped(monitor.getItem(), null),
	}));

	const shouldShow = isVisible ?? isDragging;

	const [{ isOverAndCanDrop, isOver }, dropRef] = useDrop(
		() => ({
			accept: "item",
			canDrop: (item) => canBeDropped(item, null),
			drop: (item: Hierarchy<T>) => {
				onDrop(item, null);
			},
			collect: (monitor) => ({
				isOverAndCanDrop: monitor.isOver() && monitor.canDrop(),
				isOver: monitor.isOver(),
			}),
		}),
		[onDrop, canBeDropped],
	);

	useEffect(() => {
		onOver?.(isOver);
	}, [isOver, onOver]);

	if (!shouldShow) {
		return null;
	}

	return (
		<tr ref={dropRef}>
			<td
				colSpan={numberOfColumns}
				className={clsx([
					"h-10",
					"border-2",
					"border-green-300",
					"border-dashed",
					isOverAndCanDrop ? "bg-slate-300" : "bg-slate-50",
				])}
			/>
		</tr>
	);
};

const isSameTree = (a: string, b: string) => {
	const aParts = a.split(".");
	const bParts = b.split(".");

	if (aParts.length !== bParts.length) {
		return false;
	}

	return (
		aParts.slice(0, aParts.length - 1).join(".") ===
		bParts.slice(0, bParts.length - 1).join(".")
	);
};

const isJustAfter = (first: string, second: string) => {
	const firstParts = first.split(".");
	const secondParts = second.split(".");

	if (firstParts.length !== secondParts.length) {
		return false;
	}

	const lastFirstPart = firstParts[firstParts.length - 1];
	const lastSecondPart = secondParts[secondParts.length - 1];

	return (
		Number.parseInt(lastFirstPart, 10) ===
		Number.parseInt(lastSecondPart, 10) - 1
	);
};

const containsId = <T extends HierarchyItem>(
	hierarchy: Hierarchy<T>,
	id?: string | null,
): boolean => {
	if (!id) {
		return false;
	}

	if (hierarchy.id === id) {
		return true;
	}

	return hierarchy.children.some((child) => containsId(child, id));
};

type DroppableHierarchy<T extends HierarchyItem> = {
	index: string;
} & Hierarchy<T>;

const HierarchyRow = <T extends HierarchyItem>({
	hierarchy,
	parent,
	level = 0,
	isLastWithinGroup,
	isLastGroup,
	isDraggable,
	index,
	childBefore,
	onDrop,
	onReorder,
	canBeDropped,
	expandedByDefault,
	columns,
	renderActions,
	renderAdditionalExpandalbeContent,
	highlightedNodeId,
}: {
	hierarchy: Hierarchy<T>;
	parent: Hierarchy<T> | null;
	level?: number;
	isLastWithinGroup: boolean;
	isLastGroup: boolean;
	isDraggable: boolean;
	index: string;
	onDrop: DropHandler<T>;
	canBeDropped: CanBeDroppedChecker<T>;
	onReorder: ReorderHandler<T>;
	childBefore: Hierarchy<T> | null;
	expandedByDefault: boolean;
	columns: Column<T>[];
	renderActions?: ActionRenderer<T> | undefined;
	renderAdditionalExpandalbeContent?: AddionalExpandableRender<T> | undefined;
	highlightedNodeId?: string | null | undefined;
}) => {
	const [isOverDropRow, setIsOverDropRow] = useState(false);

	const [{ opacity }, dragRef] = useDrag(
		() => ({
			type: "item",
			item: { ...hierarchy, index },
			canDrag: isDraggable,
			collect: (monitor) => ({
				opacity: monitor.isDragging() ? 0.5 : 1,
			}),
		}),
		[hierarchy, isDraggable, index],
	);

	const { totalNumberOfColumns } = calculateNumberOfColumns(
		columns,
		renderActions,
	);

	const [{ isPossibleDropAndOver, isOver, hoverItem }, dropRef] = useDrop(
		() => ({
			accept: "item",
			canDrop: (item) => canBeDropped(item, hierarchy),
			drop: (item: Hierarchy<T>) => {
				onDrop(item, hierarchy);
			},
			collect: (monitor) => ({
				isPossibleDropAndOver: monitor.isOver() && monitor.canDrop(),
				isOver: monitor.isOver(),
				hoverItem: monitor.getItem<
					DroppableHierarchy<T>
				>() as DroppableHierarchy<T> | null,
			}),
		}),
		[hierarchy, onDrop, canBeDropped, index],
	);

	const { t } = useTranslation();

	const [isExpanded, setIsExpanded] = useState(
		expandedByDefault || containsId(hierarchy, highlightedNodeId),
	);

	const extraContent = renderAdditionalExpandalbeContent?.(hierarchy);

	const hasExtraContent =
		Boolean(extraContent) || hierarchy.children.length > 0;

	const mergedRef = mergeRefs<HTMLTableCellElement>([dragRef, dropRef]);

	const isValidDropTarget = hoverItem
		? isSameTree(hoverItem.index, index) &&
			hoverItem.index !== index &&
			!isJustAfter(hoverItem.index, index)
		: false;

	const columnClassNames = clsx(
		{
			"border-b": !isLastGroup || (isExpanded && hasExtraContent),
		},
		"px-2",
		"py-3",
		"border-grey-200",
		"border-double",
	);

	const rowRef = useRef<HTMLTableRowElement>(null);

	const isHighlightedRow = hierarchy.id === highlightedNodeId;
	const [isHighlighted, setIsHighlighted] = useState(false);

	useEffect(() => {
		if (isHighlightedRow) {
			rowRef.current?.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});

			setIsHighlighted(true);

			const timeout = setTimeout(() => {
				setIsHighlighted(false);
			}, 5000);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [dragRef, isHighlightedRow]);

	return (
		<>
			<EmptyRow<T>
				isVisible={isValidDropTarget && (isOver || isOverDropRow)}
				canBeDropped={() => true}
				onDrop={(item) => onReorder(item, [childBefore, hierarchy])}
				onOver={setIsOverDropRow}
				numberOfColumns={totalNumberOfColumns + 1}
			/>
			<tr
				ref={rowRef}
				className={clsx([
					"relative",
					level > 0 && "before:absolute",
					"before:h-px",
					"before:w-3",
					"before:bg-grey-200",
					"before:left-1",
					"before:top-1/2",
					"after:absolute",
					isLastWithinGroup ? "after:h-1/2" : "after:h-full",
					level > 0 && "after:w-px",
					"after:bg-grey-200",
					"after:left-1",
					"after:top-0",
					isHighlighted
						? "bg-blue-100"
						: isPossibleDropAndOver
							? "bg-grey-300"
							: "bg-white",

					isDraggable ? "cursor-move" : undefined,
				])}
				style={{ opacity }}
			>
				<td className="w-2 pl-3">
					{hasExtraContent ? (
						<Button
							ariaLabel={t("Expand row")}
							variant="ghost"
							onClick={() => setIsExpanded((s) => !s)}
							icon={isExpanded ? <IconChevronDown /> : <IconChevronRight />}
						/>
					) : (
						<div className="block h-8 w-8" />
					)}
				</td>
				<td className={columnClassNames} ref={mergedRef}>
					{hierarchy.label ?? hierarchy.id}
				</td>
				{columns.map((column) => (
					<td className={clsx(columnClassNames, "text-right")} key={column.id}>
						{column.render(hierarchy)}
					</td>
				))}
				{renderActions && (
					<td align="right" className={clsx(columnClassNames, "w-52")}>
						{renderActions(hierarchy, parent)}
					</td>
				)}
			</tr>
			{extraContent && (
				<tr
					className={clsx([
						"relative",
						level > 0 && "before:absolute",
						!isLastWithinGroup && "before:h-full",
						"before:w-px",
						"before:left-1",
						"before:top-0",
						"before:bg-grey-200",
						"after:absolute",
						"after:h-full",
						"after:w-px",
						"after:bg-grey-200",
						"after:left-[29px]",
						"after:top-0",
					])}
				>
					<td colSpan={totalNumberOfColumns} className="pl-12">
						<Collapse in={isExpanded}>{extraContent}</Collapse>
					</td>
				</tr>
			)}
			{hasExtraContent && (
				<tr
					className={clsx([
						"relative",
						level > 0 && "before:absolute",
						!isLastWithinGroup && "before:h-full",
						"before:w-px",
						"before:left-1",
						"before:top-0",
						"before:bg-grey-200",
					])}
				>
					<td colSpan={totalNumberOfColumns}>
						<Collapse in={isExpanded}>
							<div className="w-full pl-6">
								<table className="w-full table-auto">
									<tbody>
										{hierarchy.children.map((child, childIndex) => (
											<HierarchyRow
												key={child.id}
												hierarchy={child}
												parent={hierarchy}
												level={level + 1}
												isLastGroup={
													isLastGroup &&
													childIndex === hierarchy.children.length - 1
												}
												isLastWithinGroup={
													childIndex === hierarchy.children.length - 1
												}
												index={`${index}.${childIndex + 1}`}
												expandedByDefault={expandedByDefault}
												isDraggable={isDraggable}
												childBefore={
													childIndex === 0
														? null
														: (hierarchy.children[childIndex - 1] ?? null)
												}
												onDrop={onDrop}
												onReorder={onReorder}
												canBeDropped={canBeDropped}
												renderActions={renderActions}
												renderAdditionalExpandalbeContent={
													renderAdditionalExpandalbeContent
												}
												columns={columns}
												highlightedNodeId={highlightedNodeId}
											/>
										))}
									</tbody>
								</table>
							</div>
						</Collapse>
					</td>
				</tr>
			)}
		</>
	);
};

interface Props<T extends HierarchyItem> {
	title?: string;
	hierarchy: Hierarchy<T> | Hierarchy<T>[];
	expandedByDefault?: boolean;
	isLoading?: boolean;
	isDraggable?: boolean;
	onDrop?: DropHandler<T>;
	canBeDropped?: CanBeDroppedChecker<T>;
	onReorder?: ReorderHandler<T>;
	renderActions?: ActionRenderer<T> | undefined;
	renderAdditionalExpandalbeContent?: AddionalExpandableRender<T>;
	columns?: Column<T>[];
	extraRows?: React.ReactNode[] | undefined;
	highlightedNodeId?: string | null;
}

export const HierarchyTable = <T extends HierarchyItem>({
	title = "",
	hierarchy,
	expandedByDefault = false,
	isLoading = false,
	isDraggable = false,
	onDrop = () => {},
	canBeDropped = () => true,
	onReorder = () => {},
	renderActions,
	renderAdditionalExpandalbeContent,
	columns = [],
	extraRows = [],
	highlightedNodeId,
}: Props<T>) => {
	const normalizedHierarchy = Array.isArray(hierarchy)
		? hierarchy
		: [hierarchy];

	const childsCanBeDragged = isDraggable && !isLoading;

	const { totalNumberOfColumns } = calculateNumberOfColumns(
		columns,
		renderActions,
	);

	return (
		<table className="w-full table-auto">
			<thead>
				<tr className="bg-grey-50">
					<th className="p-4 text-left" colSpan={BASE_COLUMNS + 1}>
						<span className="flex gap-2">
							{title} {isLoading && <CircleLoader size="sm" />}
						</span>
					</th>
					{columns.map((column) => (
						<th key={column.id} className="min-w-fit pr-2 text-right">
							{column.label}
						</th>
					))}
					{renderActions && <th />}
				</tr>
			</thead>
			<tbody>
				<DndProvider backend={HTML5Backend}>
					{normalizedHierarchy.map((hierarchy, childIndex) => {
						const isLast = childIndex === normalizedHierarchy.length - 1;

						const childBefore =
							childIndex === 0
								? null
								: (normalizedHierarchy[childIndex - 1] ?? null);

						return (
							<HierarchyRow
								key={hierarchy.id}
								hierarchy={hierarchy}
								parent={null}
								isLastGroup={isLast}
								isLastWithinGroup={isLast}
								isDraggable={childsCanBeDragged}
								index={`${childIndex + 1}`}
								onDrop={onDrop}
								onReorder={onReorder}
								canBeDropped={canBeDropped}
								expandedByDefault={expandedByDefault}
								childBefore={childBefore}
								renderActions={renderActions}
								renderAdditionalExpandalbeContent={
									renderAdditionalExpandalbeContent
								}
								columns={columns}
								highlightedNodeId={highlightedNodeId}
							/>
						);
					})}
					{isDraggable && (
						<EmptyRow
							onDrop={onDrop}
							canBeDropped={canBeDropped}
							numberOfColumns={totalNumberOfColumns + 1}
						/>
					)}
				</DndProvider>
				{extraRows.map((row, index) => (
					<tr key={index}>
						<td colSpan={totalNumberOfColumns + 1}>{row}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};
