import type { Builder, PaginatedResponse } from "../../types.ts";
import type { SupplierInvoice } from "./types.ts";

export const buildSupplierInvoiceEndpoints = (builder: Builder) => ({
	getSupplierInvoiceById: builder.query<
		SupplierInvoice,
		{
			companyDomainId: string;
			legalEntityId: string;
			invoiceId: string;
		}
	>({
		query: ({ companyDomainId, legalEntityId, invoiceId }) => {
			return {
				url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/supplier-invoice/${invoiceId}`,
			};
		},
		providesTags: (_result, error, arg) => {
			if (error) {
				return ["SupplierInvoice"];
			}
			return [
				"SupplierInvoice",
				{
					type: "SupplierInvoice",
					id: arg.invoiceId,
				},
			];
		},
	}),
	getSupplierInvoices: builder.query<
		PaginatedResponse<SupplierInvoice>,
		{ companyDomainId: string; legalEntityId: string; take: number }
	>({
		query: ({ companyDomainId, legalEntityId, take }) => {
			return {
				url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/supplier-invoice`,
				params: {
					take,
				},
			};
		},
		providesTags: (result) => {
			if (!result) {
				return ["SupplierInvoice"];
			}
			return [
				"SupplierInvoice",
				...result.items.map((invoice) => {
					return {
						type: "SupplierInvoice" as const,
						id: invoice.id,
					};
				}),
			];
		},
	}),
	getSupplierInvoiceFile: builder.query<
		Blob,
		{
			companyDomainId: string;
			legalEntityId: string;
			invoiceId: string;
			fileId: string;
		}
	>({
		query({ companyDomainId, fileId, invoiceId, legalEntityId }) {
			return {
				url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/supplier-invoice/${invoiceId}/files/${fileId}`,
				responseHandler: (response) => response.blob(),
			};
		},
	}),
});
