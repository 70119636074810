import { clsx } from "clsx";
import type { ComponentProps } from "react";
import { Chip } from "../../../common/components/atoms/chip/Chip";
import { Text } from "../../../common/components/atoms/typography/Text";
import { useLocale } from "src/locales/useLocale.js";

type CardProps = ComponentProps<"div">;

export const Card = ({ className, children, ...props }: CardProps) => {
	return (
		<div
			className={clsx(
				"shadow-[0px_4px_8px_-2px_rgba(16,24,40,0.1),0px_2px_4px_-2px_rgba(16,24,40,0.06) overflow-hidden rounded-[8px] border border-grey-200 bg-white",
				className,
			)}
			{...props}
		>
			{children}
		</div>
	);
};

type CardHeaderProps = ComponentProps<"div">;

export const CardHeader = ({
	className,
	children,
	...props
}: CardHeaderProps) => {
	return (
		<div
			className={clsx("border-b border-grey-200 p-4", className, "flex")}
			{...props}
		>
			{children}
		</div>
	);
};

interface CardItemsHeaderProps extends ComponentProps<"div"> {
	heading: string;
	subHeading?: React.ReactNode;
	count?: number | undefined;
}

export const CardItemsHeader = ({
	heading,
	count,
	subHeading,
}: CardItemsHeaderProps) => {
	const { formatNumber } = useLocale();

	return (
		<div className="grow">
			<div className={clsx("flex items-center", subHeading && "mb-1")}>
				<Text size="md" weight="medium" className="mr-2">
					{heading}
				</Text>
				{count !== undefined && (
					<Chip size="small" color="grey">
						{formatNumber(count)}
					</Chip>
				)}
			</div>
			{subHeading && (
				<Text size="xs" weight="regular" color="text-grey-500">
					{subHeading}
				</Text>
			)}
		</div>
	);
};
