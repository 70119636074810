import {
	IconDatabaseImport,
	IconDownload,
	IconExternalLink,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../../../common/components/atoms/notifications/events";
import { Text } from "../../../../common/components/atoms/typography/Text";
import { BreadcrumbsLayout } from "../../../../common/components/layout/BreadcrumbsLayout";
import { useAddBusinessCentralPumpMutation } from "../../../../common/redux/api/exopenApi";
import { Card } from "../../../planning/components/Card";
import { usePersistedNavigate } from "../../../../common/persistentRouterFunctions";
import pdf from "./BusinessCentralGuide.pdf";
import businessCentralLogoFull from "./business-central-logo-full.png";
import { useState } from "react";
import { type Credentials, CredentialsForm } from "./CredentialsForm.js";
import { useCurrentCompanyDomain } from "src/common/company-domain/useCurrentCompanyDomain.js";
import { DataConfigEditor } from "./DataConfigEditor.js";
import { ButtonLink } from "../../../../common/components/atoms/button/ButtonLink.js";

export const BusinessCentralPump = () => {
	const { t } = useTranslation();
	const { companyDomain } = useCurrentCompanyDomain();

	const [credentials, setCredentials] = useState<Credentials>();

	const navigate = usePersistedNavigate();

	const [addPump, { isLoading, isError }] = useAddBusinessCentralPumpMutation();
	const createPump = async (data: Parameters<typeof addPump>[0]) => {
		const result = await addPump(data);

		if ("error" in result) {
			showErrorNotification({ message: t("Failed to add data source") });
		} else {
			showSuccessNotification({ message: t("Data source was added") });
			navigate("./..");
		}
	};

	return (
		<BreadcrumbsLayout
			breadcrumbs={[
				{
					icon: <IconDatabaseImport />,
				},
				{ name: t("Data sources"), path: "./../.." },
				{ name: t("New data source"), path: "./.." },
				{ name: "Dynamics 365 Business Central" },
			]}
		>
			<div className="flex gap-4">
				<div className="grow">
					<Card className="p-6">
						{!credentials && <CredentialsForm onSubmit={setCredentials} />}
						{credentials && (
							<DataConfigEditor
								credentials={credentials}
								isSubmitting={isLoading}
								isError={isError}
								onSubmit={(configs) =>
									createPump({
										companyDomainId: companyDomain.id,
										tenantId: credentials.tenantId,
										clientId: credentials.clientId,
										clientSecret: credentials.clientSecret,
										environment: credentials.environment,
										includedCompanies: configs.map((config) => ({
											includedCompanyId: config.companyId,
											dataConfig: {
												dimensionCostCenterId: config.costCenterDimensionId
													? config.costCenterDimensionId
													: null,
												dimensionProjectId: config.projectDimensionId
													? config.projectDimensionId
													: null,
											},
										})),
									})
								}
							/>
						)}
					</Card>
				</div>
				<div className="w-[300px]">
					<Card className="p-6">
						<img
							src={businessCentralLogoFull}
							alt="Dynamics 365 Business Central logotype"
							height={78}
							width={256}
							className="mb-4"
						/>
						<Text size="md" color="text-grey-700" weight="medium">
							{t("Contact")}
						</Text>
						<Text
							size="sm"
							color="text-grey-500"
							weight="regular"
							className="mb-4"
						>
							{t("Open Monday to Friday from 9:00 a.m. to 5:00 p.m.")}
						</Text>
						<ButtonLink
							reloadDocument
							to="https://dynamics.microsoft.com/sv-se/business-central/overview/"
							variant="secondaryGray"
							icon={<IconExternalLink />}
							iconPosition="right"
							fullWidth
							align="center"
							className="mb-2"
							target="_blank"
						>
							{t("Read more")}
						</ButtonLink>
						<ButtonLink
							reloadDocument
							to={pdf}
							variant="secondaryGray"
							icon={<IconDownload />}
							fullWidth
							align="center"
							download
						>
							{t("Download guide")}
						</ButtonLink>
					</Card>
				</div>
			</div>
		</BreadcrumbsLayout>
	);
};
