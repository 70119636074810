import type { ReactNode } from "react";
import { LoadingState } from "../loadingState/LoadingState.js";
import { MissingDataState } from "../missingDataState/MissingDataState.js";
import type { TypedUseQueryHookResult } from "@reduxjs/toolkit/query/react";

type QueriesMap = {
	[key: string]: TypedUseQueryHookResult<any, any, any>;
};

type QueryReturnType<T> = T extends TypedUseQueryHookResult<any, any, any>
	? T extends { data?: infer R }
		? Exclude<R, undefined>
		: never
	: never;

type QueriesReturnTypes<T> = T extends QueriesMap
	? {
			[K in keyof T]: QueryReturnType<T[K]>;
		}
	: never;

type Props<T> = {
	queries: T;
	render: (
		data: QueriesReturnTypes<T>,
		params: { isFetching: boolean },
	) => ReactNode;
	renderLoading?: () => ReactNode;
	renderError?: (error: unknown) => ReactNode;
};

export const QueriesResult = <T extends QueriesMap>({
	queries,
	render,
	renderLoading = () => <LoadingState />,
	renderError = () => <MissingDataState />,
}: Props<T>) => {
	const queryResults = Object.values(queries);
	if (queryResults.some((query) => query.isLoading)) {
		return <>{renderLoading()}</>;
	}

	if (
		queryResults.some((query) => query.isError) ||
		queryResults.some((query) => !query.data)
	) {
		return <>{renderError(queryResults.find((query) => query.error))}</>;
	}

	const resultObject = Object.entries(queries).reduce(
		(acc, [key, query]) => ({
			...acc,
			[key]: query.data,
		}),
		{} as QueriesReturnTypes<T>,
	);

	return (
		<>
			{render(resultObject, {
				isFetching: queryResults.some((query) => query.isFetching),
			})}
		</>
	);
};
