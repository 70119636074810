import {
	buildPlanRouteUrl,
	type DomainDriver,
	type DriverCreateInput,
	type DriverUpdateInput,
} from "@exopengithub/planning-api-shared";
import { emptyPlanningApi } from "./emptyPlanningApi.ts";

const driverApi = emptyPlanningApi.injectEndpoints({
	endpoints: (builder) => ({
		createDriver: builder.mutation<
			void,
			DriverCreateInput & {
				companyDomainId: string;
				legalEntityId: string;
				planId: string;
			}
		>({
			query({
				planId,
				legalEntityId,
				companyDomainId,
				accountRecipeConnections,
				amount,
				name,
				refType,
				driverConnections,
				active,
			}) {
				return {
					method: "POST",
					url: buildPlanRouteUrl("/generalobjects", {
						version: "v2",
						companyDomainId,
						legalEntityId,
						planId,
					}),
					body: {
						name,
						amount,
						accountRecipeConnections,
						refType,
						active,
						driverConnections,
					} satisfies DriverCreateInput,
				};
			},
			invalidatesTags() {
				return ["Driver"];
			},
		}),

		updateDriver: builder.mutation<
			void,
			{
				driverId: string;
				companyDomainId: string;
				legalEntityId: string;
				planId: string;
			} & DriverUpdateInput
		>({
			query({
				planId,
				legalEntityId,
				companyDomainId,
				accountRecipeConnections,
				amount,
				name,
				driverId,
				driverConnections,
				active,
			}) {
				return {
					method: "PUT",
					url: buildPlanRouteUrl(`/generalobjects/${driverId}`, {
						version: "v2",
						companyDomainId,
						legalEntityId,
						planId,
					}),
					body: {
						name,
						amount,
						accountRecipeConnections,
						active,
						driverConnections,
					} satisfies DriverUpdateInput,
				};
			},
			invalidatesTags(_result, error, arg) {
				if (error) {
					return ["Driver"];
				}
				return [{ type: "Driver", id: arg.driverId }, "DriverModuleEntry"];
			},
		}),

		getDrivers: builder.query<
			DomainDriver[],
			{
				planId: string;
				refType: DomainDriver["refType"];
				companyDomainId: string;
				legalEntityId: string;
			}
		>({
			query: ({ planId, refType, legalEntityId, companyDomainId }) => {
				return buildPlanRouteUrl(`/general-objects/${refType}`, {
					companyDomainId,
					legalEntityId,
					planId,
				});
			},
			providesTags: (result) =>
				result
					? [
							...result.map(({ id }) => ({
								type: "Driver" as const,
								id,
							})),
							"Driver",
						]
					: ["Driver"],
		}),

		deleteDriver: builder.mutation<
			unknown,
			{ planId: string; driverId: string; companyDomainId: string }
		>({
			query: ({ planId, driverId, companyDomainId }) => {
				return {
					method: "DELETE",
					url: `/v1/${companyDomainId}/plans/${planId}/generalobjects/${driverId}`,
				};
			},
			invalidatesTags: (_result, _error, arg) => [
				{ type: "Driver", id: arg.driverId },
			],
		}),
	}),
	overrideExisting: false,
});

export const {
	useCreateDriverMutation,
	useUpdateDriverMutation,
	useDeleteDriverMutation,
	useGetDriversQuery,
} = driverApi;
