import { clsx } from "clsx";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "../typography/Text";

interface BreadcrumbsDescriptionProps {
	children?: string | undefined;
}

export const BreadcrumbsDescription = ({
	children,
}: BreadcrumbsDescriptionProps) => {
	const { t } = useTranslation();
	const [readMore, setReadMore] = useState<boolean | null>(null);
	const descRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (descRef.current) {
			if (descRef.current.offsetWidth === descRef.current.scrollWidth) {
				setReadMore(null);
			} else {
				setReadMore((prev) => {
					if (typeof prev !== "boolean") {
						return false;
					}
					return prev;
				});
			}
		}
	}, [children]);

	if (!children) {
		return null;
	}

	return (
		<div
			className="flex max-w-[400px] items-center"
			style={{ height: !readMore ? "1lh" : undefined }}
		>
			<Text
				size="xs"
				weight="regular"
				color="text-grey-500"
				ref={descRef}
				className={clsx("break-all", !readMore && "truncate")}
			>
				{children}
				{readMore === true && (
					<button
						onClick={() => {
							setReadMore(false);
						}}
					>
						<Text
							size="xs"
							weight="medium"
							color="text-purple-500"
							className="flex-shrink-0 cursor-pointer whitespace-nowrap pl-1"
						>
							{t("Collapse")}
						</Text>
					</button>
				)}
			</Text>
			{readMore === false && (
				<button
					type="button"
					onClick={() => {
						setReadMore(true);
					}}
				>
					<Text
						size="xs"
						weight="medium"
						color="text-purple-500 flex-shrink-0 cursor-pointer whitespace-nowrap pl-1"
					>
						{t("Read more")}
					</Text>
				</button>
			)}
		</div>
	);
};
