import {
	IconChevronLeft,
	IconChevronRight,
	IconChevronsLeft,
	IconChevronsRight,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { ButtonGroup } from "../button/ButtonGroup";
import { DOTS, usePagination } from "./usePagination";
import { Tooltip } from "../tooltip/Tooltip.tsx";
import { TooltipContent } from "../tooltip/TooltipContent.tsx";
import { TooltipTrigger } from "../tooltip/TooltipTrigger.tsx";
import { Button } from "../button/Button.tsx";

interface PaginationProps {
	onChange: (page: number) => void;
	siblings?: number;
	boundaries?: number;
	withControls?: boolean;
	withEdges?: boolean;
	totalPages: number;
	page: number;
}

export const Pagination = ({
	page,
	boundaries,
	onChange,
	siblings,
	totalPages,
	withControls = true,
	withEdges = true,
}: PaginationProps) => {
	const { t } = useTranslation();

	const { range, setPage, next, previous, first, last } = usePagination({
		page,
		onChange,
		total: totalPages,
		siblings,
		boundaries,
	});

	return (
		<ButtonGroup>
			{withEdges && (
				<Tooltip>
					<TooltipTrigger asChild>
						<Button
							variant="secondaryGray"
							size="sm"
							onClick={first}
							disabled={page === 1}
							icon={<IconChevronsLeft />}
							ariaLabel={t("First page")}
						/>
					</TooltipTrigger>
					<TooltipContent>{t("First page")}</TooltipContent>
				</Tooltip>
			)}
			{withControls && (
				<Tooltip>
					<TooltipTrigger asChild>
						<Button
							variant="secondaryGray"
							size="sm"
							onClick={previous}
							disabled={page === 1}
							icon={<IconChevronLeft />}
							ariaLabel={t("Previous page")}
						/>
					</TooltipTrigger>
					<TooltipContent>{t("Previous page")}</TooltipContent>
				</Tooltip>
			)}
			{range.map((item, index) => {
				if (item === DOTS) {
					return (
						<Button
							variant="secondaryGray"
							size="sm"
							key={`dots-${index}`}
							className="min-w-[36px] cursor-default px-0"
						>
							...
						</Button>
					);
				}
				return (
					<Button
						variant="secondaryGray"
						size="sm"
						key={item}
						onClick={() => {
							setPage(item);
						}}
						aria-pressed={item === page}
						className="min-w-[36px] px-0"
					>
						{item}
					</Button>
				);
			})}
			{withControls && (
				<Tooltip>
					<TooltipTrigger asChild>
						<Button
							variant="secondaryGray"
							size="sm"
							onClick={next}
							disabled={page === totalPages}
							icon={<IconChevronRight />}
							ariaLabel={t("Next page")}
						/>
					</TooltipTrigger>
					<TooltipContent>{t("Next page")}</TooltipContent>
				</Tooltip>
			)}
			{withEdges && (
				<Tooltip>
					<TooltipTrigger asChild>
						<Button
							variant="secondaryGray"
							size="sm"
							onClick={last}
							disabled={page === totalPages}
							icon={<IconChevronsRight />}
							ariaLabel={t("Last page")}
						/>
					</TooltipTrigger>
					<TooltipContent>{t("Last page")}</TooltipContent>
				</Tooltip>
			)}
		</ButtonGroup>
	);
};
