import { useTranslation } from "react-i18next";
import type { User } from "../../common/service/Types/UserTypes";
import { makeStringSort, useSort } from "../../common/utils/hooks/useSort";
import { UserTableRow } from "./UserTableRow";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events";
import {
	useDeleteUserMutation,
	useGetCompanyUsersQuery,
} from "../../common/redux/api/exopenApi";
import { useState } from "react";
import { LoadingState } from "../../common/components/atoms/loadingState/LoadingState";
import { MissingDataState } from "../../common/components/atoms/missingDataState/MissingDataState";
import { useParams } from "react-router-dom";
import { invariant } from "../../common/utils/invariant";
import { InviteUserModal } from "./InviteUserModal";
import { ConfirmDialog } from "../../common/components/atoms/dialog/ConfirmDialog.tsx";
import { GridContainer } from "src/features/planning/scenario/common/grid/GridContainer.tsx";
import { TableContainer } from "src/features/planning/components/TableContainer.tsx";
import {
	GridHeader,
	GridHeaderLabel,
	GridHeaderLabelWithSortingButton,
} from "src/features/planning/scenario/common/grid/GridHeader.tsx";

export const UserTable = () => {
	const { t } = useTranslation();
	const { companyId } = useParams();
	invariant(companyId);
	const { data: users, isLoading: isFetchingUsers } =
		useGetCompanyUsersQuery(companyId);
	const { items: sortedUsers, sortColumnProps } = useSort({
		keys: ["byName", "byEmail"],
		defaultKey: "byName",
		defaultOrder: "asc",
		rows: users ?? [],
		columns: {
			byName: {
				sort: makeStringSort("name"),
			},
			byEmail: {
				sort: makeStringSort("email"),
			},
		},
	});

	const userByEmail: Record<string, User> = {};
	for (const user of users ?? []) {
		userByEmail[user.email] = user;
	}

	const [userToDelete, setUserToDelete] = useState<User>();
	const [userToEdit, setUserToEdit] = useState<string | undefined>();

	const [deleteUser, { isLoading: isDeletingUser }] = useDeleteUserMutation();

	const onDeleteUser = async () => {
		const result = await deleteUser({ email: userToDelete!.email, companyId });
		if ("error" in result) {
			showErrorNotification({
				message: "Det gick inte att ta bort användaren.",
			});
		} else {
			showSuccessNotification({ message: "Användaren är nu borttagen" });
			setUserToDelete(undefined);
		}
	};

	if (isFetchingUsers) {
		return <LoadingState />;
	}

	if (!users) {
		return <MissingDataState />;
	}

	return (
		<>
			<TableContainer className="bg-white">
				<GridContainer
					templateColumns="auto auto auto auto min-content"
					aria-label={t("Users")}
					role="table"
				>
					<GridHeaderLabelWithSortingButton
						sortingProps={sortColumnProps.byName}
					>
						{t("Name")}
					</GridHeaderLabelWithSortingButton>
					<GridHeaderLabel>{t("Access")}</GridHeaderLabel>
					<GridHeaderLabelWithSortingButton
						sortingProps={sortColumnProps.byEmail}
					>
						{t("Email")}
					</GridHeaderLabelWithSortingButton>
					<GridHeaderLabel>{t("Assigned groups")}</GridHeaderLabel>
					<GridHeader />
					{sortedUsers.map((user) => {
						return (
							<UserTableRow
								user={user}
								key={user.email}
								onDeleteUser={(user) => {
									setUserToDelete(user);
								}}
								onEditUser={(user) => {
									setUserToEdit(user.email);
								}}
								companyId={companyId}
							/>
						);
					})}
				</GridContainer>
			</TableContainer>
			<InviteUserModal
				modalOpen={userToEdit !== undefined}
				handleClose={() => {
					setUserToEdit(undefined);
				}}
				user={userToEdit ? userByEmail[userToEdit] : undefined}
				companyId={companyId}
			/>
			<ConfirmDialog
				destructive
				open={userToDelete !== undefined}
				onClose={() => {
					setUserToDelete(undefined);
				}}
				title={t("Remove user")}
				description={t("Do you want to delete the user?")}
				onConfirm={onDeleteUser}
				confirmButtonLabel={t("Remove user")}
				isLoading={isDeletingUser}
			/>
		</>
	);
};
