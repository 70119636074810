const limitDecimals = (value: number) => {
	return Number(value.toFixed(2));
};

export const trimValue = (value: string) => {
	const trimmedValue = value
		.replace(",", ".")
		.replace(/\s+/g, "")
		.replace("−", "-");
	if (trimmedValue === "") {
		return null;
	}
	return limitDecimals(Number(trimmedValue));
};
