import type {
	BaseQueryFn,
	TypedUseQueryHookResult,
} from "@reduxjs/toolkit/query/react";
import { LoadingState } from "../loadingState/LoadingState.js";
import { MissingDataState } from "../missingDataState/MissingDataState.js";

type Props<ReturnType, Params> = {
	query: TypedUseQueryHookResult<ReturnType, Params, BaseQueryFn>;
	render: (
		data: ReturnType,
		params: { isFetching: boolean },
	) => React.ReactNode;
	renderLoading?: () => React.ReactNode;
	renderError?: (error: unknown) => React.ReactNode;
};

export const QueryResult = <ReturnType, Params>({
	query,
	render,
	renderLoading = () => <LoadingState />,
	renderError = () => <MissingDataState />,
}: Props<ReturnType, Params>) => {
	if (query.isLoading) {
		return <>{renderLoading()}</>;
	}

	if (query.isError || !query.data) {
		return <>{renderError(query.error)}</>;
	}

	return <>{render(query.data, { isFetching: query.isFetching })}</>;
};
