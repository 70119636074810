import { useMergeRefs } from "@floating-ui/react";
import {
	type ReactNode,
	forwardRef,
	type HTMLProps,
	isValidElement,
	cloneElement,
	type Ref,
} from "react";
import { useDialogContext } from "./useDialogContext.js";

interface DialogTriggerProps extends Omit<HTMLProps<HTMLElement>, "children"> {
	children: ReactNode | ((options: Record<string, unknown>) => any);
	asChild?: boolean;
}

export const DialogTrigger = forwardRef<HTMLElement, DialogTriggerProps>(
	function DialogTrigger({ children, asChild = false, ...props }, propRef) {
		if (!asChild && typeof children === "function") {
			throw new Error("`asChild` must be true when `children` is a function");
		}

		const context = useDialogContext();
		const childrenRef = (
			typeof children === "object" && children !== null && "ref" in children
				? children.ref
				: null
		) as Ref<unknown>;
		const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

		// `asChild` allows the user to pass any element as the anchor
		if (asChild) {
			if (isValidElement(children)) {
				return cloneElement(
					children,
					context.getReferenceProps({
						ref,
						...props,
						...children.props,
						"data-state": context.open ? "open" : "closed",
					}),
				);
			}
			if (typeof children === "function") {
				return children(
					context.getReferenceProps({
						ref,
						...props,
					}),
				);
			}
		}

		return (
			<button ref={ref} {...context.getReferenceProps(props)}>
				{children as ReactNode}
			</button>
		);
	},
);
