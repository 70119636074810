import { type ReactNode, createContext, type ComponentProps } from "react";
import type { Button } from "./Button";
import { clsx } from "clsx";

type ButtonGroupContextValue =
	| {
			size?: ComponentProps<typeof Button>["size"];
			variant?: ComponentProps<typeof Button>["variant"];
	  }
	| undefined;

export const ButtonGroupContext =
	createContext<ButtonGroupContextValue>(undefined);

interface ButtonGroupProps {
	size?: ComponentProps<typeof Button>["size"];
	variant?: ComponentProps<typeof Button>["variant"];
	children?: ReactNode | undefined;
	wrap?: boolean;
}

export const ButtonGroup = ({
	size,
	variant,
	children,
	wrap = true,
}: ButtonGroupProps) => {
	return (
		<div className={clsx("group inline-flex", wrap && "flex-wrap")}>
			<ButtonGroupContext.Provider value={{ size, variant }}>
				{children}
			</ButtonGroupContext.Provider>
		</div>
	);
};
