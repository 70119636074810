import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

interface AccessTokenState {
	accessToken: string | null;
}

const initialAccessTokenState: AccessTokenState = { accessToken: null };

export const accessTokenSlice = createSlice({
	name: "accessToken",
	initialState: initialAccessTokenState,
	reducers: {
		setAccessToken(state, action: PayloadAction<string>) {
			state.accessToken = action.payload;
		},
	},
});

export const { setAccessToken } = accessTokenSlice.actions;
