import { IconCheck } from "@tabler/icons-react";
import { clsx } from "clsx";
import { Text } from "../atoms/typography/Text.js";
import type { CSSProperties, ReactNode } from "react";

export const ListItem = ({
	onClick,
	label,
	isSelected,
	disabled = false,
	style,
}: {
	onClick: () => void;
	label: ReactNode;
	isSelected: boolean;
	disabled?: boolean;
	style?: CSSProperties | undefined;
}) => {
	return (
		<button
			type="button"
			className="flex w-full items-center gap-2 border-b p-2 text-left transition-colors last:border-b-0 hover:bg-gray-100"
			onClick={onClick}
			disabled={disabled}
			style={style}
		>
			<IconCheck
				size={18}
				className={clsx("text-gray-700 shrink-0", !isSelected && "invisible")}
			/>
			{typeof label === "string" ? (
				<Text
					color="text-gray-700"
					size="sm"
					className="truncate"
					title={label}
				>
					{label}
				</Text>
			) : (
				label
			)}
		</button>
	);
};
