import type { PipelineResponse } from "../service/pipeline";
import type { LeMasterKeyMapping } from "../service/companies";

export const getCurrentPipeline = (
	pipelineRuns: PipelineResponse[] | undefined,
) => {
	if (!pipelineRuns || pipelineRuns.length === 0) {
		return undefined;
	}
	const inProgressPipeline = pipelineRuns.find((pipelineRun) => {
		return pipelineRun.status === "InProgress";
	});

	if (inProgressPipeline) {
		return inProgressPipeline;
	}

	return pipelineRuns.slice(0).sort((a, b) => {
		return b.runStart.getTime() - a.runStart.getTime();
	})[0];
};

export const latestSuccessfulPump = (
	pumpId: number,
	pipelineRuns: PipelineResponse[],
	leMasterKeyMappings: LeMasterKeyMapping[],
) => {
	const pumpLegalEntities = leMasterKeyMappings
		.filter((le) => le.pumpId === pumpId)
		.flatMap((le) => le.leMasterKey);

	const successfulRuns = pipelineRuns.filter(
		(pipelineRun) =>
			pipelineRun.status === "Succeeded" &&
			(!pipelineRun.parameters?.leKey ||
				pumpLegalEntities.includes(pipelineRun.parameters.leKey)),
	);

	if (successfulRuns.length === 0) return;
	return successfulRuns.sort((a, b) => {
		return b.runStart.getTime() - a.runStart.getTime();
	})[0];
};
