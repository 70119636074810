import type { Builder } from "../../types.js";
import {
	buildGetHierarchyEndpoint,
	buildCreateHierarchyGroupEndpoint,
	buildUpdateHierarchyGroupEndpoint,
	buildRemoveHierarchyGroupEndpoint,
} from "../../utils/hierarchy.js";
import type { Supplier } from "./types.js";

export const buildHierarchyEndpoints = (builder: Builder) => ({
	getSupplierHierarchy: buildGetHierarchyEndpoint<Supplier>(
		"Supplier",
		"supplier",
		builder,
	),
	createSupplierHierarchyGroup: buildCreateHierarchyGroupEndpoint<Supplier>(
		"Supplier",
		"supplier",
		builder,
	),
	updateSupplierHierarchyGroup: buildUpdateHierarchyGroupEndpoint<Supplier>(
		"Supplier",
		"supplier",
		builder,
	),
	removeSupplierHierarchyGroup: buildRemoveHierarchyGroupEndpoint<Supplier>(
		"Supplier",
		"supplier",
		builder,
	),
});
