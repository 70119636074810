import { clsx } from "clsx";
import { forwardRef, type ComponentProps } from "react";
import styles from "./NativeSelect.module.css";
import { ValidateHint } from "../../../../features/planning/components/ValidateHint";
import type { InputProps } from "../input/Input";
import {
	COMMON_BORDERLESS_CLASS,
	COMMON_INPUT_CLASS,
} from "../input/inputUtils";
import type { Except } from "type-fest";

interface NativeSelectProps
	extends Except<ComponentProps<"select">, "aria-invalid">,
		Pick<InputProps, "hint" | "containerProps"> {
	borderless?: boolean;
	ariaInvalid?: boolean;
}

export const NativeSelect = forwardRef<HTMLSelectElement, NativeSelectProps>(
	function NativeSelect(
		{
			className,
			ariaInvalid = false,
			hint,
			containerProps,
			borderless = false,
			...props
		},
		ref,
	) {
		return (
			<div {...containerProps}>
				<select
					className={clsx(
						borderless ? COMMON_BORDERLESS_CLASS : COMMON_INPUT_CLASS,
						"block w-full text-sm text-gray-900 disabled:cursor-not-allowed disabled:text-gray-500",
						styles.select,
						ariaInvalid ? "text-red-500" : "text-gray-900",
						className,
					)}
					aria-invalid={ariaInvalid}
					{...props}
					ref={ref}
				/>
				{hint && <ValidateHint error={ariaInvalid}>{hint}</ValidateHint>}
			</div>
		);
	},
);
