import { useContext } from "react";
import { UserContext } from "./useUser";

export const useUser = () => {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error(
			"could not find user context value; please ensure the component is wrapped in a <ProvideUser>",
		);
	}
	return context;
};
