import { emptyFusionHubApi } from "./emptyFusionHubApi.ts";
import type {
	PaginatedParams,
	FusionhubIntegration,
	PaginatedResponse,
	ErLegalEntity,
	ErDocumentation,
	ErSyncStatus,
	FusionHubJob,
	FusionHubJobStatus,
} from "./types.ts";

export type ErSyncStatusWithFusionHubIntegration = {
	status: ErSyncStatus | null;
	integration: FusionhubIntegration;
};

export const fusionHubApi = emptyFusionHubApi.injectEndpoints({
	endpoints: (builder) => ({
		getErDocumentation: builder.query<ErDocumentation, void>({
			query: () => "/er/documentation",
		}),
		getAllIntegrations: builder.query<
			PaginatedResponse<FusionhubIntegration>,
			{ companyDomainId: string } & PaginatedParams
		>({
			query: (params) => ({
				url: "/integrations",
				params,
			}),
			providesTags: () => [
				{
					type: "Integrations",
				},
			],
		}),
		getErIntegrations: builder.query<
			PaginatedResponse<FusionhubIntegration>,
			{ companyDomainId: string } & PaginatedParams
		>({
			query: (params) => ({
				url: "/er/integrations",
				params,
			}),
			providesTags: () => [
				{
					type: "Integrations",
				},
			],
		}),
		getErLegalEntity: builder.query<ErLegalEntity, { integrationId: number }>({
			query: ({ integrationId }) =>
				`/er/integrations/${integrationId}/legal-entity`,
		}),
		getErSyncStatus: builder.query<
			{
				syncs: ErSyncStatusWithFusionHubIntegration[];
			},
			{ integrationIds: number[] }
		>({
			query: ({ integrationIds }) => {
				return {
					url: `/er/integrations/sync-aggregated?${integrationIds.map((id) => `integrationId=${id}`).join("&")}`,
				};
			},
			providesTags: (_result, error, { integrationIds }) => {
				if (error) {
					return ["SyncStatus"];
				}
				return integrationIds.map((integrationId) => {
					return { type: "SyncStatus", id: integrationId };
				});
			},
		}),
		createDemoIntegration: builder.mutation<
			FusionhubIntegration,
			{ companyDomainId: string }
		>({
			query: ({ companyDomainId }) => ({
				url: "/integrations",
				params: { companyDomainId },
				method: "POST",
				body: { companyDomainId, type: "DEMO" },
			}),
			invalidatesTags: [{ type: "Integrations" }],
		}),
		triggerSync: builder.mutation<void, { integrationIds: number[] }>({
			query: ({ integrationIds }) => ({
				url: "/er/integrations/sync",
				method: "POST",
				body: { integrationIds },
			}),
			invalidatesTags: (_result, _error, { integrationIds }) => {
				return integrationIds.flatMap((integrationId) => [
					{ type: "SyncStatus", id: integrationId },
					{ type: "Integrations", id: integrationId },
				]);
			},
		}),
		removeIntegration: builder.mutation<void, { integrationId: number }>({
			query: ({ integrationId }) => ({
				url: `/integrations/${integrationId}`,
				method: "DELETE",
			}),
			invalidatesTags: (_result, _error, { integrationId }) => [
				{ type: "SyncStatus", id: integrationId },
				{ type: "Integrations" },
			],
		}),

		getJobs: builder.query<
			PaginatedResponse<FusionHubJob>,
			{
				companyDomainId?: string | undefined;
				integrationId?: string | undefined;
				status?: FusionHubJobStatus[] | undefined;
				jobNames?: string[] | undefined;
			} & PaginatedParams
		>({
			query({ status, jobNames, ...params }) {
				const searchParams = new URLSearchParams();
				if (status) {
					for (const s of status) {
						searchParams.append("status", s);
					}
				}

				if (jobNames) {
					for (const j of jobNames) {
						searchParams.append("jobNames", j);
					}
				}
				return {
					url: `/jobs?${searchParams.toString()}`,
					params: params,
				};
			},
			providesTags(result) {
				return result
					? result.items.map((job) => ({ type: "Job", id: job.id }))
					: [{ type: "Job" }];
			},
		}),

		getJob: builder.query<FusionHubJob, { jobId: string }>({
			query(params) {
				return {
					url: `/jobs/${params.jobId}`,
					params,
				};
			},
			providesTags(_result, _error, args) {
				return [{ type: "Job", id: `${args.jobId}` }];
			},
		}),

		getJobNames: builder.query<
			string[],
			{
				companyDomainId?: string | undefined;
				integrationId?: string | undefined;
			}
		>({
			query(params) {
				return {
					url: "/jobs/job-names",
					params,
				};
			},
		}),

		getIntegration: builder.query<
			FusionhubIntegration,
			{ integrationId: string }
		>({
			query({ integrationId }) {
				return {
					url: `/integrations/${integrationId}`,
				};
			},
			providesTags: (_result, _error, { integrationId }) => [
				{ type: "Integrations", id: integrationId },
			],
		}),
	}),
});

export const {
	useGetAllIntegrationsQuery,
	useGetErIntegrationsQuery,
	useGetErLegalEntityQuery,
	useGetErDocumentationQuery,
	useGetErSyncStatusQuery,
	useTriggerSyncMutation,
	useRemoveIntegrationMutation,
	useCreateDemoIntegrationMutation,
	useGetJobsQuery,
	useGetJobQuery,
	useGetJobNamesQuery,
	useGetIntegrationQuery,
} = fusionHubApi;
