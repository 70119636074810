import type { TextProps } from "../typography/Text";

export type StepperSize = "sm" | "md" | "lg";

export const stepperSizes: Record<
	StepperSize,
	{
		iconSize: number;
		labelSize: TextProps["size"];
		dotSize: number;
		horizontalIconSpacing: number;
		verticalIconSpacing: number;
	}
> = {
	sm: {
		iconSize: 24,
		labelSize: "sm",
		dotSize: 8,
		horizontalIconSpacing: 14,
		verticalIconSpacing: 12,
	},
	md: {
		iconSize: 32,
		labelSize: "md",
		dotSize: 10,
		horizontalIconSpacing: 20,
		verticalIconSpacing: 16,
	},
	lg: {
		iconSize: 40,
		labelSize: "md",
		dotSize: 12,
		horizontalIconSpacing: 24,
		verticalIconSpacing: 16,
	},
};
