import { createContext, type ReactNode, useMemo } from "react";
import { LoadingState } from "../components/atoms/loadingState/LoadingState";
import { useCurrentLegalEntity } from "../legal-entities/useCurrentLegalEntity.js";
import { useLegalEntities } from "../legal-entities/useLegalEntities.js";
import { extractLegalEntitiesFromHierarchy } from "../legal-entities/utils/extractLegalEntitiesFromHierarchy.js";
import { getLegalEntityInHierarchy } from "../legal-entities/utils/getLegalEntityInHierarchy.js";
import { useLegalEntityHierarchy } from "../legal-entities/useLegalEntityHierarchy.js";
import type { LegalEntity } from "../service/nexus/endpoints/companyDomain/types.js";

export interface LegacyLegalEntity {
	groupName: string;
	groupMasterKey: string;
	memberMasterKeys: string[];
}

type LegacyLegalEntitiesContextType = {
	legalEntities: LegacyLegalEntity[];
	legalEntity: LegacyLegalEntity | null;
	legalEntityById: Record<string, LegacyLegalEntity>;
	isFetching: boolean;
};

export const LegacyLegalEntitiesContext =
	createContext<LegacyLegalEntitiesContextType | null>(null);
LegacyLegalEntitiesContext.displayName = "LegacyLegalEntitiesContext";

function useProvideLegalEntities() {
	const { legalEntities, isLoading, isFetching } = useLegalEntities();

	const {
		hierarchy,
		isLoading: isLoadingHierarchy,
		isFetching: isFetchingHierarchy,
	} = useLegalEntityHierarchy();

	const state = useMemo(() => {
		if (!legalEntities || !hierarchy) {
			return { legalEntities: [], legalEntityById: {} };
		}

		const extractedLegalEntities = legalEntities.map((legalEntity) => {
			let childLegalEntities: LegalEntity[] = [];

			if (hierarchy.hierarchy) {
				const partOfHierarchy = getLegalEntityInHierarchy(
					legalEntity,
					hierarchy.hierarchy,
				);

				if (partOfHierarchy) {
					childLegalEntities =
						extractLegalEntitiesFromHierarchy(partOfHierarchy);
				}
			}

			return {
				groupName: legalEntity.name,
				groupMasterKey: legalEntity.legalEntityMasterKey,
				memberMasterKeys: childLegalEntities.map(
					(legalEntity) => legalEntity.legalEntityMasterKey,
				),
			};
		});

		return {
			legalEntities: extractedLegalEntities,
			legalEntityById: extractedLegalEntities.reduce<
				Record<string, LegacyLegalEntity>
			>((map, item) => {
				map[item.groupMasterKey] = item;
				return map;
			}, {}),
		};
	}, [hierarchy, legalEntities]);

	const { legalEntity: currentLegalEntity } = useCurrentLegalEntity();

	return useMemo(() => {
		const currentLegacyLegalEntity = currentLegalEntity
			? state.legalEntityById[currentLegalEntity.legalEntityMasterKey]
			: null;

		return {
			legalEntities: state.legalEntities,
			legalEntity: currentLegacyLegalEntity,
			legalEntityById: state.legalEntityById,
			isLoading: isLoading || isLoadingHierarchy,
			isFetching: isFetching || isFetchingHierarchy,
		};
	}, [
		currentLegalEntity,
		state.legalEntityById,
		state.legalEntities,
		isLoading,
		isLoadingHierarchy,
		isFetching,
		isFetchingHierarchy,
	]);
}

interface Props {
	children?: ReactNode | undefined;
}

export function ProvideLegacyLegalEntities({ children }: Props) {
	const { isLoading, ...legalEntities } = useProvideLegalEntities();
	if (isLoading) {
		return <LoadingState />;
	}

	return (
		<LegacyLegalEntitiesContext.Provider value={legalEntities}>
			{children}
		</LegacyLegalEntitiesContext.Provider>
	);
}
