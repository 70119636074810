import { default as i18n } from "i18next";
import { initReactI18next } from "react-i18next";
import translation from "../../locales/sv.json";
import detector from "i18next-browser-languagedetector";

const resources = {
	sv: {
		translation,
	},
} as const;

i18n
	.use(detector)
	.use(initReactI18next)
	.init({
		fallbackLng: "en",
		returnNull: false,
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		keySeparator: false,
		resources,
		supportedLngs: ["en", "sv"],
	});

document.documentElement.setAttribute("lang", i18n.language);
i18n.on("languageChanged", (lang) => {
	document.documentElement.setAttribute("lang", lang);
});
