const isNullOrUndefined = (
	value: string | number | null | undefined,
): value is null | undefined => value === null || typeof value === "undefined";

export const sortArrayByAttribute = <T>(
	items: T[],
	getAttribute: (item: T) => string | number | null | undefined,
	order: "asc" | "desc" = "asc",
) => {
	const newItems = [...items];

	newItems.sort((a, b) => {
		const aKey = getAttribute(a);
		const bKey = getAttribute(b);

		if (isNullOrUndefined(aKey) || isNullOrUndefined(bKey)) {
			if (isNullOrUndefined(aKey) && !isNullOrUndefined(bKey)) {
				return order === "asc" ? -1 : 1;
			}

			if (!isNullOrUndefined(aKey) && isNullOrUndefined(bKey)) {
				return order === "asc" ? 1 : -1;
			}

			return 0;
		}

		if (aKey > bKey) {
			return order === "asc" ? 1 : -1;
		}

		if (aKey < bKey) {
			return order === "asc" ? -1 : 1;
		}

		return 0;
	});

	return newItems;
};
