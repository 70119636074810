import { IconFlagCheck } from "@tabler/icons-react";
import { useState, useEffect } from "react";
import { Button } from "./common/components/atoms/button/Button.tsx";
import { CheckboxField } from "./common/components/atoms/checkbox/CheckboxField.tsx";
import { Dialog } from "./common/components/atoms/dialog/Dialog.tsx";
import { DialogActions } from "./common/components/atoms/dialog/DialogActions.tsx";
import { DialogBody } from "./common/components/atoms/dialog/DialogBody.tsx";
import { DialogContent } from "./common/components/atoms/dialog/DialogContent.tsx";
import { DialogTitle } from "./common/components/atoms/dialog/DialogTitle.tsx";
import { DialogTrigger } from "./common/components/atoms/dialog/DialogTrigger.tsx";
import {
	FEATURE_FLAG_ENABLED_STORAGE_KEY,
	type FeatureFlagName,
	loadFeatureFlags,
	toggleFeature,
} from "./featureFlagUtils.ts";

export const FeatureFlags = () => {
	const [open, setOpen] = useState(
		Boolean(sessionStorage.getItem(FEATURE_FLAG_ENABLED_STORAGE_KEY) !== null),
	);
	const [flags] = useState(loadFeatureFlags());

	useEffect(() => {
		let count = 0;

		const listener = (event: KeyboardEvent) => {
			if (open) {
				return;
			}
			if (event.key === "Home") {
				count++;
				if (count === 7) {
					sessionStorage.setItem(FEATURE_FLAG_ENABLED_STORAGE_KEY, "");
					setOpen(true);
				}
			} else {
				count = 0;
			}
		};
		window.addEventListener("keydown", listener);
		return () => {
			window.removeEventListener("keydown", listener);
		};
	}, [open]);

	if (!open) {
		return null;
	}

	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button
					className="fixed bottom-4 right-4 z-50"
					variant="secondaryGray"
					icon={<IconFlagCheck />}
					ariaLabel="Toggle Feature Flag"
				/>
			</DialogTrigger>
			<DialogContent>
				<DialogTitle>Feature Flags</DialogTitle>
				<DialogBody>
					<div className="mb-4 flex flex-col gap-2">
						{Object.entries(flags).map(([name, enabled]) => {
							return (
								<CheckboxField
									key={name}
									checked={enabled}
									label={name}
									onChange={(event) => {
										toggleFeature(
											name as FeatureFlagName,
											event.target.checked,
										);
										window.location.reload();
									}}
								/>
							);
						})}
					</div>

					<button
						type="button"
						className="text-red-500"
						onClick={() => {
							setOpen(false);
							sessionStorage.removeItem(FEATURE_FLAG_ENABLED_STORAGE_KEY);
						}}
					>
						Disable Feature Flag Button
					</button>
				</DialogBody>
				<DialogActions withCancelButton />
			</DialogContent>
		</Dialog>
	);
};
