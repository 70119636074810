import { emptyPlanningApi } from "./emptyPlanningApi.js";

const changeLogApi = emptyPlanningApi.injectEndpoints({
	endpoints: (builder) => ({
		getChangeLogLastRead: builder.query<
			{
				id: string | null;
			},
			void
		>({
			query: () => "/change-log/last-read",
			providesTags: ["ChangeLogLastRead"],
		}),
		updateChangeLogLastRead: builder.mutation<
			{
				id: string | null;
			},
			string
		>({
			query: (id) => ({
				url: `/change-log/last-read/${id}`,
				method: "put",
			}),
			invalidatesTags: ["ChangeLogLastRead"],
		}),
	}),
});

export const {
	useGetChangeLogLastReadQuery,
	useUpdateChangeLogLastReadMutation,
} = changeLogApi;
