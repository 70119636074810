import { clsx } from "clsx";
import { forwardRef, type HTMLAttributes } from "react";
import styles from "./Avatar.module.css";

interface AvatarProps extends HTMLAttributes<HTMLSpanElement> {
	size: "xs" | "sm" | "md" | "lg" | "xl";
	src: string;
	alt: string;
}

export const Avatar = forwardRef<HTMLSpanElement, AvatarProps>(
	({ size, className, src, alt, ...props }, ref) => {
		return (
			<span
				className={clsx(styles.avatarSpan, styles[`size_${size}`], className)}
				ref={ref}
				aria-hidden="true"
				{...props}
			>
				<img alt={alt} src={src} loading="lazy" />
			</span>
		);
	},
);

Avatar.displayName = "Avatar";
