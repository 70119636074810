import type { DomainPlan } from "@exopengithub/planning-api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../common/components/atoms/button/Button";
import { DeletePlanDialog } from "../../../plans/DeletePlanDialog/DeletePlanDialog";

interface DeletePlanButton {
	plan: DomainPlan;
	disabled: boolean;
	onDelete?: (() => void) | undefined;
}

export const DeletePlanButton = ({
	plan,
	disabled,
	onDelete,
}: DeletePlanButton) => {
	const { t } = useTranslation();
	const [openDelete, setOpenDelete] = useState(false);

	const handleCloseDeleteDialog = () => {
		setOpenDelete(false);
	};

	return (
		<>
			<DeletePlanDialog
				plan={plan}
				open={openDelete}
				onClose={handleCloseDeleteDialog}
				onDelete={onDelete}
			/>
			<Button
				onClick={() => {
					setOpenDelete(true);
				}}
				destructive
				disabled={disabled}
			>
				{t("Delete plan")}
			</Button>
		</>
	);
};
