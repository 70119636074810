import { useContext } from "react";
import { PlanContext } from "./PlanContext.tsx";

export const usePlanContextOrThrow = () => {
	const context = useContext(PlanContext);

	if (context == null) {
		throw new Error(
			"could not find plan context value; please ensure the component is wrapped in a <PlanContext.Provider />",
		);
	}

	return context;
};
