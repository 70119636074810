import {
	buildPlanRouteUrl,
	type DomainIncomingBalance,
} from "@exopengithub/planning-api-shared";
import { emptyPlanningApi } from "./emptyPlanningApi.js";

const incomingBalanceApi = emptyPlanningApi.injectEndpoints({
	endpoints: (builder) => ({
		getIncomingBalancesForPlan: builder.query<
			DomainIncomingBalance[],
			{
				companyDomainId: string;
				legalEntityId: string;
				planId: string;
			}
		>({
			query: ({ planId, companyDomainId, legalEntityId }) =>
				buildPlanRouteUrl("/incoming-balance", {
					companyDomainId,
					legalEntityId,
					planId,
				}),
			providesTags: (_result, _error, arg) => [
				{ type: "IncomingBalance", id: arg.planId },
			],
		}),

		updateIncomingBalanceForPlan: builder.mutation<
			void,
			{
				companyDomainId: string;
				legalEntityId: string;
				planId: string;
				accountId: string;
				amount: number;
			}
		>({
			query: ({
				planId,
				companyDomainId,
				accountId,
				amount,
				legalEntityId,
			}) => ({
				method: "PUT",
				url: buildPlanRouteUrl(`/incoming-balance/${accountId}`, {
					companyDomainId,
					legalEntityId,
					planId,
				}),
				body: { amount },
			}),
			invalidatesTags: (_result, _error, arg) => [
				{ type: "IncomingBalance", id: arg.planId },
			],
		}),

		resetIncomingBalanceForPlan: builder.mutation<
			void,
			{
				companyDomainId: string;
				legalEntityId: string;
				planId: string;
			}
		>({
			query: ({ planId, companyDomainId, legalEntityId }) => ({
				method: "PUT",
				url: buildPlanRouteUrl("/incoming-balance", {
					companyDomainId,
					legalEntityId,
					planId,
				}),
			}),
			invalidatesTags: (_result, _error, arg) => [
				{ type: "IncomingBalance", id: arg.planId },
			],
		}),
	}),
	overrideExisting: false,
});

export const {
	useGetIncomingBalancesForPlanQuery,
	useUpdateIncomingBalanceForPlanMutation,
	useResetIncomingBalanceForPlanMutation,
} = incomingBalanceApi;
