import { forwardRef, type ComponentProps } from "react";
import { clsx } from "clsx";

interface GridContainerProps extends ComponentProps<"div"> {
	templateRows?: string | undefined;
	templateColumns: string;
	ariaLabel?: string | undefined;
}

export const GridContainer = forwardRef<HTMLDivElement, GridContainerProps>(
	function GridContainer(
		{ className, style, templateColumns, templateRows, ariaLabel, ...props },
		ref,
	) {
		return (
			<div
				className={clsx("grid overflow-auto", className)}
				style={{
					gridTemplateRows: templateRows,
					gridTemplateColumns: templateColumns,
					...style,
				}}
				aria-label={ariaLabel}
				ref={ref}
				{...props}
			/>
		);
	},
);
