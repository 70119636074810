import { type ReactNode, useMemo, useState } from "react";
import { FilterPopover } from "src/common/components/item-picker/FilterPopover";
import { ListItem } from "src/common/components/item-picker/ListItem";

export const ItemPicker = <T extends string>({
	items,
	onSelect,
	selected,
	filterLabel,
	filterHeaderLabel,
	placeholderLabel,
	defaultValue,
	withFilter,
}: {
	items: { id: T; label: ReactNode }[];
	onSelect: (id: T | null) => void;
	selected: T | null;
	filterLabel: string;
	filterHeaderLabel: string;
	placeholderLabel?: string;
	defaultValue?: T;
	withFilter?: boolean;
}) => {
	const [filter, setFilter] = useState("");

	const filteredItems = useMemo(() => {
		if (filter === "") {
			return items;
		}
		return items.filter((item) => {
			return typeof item.label === "string"
				? item.label.toLocaleLowerCase().includes(filter)
				: false;
		});
	}, [filter, items]);

	const activeIndex = useMemo(() => {
		const index = filteredItems.findIndex((item) => {
			return item.id === selected;
		});
		if (index === -1) {
			return null;
		}
		return index;
	}, [filteredItems, selected]);

	return (
		<FilterPopover
			isActive={selected !== null}
			label={filterLabel}
			header={filterHeaderLabel}
			items={filteredItems}
			activeIndex={activeIndex}
			placeholderLabel={placeholderLabel}
			onFilter={withFilter ? setFilter : undefined}
			onClose={() => setFilter("")}
		>
			{({ close, virtualItem, style }) => {
				const { id, label } = filteredItems[virtualItem.index];
				return (
					<ListItem
						isSelected={
							selected === id ||
							(defaultValue !== undefined &&
								id === defaultValue &&
								selected === null)
						}
						label={label}
						key={id}
						onClick={() => {
							onSelect(selected === id ? null : id);
							close();
						}}
						style={style}
					/>
				);
			}}
		</FilterPopover>
	);
};
