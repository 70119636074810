import { clsx } from "clsx";

const colors = [
	"#f44336",
	"#e91e63",
	"#9c27b0",
	"#673ab7",
	"#3f51b5",
	"#2196f3",
	"#03a9f4",
	"#00bcd4",
	"#009688",
	"#4caf50",
	"#8bc34a",
	"#cddc39",
	"#ffeb3b",
	"#ffc107",
	"#ff9800",
	"#ff5722",
	"#795548",
	"#607d8b",
];

interface ColorPickerProps {
	onChange: (color: string) => void;
	value: string;
}

export const ColorPicker = ({ value, onChange }: ColorPickerProps) => {
	return (
		<div className="flex flex-wrap gap-2">
			{colors.map((color) => {
				return (
					<button
						key={color}
						className={clsx(
							"h-[30px] w-[30px] rounded-full",
							value === color && "border-[4px] border-double border-white",
						)}
						type="button"
						aria-pressed={value === color}
						style={{ backgroundColor: color }}
						onClick={() => {
							onChange(color);
						}}
					/>
				);
			})}
		</div>
	);
};
