import { useTranslation } from "react-i18next";
import { Controller, useWatch, type UseFormReturn } from "react-hook-form";
import { RadioField } from "components/checkbox/RadioField";
import { Input } from "components/input/Input";
import { Select } from "components/select/Select";
import { ValidateHint } from "../../planning/components/ValidateHint";
import { FormSection } from "src/features/planning/components/formSection/FormSection";
import { Label } from "components/label/Label";
import { useId } from "react";
import type { Company } from "../../../common/service/companies.ts";

interface Props {
	form: UseFormReturn<any>;
	showWhatHappensNext?: boolean;
	companyDomains: Company[];
}

export const AddDataSourceCompanyDomainSection = ({
	form,
	showWhatHappensNext = true,
	companyDomains,
}: Props) => {
	const operation = useWatch({
		control: form.control,
		name: "operation",
	});

	const { t } = useTranslation();
	const companyDomainNameInputId = useId();

	const formCompanyDomainId = form.watch("companyDomainId");

	const knownCompanyDomain = companyDomains.find(
		(company) => formCompanyDomainId === company.id.toString(),
	);

	return (
		<>
			<FormSection
				title={t("Company domain")}
				description={
					companyDomains.length > 0
						? t(
								"Choose if you want to add the data source to an existing company domain or create a new one.",
							)
						: t(
								"It seems like you don't have access to any existing company domains. In order to complete the onboarding we need to create a new company domain where the data source can be added.",
							)
				}
			>
				{companyDomains.length > 0 && (
					<div className="mb-4 flex gap-4">
						<RadioField
							disabled={form.formState.isSubmitting}
							label={t("Add to existing company domain")}
							checked={operation === "add"}
							onChange={(event) => {
								if (event.target.checked) {
									form.setValue("operation", "add");
								}
							}}
						/>
						<RadioField
							disabled={form.formState.isSubmitting}
							label={t("Create a new company domain")}
							checked={operation === "new"}
							onChange={(event) => {
								if (event.target.checked) {
									form.setValue("operation", "new");
								}
							}}
						/>
					</div>
				)}
				{operation === "add" ? (
					<Controller
						control={form.control}
						name="companyDomainId"
						rules={{
							required: t("This field is required"),
						}}
						render={({ field: { onChange, ...rhfField }, fieldState }) => {
							return (
								<>
									<Label id={companyDomainNameInputId}>
										{t("Company domain")}
									</Label>
									<Select
										disabled={form.formState.isSubmitting}
										withFilter
										aria-labelledby={companyDomainNameInputId}
										placeholder={t("Select a company domain")}
										{...rhfField}
										onChange={(value) => {
											if (value !== null) {
												onChange(value);
											}
										}}
										options={companyDomains.map((company) => {
											return {
												value: `${company.id}`,
												label: company.name,
											};
										})}
										ariaInvalid={fieldState.error !== undefined}
									/>
									{fieldState.error && (
										<ValidateHint error>
											{fieldState.error.message}
										</ValidateHint>
									)}
								</>
							);
						}}
					/>
				) : (
					<>
						<Label htmlFor={companyDomainNameInputId}>
							{t("Company domain name")}
						</Label>
						<Input
							{...form.register("companyDomainName", {
								required: t("This field is required"),
							})}
							id={companyDomainNameInputId}
							disabled={form.formState.isSubmitting}
							placeholder={t("Enter name")}
							aria-invalid={
								form.formState.errors.companyDomainName !== undefined
							}
						/>
					</>
				)}
			</FormSection>

			{showWhatHappensNext && (
				<FormSection title={t("What happens next?")}>
					<ol className="list-decimal">
						{operation === "add" ? (
							<li>
								{t(
									"The data source will be added to the {{name}} company domain",
									{
										name: knownCompanyDomain
											? knownCompanyDomain.name
											: "selected",
									},
								)}
							</li>
						) : (
							<>
								<li>
									{t(
										"A new company domain will be created, this will take a minute or two",
									)}
								</li>
								<li>
									{t(
										"The data source will be added to the newly created company domain",
									)}
								</li>
							</>
						)}
						<li>
							{t(
								"A data run will be started, it will take between thirty minutes to a couple of hours to complete depending on the source system and data size",
							)}
						</li>
						<li>
							{t(
								"You will recieve an email once the initial data load is complete",
							)}
						</li>
					</ol>
				</FormSection>
			)}
		</>
	);
};
