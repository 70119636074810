import type { ReactNode } from "react";
import { DrawerContext } from "./DrawerContext.ts";
import { type UseDrawerOptions, useDrawer } from "./useDrawer.ts";

export function Drawer({
	children,
	...options
}: {
	children: ReactNode;
} & UseDrawerOptions) {
	const drawer = useDrawer(options);
	return (
		<DrawerContext.Provider value={drawer}>{children}</DrawerContext.Provider>
	);
}
