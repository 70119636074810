import { useMemo } from "react";
import { range } from "../../../utils/range";

export const DOTS = Symbol("dots");

interface UsePaginationOptions {
	page: number;
	total: number;
	siblings?: number | undefined;
	boundaries?: number | undefined;
	onChange: (page: number) => void;
}

export const usePagination = ({
	total,
	siblings = 1,
	boundaries = 1,
	page,
	onChange,
}: UsePaginationOptions) => {
	const _total = Math.max(Math.trunc(total), 0);

	const setPage = (pageNumber: number) => {
		if (pageNumber <= 0) {
			onChange(1);
		} else if (pageNumber > _total) {
			onChange(_total);
		} else {
			onChange(pageNumber);
		}
	};

	const next = () => setPage(page + 1);
	const previous = () => setPage(page - 1);
	const first = () => setPage(1);
	const last = () => setPage(_total);

	const paginationRange = useMemo((): (number | typeof DOTS)[] => {
		const totalPageNumbers = siblings * 2 + 3 + boundaries * 2;
		if (totalPageNumbers >= _total) {
			return range(1, _total);
		}

		const leftSiblingIndex = Math.max(page - siblings, boundaries);
		const rightSiblingIndex = Math.min(page + siblings, _total - boundaries);

		const shouldShowLeftDots = leftSiblingIndex > boundaries + 2;
		const shouldShowRightDots = rightSiblingIndex < _total - (boundaries + 1);

		if (!shouldShowLeftDots && shouldShowRightDots) {
			const leftItemCount = siblings * 2 + boundaries + 2;
			return [
				...range(1, leftItemCount),
				DOTS,
				...range(_total - (boundaries - 1), _total),
			];
		}

		if (shouldShowLeftDots && !shouldShowRightDots) {
			const rightItemCount = boundaries + 1 + 2 * siblings;
			return [
				...range(1, boundaries),
				DOTS,
				...range(_total - rightItemCount, _total),
			];
		}

		return [
			...range(1, boundaries),
			DOTS,
			...range(leftSiblingIndex, rightSiblingIndex),
			DOTS,
			...range(_total - boundaries + 1, _total),
		];
	}, [siblings, boundaries, _total, page]);

	return {
		range: paginationRange,
		setPage,
		next,
		previous,
		first,
		last,
	};
};
