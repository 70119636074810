import type {
	DomainModule,
	ModuleRefType,
} from "@exopengithub/planning-api-shared";
import type { TFunction } from "i18next";
import { PLANNING_MODULE_INFO } from "./modules/moduleInfos.js";

export const getModuleNameByRef = (
	t: TFunction,
	refType: ModuleRefType,
): string => {
	const module = PLANNING_MODULE_INFO.find((m) => m.refType === refType);

	if (!module) {
		return refType;
	}

	return module.getTitle({ t });
};

export const getModuleName = (t: TFunction, module: DomainModule) => {
	return getModuleNameByRef(t, module.refType);
};
