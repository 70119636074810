import type { ReactNode } from "react";
import { Text } from "../../../common/components/atoms/typography/Text";
import { useAmountFormatter } from "../utils/amountFormat/AmountFormatContext.js";
import { Chip } from "components/chip/Chip.js";

const DrilldownDetailsCaretText = ({ children }: { children: ReactNode }) => (
	<Text size="sm" weight="regular" color="text-grey-500" className="pl-2">
		↳ {children}
	</Text>
);

export const DrilldownDetailsItem = ({
	children,
	name,
	amount,
	infoRows,
}: {
	name: ReactNode;
	amount: number;
	infoRows?: ReactNode[];
	children?: ReactNode | undefined;
}) => {
	const amountFormatter = useAmountFormatter();

	return (
		<div className="flex">
			<div className="flex-grow">
				<Text size="sm" weight="medium">
					{name}
				</Text>
				{children}
				{infoRows?.map((content, index) => (
					<DrilldownDetailsCaretText key={index}>
						{content}
					</DrilldownDetailsCaretText>
				))}
			</div>
			<Text size="sm" weight="regular">
				{amountFormatter(amount)}
			</Text>
		</div>
	);
};

export const DrilldownDetailsContainer = ({
	children,
	title,
}: {
	children: ReactNode;
	title: ReactNode;
}) => {
	return (
		<div className="space-y-2">
			<div>
				<Chip size="small" color="purple">
					{title}
				</Chip>
			</div>
			{children}
		</div>
	);
};
