import { forwardRef } from "react";
import { useCollapse } from "./useCollapse";

interface CollapseProps extends React.ComponentPropsWithoutRef<"div"> {
	children: React.ReactNode;
	in: boolean;
	transitionDuration?: number | undefined;
	transitionTimingFunction?: string;
	animateOpacity?: boolean;
}

export const Collapse = forwardRef<HTMLDivElement, CollapseProps>(
	(
		{
			children,
			in: opened,
			transitionDuration = 200,
			transitionTimingFunction = "ease",
			style,
			animateOpacity = true,
			...others
		},
		ref,
	) => {
		const duration = transitionDuration;
		const getCollapseProps = useCollapse({
			opened,
			transitionDuration,
			transitionTimingFunction,
		});

		if (duration === 0) {
			return opened ? <div {...others}>{children}</div> : null;
		}

		return (
			<div {...getCollapseProps({ style, ref, ...others })}>
				<div
					style={{
						opacity: opened || !animateOpacity ? 1 : 0,
						transition: animateOpacity
							? `opacity ${duration}ms ${transitionTimingFunction}`
							: "none",
					}}
				>
					{children}
				</div>
			</div>
		);
	},
);

Collapse.displayName = "Collapse";
