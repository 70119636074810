import { clsx } from "clsx";

export const PlanColorIndicator = ({
	float,
	className,
}: {
	float: number;
	className?: string;
}) => {
	return (
		<div
			className={clsx("size-3 shrink-0 rounded-full", className)}
			style={{ backgroundColor: `hsl(${float * 360}, 50%, 60%)` }}
		/>
	);
};
