import { clsx } from "clsx";
import {
	Children,
	cloneElement,
	type HTMLAttributes,
	isValidElement,
	type ReactElement,
} from "react";

interface TabsProps extends HTMLAttributes<HTMLDivElement> {
	selectedIndex?: number;
	onSelectTab?: (tabIndex: number) => void;
}

export const Tabs = ({
	children,
	selectedIndex,
	onSelectTab,
	className,
	...props
}: TabsProps) => {
	const tabItems = Children.map(children, (child, index) => {
		if (isValidElement(child)) {
			return cloneElement(child as ReactElement, {
				selected: selectedIndex === index,
				onClick: () => {
					if (onSelectTab) {
						onSelectTab(index);
					}
				},
			});
		}
		return child;
	});

	return (
		<div
			role="tablist"
			className={clsx(
				"flex gap-4 overflow-x-auto border-b border-grey-200",
				className,
			)}
			{...props}
		>
			{tabItems}
		</div>
	);
};
