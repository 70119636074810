type ValidKeyType = string | number | bigint;
type OnlyWithKeysToKeys<T> = {
	[K in keyof T as T[K] extends ValidKeyType ? K : never]: T[K];
};

export const arrayToMapByKey = <
	T,
	Key extends
		keyof OnlyWithKeysToKeys<T> = "id" extends keyof OnlyWithKeysToKeys<T>
		? "id"
		: never,
>(
	arr: T[],
	key: Key = "id" as Key,
): Map<string, T> =>
	new Map(
		arr.map((element) => {
			return [`${element[key as keyof T]}`, element];
		}),
	);
