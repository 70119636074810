interface GetSize {
	size: string | number;
	sizes: Record<string, number>;
}

export function getSize(props: GetSize) {
	if (typeof props.size === "number") {
		return props.size;
	}

	const computedSize = props.sizes[props.size];

	return computedSize ?? (props.size || props.sizes.md);
}
