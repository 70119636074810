import { useTranslation } from "react-i18next";
import { useCurrentCompanyDomain } from "../../../common/company-domain/useCurrentCompanyDomain.js";
import { Button } from "../../../common/components/atoms/button/Button.js";
import { showErrorNotification } from "../../../common/components/atoms/notifications/events.js";
import type { LegalEntityEntity } from "../../../common/service/nexus/types.js";
import { useEntityHierarchy } from "../entityHierarchyProviderUtils";
import type { EntityHierarchy } from "src/common/service/nexus/utils/hierarchy.js";
import { Dialog } from "components/dialog/Dialog.js";
import { DialogTitle } from "components/dialog/DialogTitle.js";
import { DialogBody } from "components/dialog/DialogBody.js";
import { DialogActions } from "components/dialog/DialogActions.js";
import { DialogContent } from "components/dialog/DialogContent.js";

type Props = {
	isOpen: boolean;
	onClose: () => void;
	group: EntityHierarchy<LegalEntityEntity, Record<string, null>> | null;
};

export const RemoveGroupModal = ({ group, isOpen, onClose }: Props) => {
	const { t } = useTranslation();

	const { companyDomain } = useCurrentCompanyDomain();

	const {
		removeGroupMutation: [deleteGroup, { isLoading }],
		parentLegalEntityId: legalEntityId,
		type,
	} = useEntityHierarchy();

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogContent>
				<DialogTitle>{t("Remove group")}</DialogTitle>
				<DialogBody>
					<div className="text-sm mb-2">
						{t("Are you sure you want to remove {{groupName}}?", {
							groupName: group?.name,
						})}
					</div>
				</DialogBody>
				<DialogActions withCancelButton>
					<Button
						type="button"
						onClick={async () => {
							if (group) {
								const result = await deleteGroup({
									companyDomainId: companyDomain.id,
									legalEntityId,
									groupId: group.id,
									type,
								});

								if ("error" in result) {
									showErrorNotification({
										message: t("Error"),
									});
								} else {
									onClose();
								}
							}
						}}
						destructive
						isLoading={isLoading}
					>
						{t("Remove")}
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};
