import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { clsx } from "clsx";

interface DialogBodyProps extends ComponentPropsWithoutRef<"div"> {
	children?: ReactNode | undefined;
}

export const DialogBody = ({
	children,
	className,
	...props
}: DialogBodyProps) => {
	return (
		<div className={clsx("overflow-y-auto px-6 py-0", className)} {...props}>
			{children}
		</div>
	);
};
