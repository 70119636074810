import {
	createContext,
	forwardRef,
	type ReactElement,
	useContext,
	type ComponentProps,
} from "react";
import { Button } from "./Button";
import { ButtonGroup } from "./ButtonGroup";

interface ToggleButtonGroupContextValue {
	selected: string;
	onChange: (value: string) => void;
}

const ToggleButtonGroupContext = createContext<ToggleButtonGroupContextValue>(
	null as any,
);

type ToggleButtonProps = ComponentProps<typeof Button> & {
	value: string;
};

export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
	function ToggleButton({ value, ...props }, ref) {
		const { selected, onChange } = useContext(ToggleButtonGroupContext);
		const childrenProps = {
			"aria-pressed": selected === value,
			onClick: () => {
				onChange(value);
			},
			ref,
			...props,
		};

		return <Button {...childrenProps} />;
	},
);

interface ToggleButtonGroupProps {
	children: ReactElement<ToggleButtonProps>[];
	value: string;
	onChange: (value: string) => void;
}

export const ToggleButtonGroup = ({
	children,
	value,
	onChange,
}: ToggleButtonGroupProps) => {
	return (
		<ToggleButtonGroupContext.Provider value={{ selected: value, onChange }}>
			<ButtonGroup wrap={false}>{children}</ButtonGroup>
		</ToggleButtonGroupContext.Provider>
	);
};
