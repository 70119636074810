import { createApi } from "@reduxjs/toolkit/query/react";
import { makeBaseQueryWithUnauthentication } from "../../../common/redux/api/baseQuery";
import { planningApiUrl } from "../../../env";
import { isPlainObject } from "@reduxjs/toolkit";

// taken from RTK
function stripUndefined(obj: any) {
	if (!isPlainObject(obj)) {
		return obj;
	}
	const copy: Record<string, any> = { ...obj };
	for (const [k, v] of Object.entries(copy)) {
		if (v === undefined) delete copy[k];
	}
	return copy;
}

function extractArrays(params: Record<string, any>) {
	const arrays: [string, any][] = [];
	const nonArrays: [string, any][] = [];
	for (const keyVal of Object.entries(params)) {
		if (Array.isArray(keyVal[1])) {
			arrays.push(keyVal);
		} else {
			nonArrays.push(keyVal);
		}
	}

	return { arrays, nonArrays };
}

export const emptyPlanningApi = createApi({
	reducerPath: "planningApi",
	baseQuery: makeBaseQueryWithUnauthentication({
		baseUrl: planningApiUrl,
		paramsSerializer(params) {
			const paramsWithoutUndefined = stripUndefined(params);
			const { arrays, nonArrays } = extractArrays(paramsWithoutUndefined);
			const searchParams = new URLSearchParams(nonArrays);
			for (const [k, v] of arrays) {
				for (const item of v) {
					searchParams.append(k, item);
				}
			}
			return searchParams.toString();
		},
	}),
	tagTypes: [
		"Plan",
		"Account",
		"UnsyncedAccount",
		"AccountRecipe",
		"OrganizationalUnit",
		"Driver",
		"AllocationKey",
		"AccountLink",
		"Comment",
		"VatSetting",
		"Activity",
		"Asset",
		"PlanAsset",
		"AssetType",
		"Module",
		"PersonnelAgreement",
		"AccountingObject",
		"Employee",
		"EmployeeGroup",
		"EmployeeTransaction",
		"PlanEmployee",
		"EmployeeAllocationKey",
		"PlanningAccountNumber",
		"FinancialAsset",
		"PlanFinancialAsset",
		"FinancialAssetType",
		"Conversation",
		"ConversationMessage",
		"ConversationRead",
		"ComparisonData",
		"ConsultantType",
		"ConsultancyTransaction",
		"DebitRateKey",
		"ActualsForPlan",
		"AccountModuleEntry",
		"DriverModuleEntry",
		"ModuleCount",
		"DefaultOnboardingSettings",
		"Holiday",
		"Consultant",
		"IncomingBalance",
		"ChangeLogLastRead",
		"PlanUser",
		"ModulesCopyOptions",
	],
	endpoints() {
		return {};
	},
});
