import "./base.css";
import { LoadingState } from "./common/components/atoms/loadingState/LoadingState";
import "./common/utils/i18n.ts";
import "./style/index.css";
import { createRoot } from "react-dom/client";
import { StrictMode, Suspense } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { appInsightsConnectionString, auth0Domain, isStaging } from "./env";
import { App } from "./App.js";
import { FeatureFlags } from "./FeatureFlags.tsx";

if (import.meta.env.PROD && appInsightsConnectionString) {
	const appInsights = new ApplicationInsights({
		config: {
			connectionString: appInsightsConnectionString,
			isBeaconApiDisabled: true, // Cross-Origin Read Blocking (CORB)
			enableCorsCorrelation: true,
			correlationHeaderExcludedDomains: ["*.auth0.com", auth0Domain],
			enableAutoRouteTracking: true,
			enableRequestHeaderTracking: true,
			enableResponseHeaderTracking: true,
			enableAjaxPerfTracking: true,
			enableAjaxErrorStatusText: true,
			enableUnhandledPromiseRejectionTracking: true,
		},
	});
	appInsights.loadAppInsights();
	appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
}

const root = createRoot(document.getElementById("root")!);

const renderApp = async () => {
	if (isStaging) {
		console.warn("Mocking Exopen API requests with MSW");
		const { mockExopenApiRequests } = await import("./mocking/index.js");
		await mockExopenApiRequests();
	}

	root.render(
		<StrictMode>
			<Suspense fallback={<LoadingState />}>
				<App />
			</Suspense>
			<FeatureFlags />
		</StrictMode>,
	);
};

void renderApp();
