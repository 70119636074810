import { createContext } from "react";
import type { NotificationProps } from "./notificationTypes";

type NotificationsContextProps =
	| {
			notifications: NotificationProps[];
			queue: NotificationProps[];
	  }
	| undefined;

export const NotificationsContext =
	createContext<NotificationsContextProps>(undefined);
