import { useId, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../common/components/atoms/button/Button";
import { Label } from "../../../common/components/atoms/label/Label";
import { NativeSelect } from "../../../common/components/atoms/native-select/NativeSelect";
import { showErrorNotification } from "../../../common/components/atoms/notifications/events";
import type { LegalEntity } from "src/common/service/nexus/endpoints/companyDomain/types.js";
import { Dialog } from "components/dialog/Dialog.tsx";
import { DialogContent } from "components/dialog/DialogContent.tsx";
import { DialogTitle } from "components/dialog/DialogTitle.tsx";
import { DialogBody } from "components/dialog/DialogBody.tsx";
import { DialogActions } from "components/dialog/DialogActions.tsx";

interface Props {
	isOpen: boolean;
	availableLegalEntities: LegalEntity[];
	onAdd: (legalEntity: LegalEntity) => Promise<void>;
	onCancel: () => void;
}

export const AddCompanyToHierarchyModal = ({
	isOpen,
	availableLegalEntities,
	onAdd,
	onCancel,
}: Props) => {
	const { t } = useTranslation();

	const [selected, setSelected] = useState("");

	const [isAdding, setIsAdding] = useState(false);

	const formId = useId();
	const legalEntityInputId = useId();

	return (
		<Dialog onClose={onCancel} open={isOpen}>
			<DialogContent>
				<DialogTitle>{t("Add subsidiary")}</DialogTitle>
				<DialogBody className="pb-1">
					<form
						onSubmit={async (e) => {
							e.preventDefault();

							const legalEntity = availableLegalEntities.find(
								(node) => node.id === selected,
							);

							if (legalEntity) {
								setIsAdding(true);
								try {
									await onAdd(legalEntity);
									setIsAdding(false);
								} catch (e) {
									showErrorNotification({
										message: t("Error"),
									});
								} finally {
									setIsAdding(false);
								}
							}
						}}
						id={formId}
						className="space-y-4"
					>
						<div>
							<Label htmlFor={legalEntityInputId}>{t("Add subsidiary")}</Label>
							<NativeSelect
								onChange={(e) => setSelected(e.target.value)}
								value={selected}
								id={legalEntityInputId}
							>
								<option value="">{t("Select subsidiary company")}</option>
								{availableLegalEntities.map((node) => (
									<option key={node.id} value={node.id}>
										{node.name}
									</option>
								))}
							</NativeSelect>
						</div>
					</form>
				</DialogBody>
				<DialogActions withCancelButton>
					<Button
						disabled={!selected}
						type="submit"
						form={formId}
						isLoading={isAdding}
					>
						{t("Add to corporate structure")}
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};
