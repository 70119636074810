import { clsx } from "clsx";
import {
	Children,
	cloneElement,
	createContext,
	type CSSProperties,
	type Dispatch,
	type HTMLAttributes,
	isValidElement,
	type ReactElement,
	type ReactNode,
	type SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import useResizeObserver from "use-resize-observer";
import { invariant } from "../../../utils/invariant";
import { mergeRefs } from "../../../utils/mergeRefs";
import { Text } from "../typography/Text";
import { type StepperSize, stepperSizes } from "./stepperSizes";

type StepperContextValue =
	| {
			offsets: Record<
				number,
				{ stepOffset: number; left: number; right: number }
			>;
			setOffsets: Dispatch<
				SetStateAction<
					Record<number, { stepOffset: number; left: number; right: number }>
				>
			>;
			size: StepperSize;
	  }
	| undefined;

const StepperContext = createContext<StepperContextValue>(undefined);

const useStepperContextOrThrow = () => {
	const context = useContext(StepperContext);

	if (context == null) {
		throw new Error(
			"could not find stepper context value; please ensure the component is wrapped in a <StepperContext.Provider />",
		);
	}

	return context;
};

const connectorHeight = 2;
const connectorSpacing = 8;

interface ConnectorProps {
	size: StepperSize;
	index: number;
	highlight: boolean;
	children?: ReactNode | undefined;
}

const Connector = ({ size, index, highlight, children }: ConnectorProps) => {
	const { offsets } = useStepperContextOrThrow();

	const style: HTMLAttributes<HTMLDivElement>["style"] = {
		height: connectorHeight,
		top: stepperSizes[size].iconSize / 2 - connectorHeight / 2,
	};

	if (offsets[index] && offsets[index + 1]) {
		style.left =
			offsets[index].right - offsets[index].stepOffset + connectorSpacing;
		style.right =
			(offsets[index + 1].left - offsets[index + 1].stepOffset) * -1 +
			connectorSpacing;
	}

	return (
		<div
			className={clsx("absolute", highlight ? "bg-purple-500" : "bg-grey-200")}
			style={style}
		>
			{children}
		</div>
	);
};

interface StepProps {
	label: string;
	state?: "inactive" | "progress" | "completed";
	isLastStep?: boolean;
	index?: number;
	children?: ReactNode | undefined;
}

const Step = ({ label, state, isLastStep, index, children }: StepProps) => {
	invariant(typeof isLastStep === "boolean");
	invariant(typeof index === "number");

	const { size, setOffsets } = useStepperContextOrThrow();

	const stepRef = useRef<HTMLDivElement | null>(null);
	const { ref, width } = useResizeObserver<HTMLElement>();
	const { ref: containerRef, width: containerWidth } =
		useResizeObserver<HTMLElement>();

	// biome-ignore lint/correctness/useExhaustiveDependencies:
	useEffect(() => {
		if (width && stepRef.current) {
			const iconElement = stepRef.current.firstElementChild;
			const parent = stepRef.current.parentElement;
			if (iconElement && parent) {
				const { left: parentLeft } = parent.getBoundingClientRect();
				const { left, right } = iconElement.getBoundingClientRect();
				setOffsets((prev) => {
					return { ...prev, [index]: { stepOffset: parentLeft, left, right } };
				});
			} else {
				throw new Error();
			}
		}
	}, [width, setOffsets, containerWidth, index]);

	const iconStyle: CSSProperties = {
		width: stepperSizes[size].iconSize,
		height: stepperSizes[size].iconSize,
		marginBottom: stepperSizes[size].horizontalIconSpacing,
	};

	const withConnector = children === undefined ? !isLastStep : true;

	return (
		<div
			className="relative flex justify-center"
			aria-current={state === "progress"}
			ref={containerRef}
		>
			<div
				ref={mergeRefs([stepRef, ref])}
				className="flex items-center flex-col break-all"
			>
				<div
					className={clsx(
						"rounded-full flex items-center justify-center bg-purple-50 border ",
						state === "progress" && "shadow-purple-100 shadow-[0_0_0_4px]",
						state === "inactive" && "bg-white border-gray-200",
						state !== "inactive" && "border-purple-500",
					)}
					style={iconStyle}
				>
					{state === "completed" ? (
						<svg
							width={stepperSizes[size].iconSize - 4}
							height={stepperSizes[size].iconSize - 4}
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M17.0965 7.39004L9.9365 14.3L8.0365 12.27C7.6865 11.94 7.1365 11.92 6.7365 12.2C6.3465 12.49 6.2365 13 6.4765 13.41L8.7265 17.07C8.9465 17.41 9.3265 17.62 9.7565 17.62C10.1665 17.62 10.5565 17.41 10.7765 17.07C11.1365 16.6 18.0065 8.41004 18.0065 8.41004C18.9065 7.49004 17.8165 6.68004 17.0965 7.38004V7.39004Z"
								className="fill-purple-500"
							/>
						</svg>
					) : (
						<svg
							width={stepperSizes[size].dotSize}
							height={stepperSizes[size].dotSize}
						>
							<circle
								cx={stepperSizes[size].dotSize / 2}
								cy={stepperSizes[size].dotSize / 2}
								r={stepperSizes[size].dotSize / 2}
								className={
									state === "progress" ? "fill-purple-500" : "fill-grey-200"
								}
							/>
						</svg>
					)}
				</div>
				<Text
					color={state === "progress" ? "text-purple-700" : "text-grey-700"}
					size={stepperSizes[size].labelSize}
					weight="medium"
				>
					{label}
				</Text>
			</div>
			{withConnector && (
				<Connector
					size={size}
					index={index}
					highlight={state === "progress" || state === "completed"}
				>
					{children}
				</Connector>
			)}
		</div>
	);
};

interface StepperProps {
	size?: StepperSize;
	step: number;
	children: ReactNode;
	className?: string;
}

export const Stepper = ({
	size = "md",
	step,
	children,
	className,
}: StepperProps) => {
	const listChildren = Children.toArray(children);
	const [offsets, setOffsets] = useState<
		Record<number, { stepOffset: number; left: number; right: number }>
	>({});
	const steps = listChildren.reduce<ReactElement<StepProps>[]>(
		(acc, item, index, list) => {
			acc.push(
				cloneElement(item as ReactElement<StepProps>, {
					state:
						step === index
							? "progress"
							: step > index
								? "completed"
								: "inactive",
					isLastStep: list.length - 1 === index,
					index,
					children: undefined,
				}),
			);
			return acc;
		},
		[],
	);

	const contextValue = useMemo(() => {
		return { offsets, setOffsets, size };
	}, [offsets, size]);

	const child = Children.map(listChildren[step], (node) => {
		if (!isValidElement(node)) return;
		return Children.map(node.props.children, (childNode) => childNode);
	});

	return (
		<StepperContext.Provider value={contextValue}>
			<div className={clsx("grid grid-flow-col auto-cols-fr", className)}>
				{steps}
			</div>
			{child}
		</StepperContext.Provider>
	);
};

Stepper.Step = Step;
