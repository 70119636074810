import { useAuth0, type OAuthError } from "@auth0/auth0-react";

let getAccessTokenSilentlyResolve: (
	value: ReturnType<typeof useAuth0>["getAccessTokenSilently"],
) => void;
let logoutResolve: (value: ReturnType<typeof useAuth0>["logout"]) => void;

const getAccessTokenSilentlyPromise = new Promise<
	ReturnType<typeof useAuth0>["getAccessTokenSilently"]
>((resolve) => {
	getAccessTokenSilentlyResolve = resolve;
});

const logoutPromise = new Promise<ReturnType<typeof useAuth0>["logout"]>(
	(resolve) => {
		logoutResolve = resolve;
	},
);

export const getAccessTokenSilently = async () => {
	const getAccessTokenSilently = await getAccessTokenSilentlyPromise;
	return getAccessTokenSilently();
};

export const logout = async (
	...params: Parameters<Awaited<typeof logoutPromise>>
) => {
	const logout = await logoutPromise;
	return logout(...params);
};

export const DeferAuth0 = () => {
	const { getAccessTokenSilently, logout, error } = useAuth0();

	// https://github.com/auth0/auth0-react/issues/620
	if (
		error &&
		"error" in error &&
		(error as OAuthError).error === "missing_transaction"
	) {
		const searchParams = new URLSearchParams(window.location.search);
		searchParams.delete("code");
		searchParams.delete("state");
		window.location.search = searchParams.toString();
		return null;
	}
	getAccessTokenSilentlyResolve(getAccessTokenSilently);
	logoutResolve(logout);
	return null;
};
