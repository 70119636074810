import { usePopoverMenuContext } from "components/popoverMenu/usePopoverMenuContext";
import {
	cloneElement,
	type ReactElement,
	type MouseEvent,
	type ComponentType,
	type ReactNode,
	type HTMLProps,
} from "react";
import type { IconProps } from "@tabler/icons-react";
import { Text } from "../../typography/Text.tsx";

interface BasePopoverMenuItemProps
	extends Omit<HTMLProps<HTMLElement>, "label" | "onClick"> {
	icon?: ReactElement<IconProps>;
	label: ReactNode;
	onClick?: (e: MouseEvent) => void;
	isLoading?: boolean | undefined;
}

interface WithChilden {
	children?: ReactNode | undefined;
}

type PopoverMenuItemPropsWithComponent<TProps> = BasePopoverMenuItemProps & {
	component: ComponentType<TProps>;
} & TProps;

type PopoverMenuItemProps<TProps = {}> =
	| BasePopoverMenuItemProps
	| PopoverMenuItemPropsWithComponent<TProps>;

export function PopoverMenuItem<T extends WithChilden>({
	label,
	icon,
	isLoading,
	...rest
}: PopoverMenuItemProps<T>) {
	const { setOpen } = usePopoverMenuContext();

	const content = (
		<span className="group mb-1 flex items-center overflow-hidden rounded-lg px-3 py-2 last:mb-0 hover:bg-purple-25">
			{icon && (
				<span className="mr-3 flex w-[20px] items-center">
					{cloneElement(icon, {
						size: 20,
						className: "group-hover:text-purple-500 text-gray-500",
					})}
				</span>
			)}
			<Text color="text-gray-700" weight="medium" size="sm">
				{label}
			</Text>
		</span>
	);

	const onClick = (e: MouseEvent) => {
		rest.onClick?.(e);
		setOpen(false);
	};

	if ("component" in rest) {
		const { component: Component, ...restWithoutComponent } =
			rest as PopoverMenuItemPropsWithComponent<T>;
		return (
			<Component
				{...(restWithoutComponent as T)}
				onClick={onClick}
				role="menuitem"
			>
				{content}
			</Component>
		);
	}

	return (
		<button
			className="block"
			type="button"
			role="menuitem"
			onClick={onClick}
			tabIndex={-1}
			disabled={isLoading === true}
		>
			{content}
		</button>
	);
}
