import type { IconProps } from "@tabler/icons-react";
import { clsx } from "clsx";
import { type ReactElement, cloneElement } from "react";
import { Text } from "../../common/components/atoms/typography/Text";

interface LinkItemProps {
	isActive?: boolean;
	icon: ReactElement<IconProps>;
	label?: string | undefined;
}

export const LinkItem = ({ isActive, label, icon }: LinkItemProps) => {
	if (!label) {
		return (
			<div
				className={clsx(
					"rounded-[6px] p-[10px]",
					isActive ? "bg-primary-50" : "hover:bg-grey-50",
				)}
			>
				{cloneElement<IconProps>(icon, {
					size: 20,
					className: isActive ? "text-primary-500" : "text-grey-500",
				})}
			</div>
		);
	}

	return (
		<div
			className={clsx(
				"flex items-center gap-4 rounded-[6px] px-[12px] py-[10px]",
				isActive ? "bg-primary-50" : "hover:bg-grey-50",
			)}
		>
			<div className="shrink-0">
				{cloneElement<IconProps>(icon, {
					size: 20,
					className: isActive ? "text-primary-500" : "text-grey-500",
				})}
			</div>
			<div>
				<Text
					size="sm"
					weight="medium"
					color={isActive ? "text-primary-700" : "text-grey-700"}
				>
					{label}
				</Text>
			</div>
		</div>
	);
};
