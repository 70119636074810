type Comparable = string | number | boolean | null | undefined;

export const unique = <T, C extends Comparable>(
	array: T[],
	getId: (item: T) => C,
): T[] => {
	const seen = new Set<C>();
	return array.filter((item) => {
		const id = getId(item);
		if (seen.has(id)) {
			return false;
		}
		seen.add(id);
		return true;
	});
};
