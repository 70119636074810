import { DrilldownDetailsItem } from "src/features/planning/drilldown/DrilldownDetails";
import type { DrilldownDetailsRenderProps } from "../types";
import { getTransactionAmountFromAccount } from "../../../utils/getTransactionAmount.js";

export const ModuleDrilldownDetails = <MetaData = unknown>({
	transactions,
	accountById,
}: DrilldownDetailsRenderProps<MetaData>) => {
	return (
		<>
			{transactions.map((transaction, index) => {
				return (
					<DrilldownDetailsItem
						amount={getTransactionAmountFromAccount(transaction, accountById)}
						name={transaction.transactiondescr}
						key={index}
						infoRows={[]}
					/>
				);
			})}
		</>
	);
};
