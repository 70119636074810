/**
 * Creates an array of numbers from `start` to `end`, inclusive.
 * @param start The starting number of the range.
 * @param end The ending number of the range.
 * @returns An array of numbers from `start` to `end`, inclusive.
 */
export function range(start: number, end: number) {
	const length = end - start + 1;
	return Array.from({ length }, (_, index) => index + start);
}
