import type { Except } from "type-fest";
import type { ButtonLikeProps } from "./ButtonLike.js";

export const getButtonLikeProps = <T extends object>({
	variant,
	size,
	iconPosition,
	icon,
	isLoading,
	fullWidth,
	destructive,
	borderless,
	align,
	ariaLabel,
	...rest
}: T & ButtonLikeProps): {
	buttonLikeProps: Except<ButtonLikeProps, "children">;
	rest: T;
} => {
	return {
		buttonLikeProps: {
			variant,
			size,
			iconPosition,
			icon,
			isLoading,
			fullWidth,
			destructive,
			borderless,
			align,
			ariaLabel,
		},
		rest: rest as T,
	};
};
