import {
	IconDatabaseImport,
	IconDownload,
	IconExternalLink,
	IconMail,
	IconPhone,
} from "@tabler/icons-react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button } from "../../../../common/components/atoms/button/Button";
import { Label } from "../../../../common/components/atoms/label/Label";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../../../common/components/atoms/notifications/events";
import { BreadcrumbsLayout } from "../../../../common/components/layout/BreadcrumbsLayout";
import { useAddXledgerPumpMutation } from "../../../../common/redux/api/exopenApi";
import { invariant } from "../../../../common/utils/invariant";
import { Card } from "../../../planning/components/Card";
import { Input } from "../../../../common/components/atoms/input/Input";
import { usePersistedNavigate } from "../../../../common/persistentRouterFunctions";
import xledgerLogoFull from "./xledger-logo-full.webp";
import { Anchor } from "../../../../common/components/atoms/anchor/Anchor";
import { Text } from "../../../../common/components/atoms/typography/Text";
import { ButtonLink } from "../../../../common/components/atoms/button/ButtonLink.js";
import pdf from "./XledgerGuide.pdf";
import { useId } from "react";

interface Inputs {
	token: string;
}

export const XledgerPump = () => {
	const { t } = useTranslation();
	const { companyId } = useParams();
	invariant(companyId);
	const navigate = usePersistedNavigate();
	const form = useForm<Inputs>({ defaultValues: { token: "" } });
	const [addPump] = useAddXledgerPumpMutation();
	const submitHandler: SubmitHandler<Inputs> = async (data) => {
		const result = await addPump({
			token: data.token,
			companyId,
		});
		if ("error" in result) {
			showErrorNotification({ message: t("Failed to add data source") });
		} else {
			showSuccessNotification({ message: t("Data source was added") });
			navigate("./..");
		}
	};
	const tokenInputId = useId();

	return (
		<BreadcrumbsLayout
			breadcrumbs={[
				{
					icon: <IconDatabaseImport />,
				},
				{ name: t("Data sources"), path: "./../.." },
				{ name: t("New data source"), path: "./.." },
				{ name: "Xledger" },
			]}
		>
			<div className="flex gap-4">
				<div className="grow">
					<Card className="p-6">
						<form onSubmit={form.handleSubmit(submitHandler)}>
							<Label htmlFor={tokenInputId}>
								{t("Enter your API key below")}
							</Label>
							<Input
								{...form.register("token", {
									required: t("This field is required"),
								})}
								id={tokenInputId}
								containerProps={{ className: "mb-4" }}
								aria-invalid={form.formState.errors.token !== undefined}
								hint={form.formState.errors.token?.message}
							/>

							<Button
								variant="primary"
								isLoading={form.formState.isSubmitting}
								disabled={form.formState.isSubmitting}
								type="submit"
								className="float-right"
							>
								{t("Add data source")}
							</Button>
						</form>
					</Card>
				</div>
				<div className="w-[300px]">
					<Card className="p-6">
						<img
							src={xledgerLogoFull}
							alt="Xledger logotype"
							height={56}
							width={200}
							className="mb-4"
						/>
						<Text size="md" color="text-grey-700" weight="medium">
							{t("Contact")}
						</Text>
						<Anchor href="https://xledger.com/se/kontakt/" target="_blank">
							https://xledger.com/se/kontakt/
						</Anchor>
						<div className="flex items-center gap-x-1">
							<IconPhone size={18} className="text-grey-500" />
							<Anchor href="tel:+08-568 901 00" target="_blank">
								08 - 568 901 00
							</Anchor>
						</div>
						<div className="mb-4 flex items-center gap-x-1">
							<IconMail size={18} className="text-grey-500" />
							<Anchor href="mailto:info@xledger.se" target="_blank">
								info@xledger.se
							</Anchor>
						</div>
						<ButtonLink
							reloadDocument
							to="https://xledger.com/se/"
							variant="secondaryGray"
							icon={<IconExternalLink />}
							iconPosition="right"
							fullWidth
							align="center"
							className="mb-2"
							target="_blank"
						>
							{t("Read more")}
						</ButtonLink>
						<ButtonLink
							reloadDocument
							to={pdf}
							variant="secondaryGray"
							icon={<IconDownload />}
							fullWidth
							align="center"
							download
						>
							{t("Download guide")}
						</ButtonLink>
					</Card>
				</div>
			</div>
		</BreadcrumbsLayout>
	);
};
