import { createContext, useContext } from "react";
import type { useDrawer } from "./useDrawer.ts";

type DrawerContextType = ReturnType<typeof useDrawer> | null;

export const DrawerContext = createContext<DrawerContextType>(null);

export const useDrawerContext = () => {
	const context = useContext(DrawerContext);

	if (context == null) {
		throw new Error("Drawer components must be wrapped in <Drawer />");
	}

	return context;
};
