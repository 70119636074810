import type { useInteractions } from "@floating-ui/react";
import { createContext } from "react";

export interface MultiSelectContextValue {
	activeIndex: number | null;
	selectedIndicies: number[];
	getItemProps: ReturnType<typeof useInteractions>["getItemProps"];
	handleSelect: (index: number | null) => void;
}

export const MultiSelectContext = createContext<MultiSelectContextValue>(
	{} as MultiSelectContextValue,
);
