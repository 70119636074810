import { dateObjectToIsoDate } from "src/common/utils/date-utils/dateObjectToIsoDate.js";
import type { Builder } from "../../types.js";
import type {
	ExchangeRate,
	ExchangeCalculationMethod,
	ExchangeRateProvider,
} from "./types.js";

export const buildCurrencyExchangeRateEndpoints = (builder: Builder) => ({
	getCurrencyExchangeRate: builder.query<
		ExchangeRate[],
		{
			exchangeCalculationMethod: ExchangeCalculationMethod;
			exchangeRateProvider: ExchangeRateProvider;
			fromCurrency: string;
			toCurrency: string;
			fromDate: Date;
			toDate: Date;
		}
	>({
		query: ({
			exchangeCalculationMethod,
			exchangeRateProvider,
			fromCurrency,
			toCurrency,
			fromDate,
			toDate,
		}) => ({
			url: `/v1/exchange-rate/${fromCurrency}/${toCurrency}/${exchangeRateProvider}`,
			params: {
				calculationMethod: exchangeCalculationMethod,
				fromDate: dateObjectToIsoDate(fromDate),
				toDate: dateObjectToIsoDate(toDate),
			},
		}),
	}),
});
