import { useContext } from "react";
import { TokenContext } from "./useAuth0AccessToken.js";

export const useAccessToken = () => {
	const context = useContext(TokenContext);

	if (!context) {
		throw new Error(
			"could not find access token context value; please ensure the component is wrapped in a <ProvideAccessToken>",
		);
	}
	return context;
};
