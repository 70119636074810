export const ExopenLogo = () => {
	return (
		<svg
			viewBox="0 0 143 39"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M11.404 26.0083C7.85402 26.0083 5.81348 23.8325 5.36902 20.146H21.1665C23.2965 12.8624 19.258 7 11.448 7C4.4375 7 0 11.6629 0 18.7248C0 25.7866 4.39348 30.4495 11.316 30.4495C16.8625 30.4495 20.8115 27.4736 22.098 22.8107H16.597C15.8415 24.8543 13.889 26.0083 11.404 26.0083ZM11.36 11.2636C14.866 11.2636 16.685 13.3513 16.374 16.5489H5.45848C5.99098 13.1281 8.03152 11.2636 11.36 11.2636Z" />
			<path d="M58.1759 7C51.3869 7 46.86 11.707 46.86 18.7248C46.86 25.7426 51.3869 30.4495 58.1759 30.4495C64.9209 30.4495 69.4919 25.7426 69.4919 18.7248C69.4919 11.707 64.9209 7 58.1759 7ZM58.1759 26.0083C54.5365 26.0083 52.1409 23.1219 52.1409 18.7248C52.1409 14.3276 54.5365 11.4412 58.1759 11.4412C61.77 11.4412 64.2109 14.3276 64.2109 18.7248C64.2109 23.1219 61.77 26.0083 58.1759 26.0083Z" />
			<path d="M84.889 7C81.5619 7 78.9435 8.5093 77.3005 11.0418V7.62248H72.2425V38.2661H77.3005V26.3636C78.9435 28.9402 81.5605 30.4495 84.889 30.4495C90.7919 30.4495 95.0065 25.7866 95.0065 18.7248C95.0065 11.707 90.7919 7 84.889 7ZM83.469 26.0524C79.7415 26.0524 77.2565 23.1219 77.2565 18.7248C77.2565 14.3276 79.7415 11.4412 83.469 11.4412C87.2419 11.4412 89.7269 14.3276 89.7269 18.7248C89.7269 23.1219 87.2419 26.0524 83.469 26.0524Z" />
			<path d="M134.856 7C131.483 7 128.777 8.77648 127.312 11.707V7.62248H122.253V29.8285H127.312V18.9465C127.312 14.4172 129.663 11.3971 133.037 11.3971C136.942 11.3971 136.942 14.9942 136.942 17.5253V29.8285H142V17.3917C142 14.0619 142 7 134.856 7Z" />
			<path d="M108.763 26.0083C105.213 26.0083 103.171 23.8325 102.728 20.146H118.526C120.656 12.8624 116.617 7 108.807 7C101.797 7 97.358 11.6629 97.358 18.7248C97.358 25.7866 101.751 30.4495 108.674 30.4495C114.222 30.4495 118.171 27.4736 119.457 22.8107H113.955C113.201 24.8543 111.248 26.0083 108.763 26.0083ZM108.718 11.2636C112.224 11.2636 114.043 13.3513 113.733 16.5489H102.816C103.349 13.1281 105.391 11.2636 108.718 11.2636Z" />
			<path d="M35.943 12.7729L34.6125 14.5323L29.864 7.62248H24.096L31.7725 18.4135L29.154 21.9665C28.4165 23.0575 27.5189 24.0311 26.4915 24.8543C25.533 25.6033 24.2309 26.0083 22.72 26.0083V30.4495C28.4468 30.4495 30.9276 27.5972 32.9639 24.6767L39.76 15.483C40.4956 14.3909 41.3934 13.4176 42.4225 12.5966C43.381 11.8462 44.6391 11.4412 46.15 11.4412V7C40.4231 7 37.9807 9.85232 35.943 12.7729Z" />
			<path d="M46.15 29.8285L39.1651 20.0919C38.7097 20.2694 38.2826 20.5121 37.897 20.8125C37.0319 21.5 36.2608 22.2985 35.6037 23.1873L40.2031 29.8285H46.15Z" />
		</svg>
	);
};
