import { type Control, useController } from "react-hook-form";
import { ModuleSelectableList } from "./ModuleSelectableList";
import type { DomainModule } from "@exopengithub/planning-api-shared";
import { useTranslation } from "react-i18next";

interface ModuleInputs {
	modules: string[];
}

export const RHFModuleSelectableList = <T extends ModuleInputs>({
	control,
	modules,
	disabled,
	className,
}: {
	modules: DomainModule[];
	control: T extends ModuleInputs ? Control<T> : never;
	disabled?: boolean;
	className?: string | undefined;
}) => {
	const { t } = useTranslation();
	const controller = useController({
		control,
		name: "modules",
		rules: {
			validate: (modules) => {
				return modules.length >= 1 ? true : t("Choose at least one module");
			},
		},
	});

	return (
		<ModuleSelectableList
			modules={modules}
			className={className}
			selected={controller.field.value}
			onChange={(nextSelectedModules) => {
				controller.field.onChange(nextSelectedModules);
			}}
			error={controller.fieldState.error}
			readOnly={disabled}
		/>
	);
};
