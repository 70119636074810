import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { isProduction } from "./env";
import * as Sentry from "@sentry/react";

if (isProduction) {
	Sentry.init({
		dsn: "https://31162bd016a0fc55e98c1c339101553d@o4506904172494848.ingest.us.sentry.io/4506904175312896",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
			Sentry.inboundFiltersIntegration({}),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost"],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

export const SentryIdentity = () => {
	const { user, isAuthenticated } = useAuth0();

	useEffect(() => {
		if (isProduction && isAuthenticated && user && user.email) {
			Sentry.setUser({ email: user.email });
		}
	}, [isAuthenticated, user]);

	return null;
};
