import { createContext, useContext } from "react";
import type { Duration } from "src/common/utils/date-utils/getDuration";

export type LocaleGetMonth = (
	month: number | Date,
	type?: "long" | "short",
) => string;

export type FormatRange = (start: Date, end: Date) => string;

export type FormatRangeParts = (
	start: Date,
	end: Date,
) => Intl.DateTimeRangeFormatPart[];

export type Format = (
	date: Date,
	options?: Intl.DateTimeFormatOptions,
) => string;

type LocaleContextValue =
	| {
			getMonthsInYear: () => string[];
			getMonth: LocaleGetMonth;
			formatRange: FormatRange;
			format: Format;
			formatRangeParts: FormatRangeParts;
			formatRelative: (date: Date) => string;
			lang: string;
			formatNumber: (number: number | bigint) => string;
			formatDuration: (duration: Duration) => string;
	  }
	| undefined;

export const LocaleContext = createContext<LocaleContextValue>(undefined);

export const useLocale = () => {
	const context = useContext(LocaleContext);

	if (!context) {
		throw new Error(
			"could not find locale context value; please ensure the component is wrapped in a <ProvideLocale>",
		);
	}

	return context;
};
