import { Controller, type UseFormReturn } from "react-hook-form";
import { CheckboxField } from "../../../../common/components/atoms/checkbox/CheckboxField.tsx";
import { Collapse } from "../../../../common/components/atoms/collapse/Collapse.tsx";
import { QueriesResult } from "../../../../common/components/atoms/queryResult/QueriesResult.tsx";
import { MultiSelect } from "../../../../common/components/atoms/select/MultiSelect.tsx";
import { stringSort } from "../../../../common/utils/hooks/useSort.ts";
import { useGetOrganizationalUnitQuery } from "../../api/planningApi.ts";
import { ValidateHint } from "../../components/ValidateHint.tsx";
import { organizationalUnitsSelectors } from "../../utils/organizationalUnitsAdapter.ts";
import { useTranslation } from "react-i18next";
import { useCurrentPlanOrThrow } from "../../useCurrentPlan.ts";

export interface CostCenterPermissionsFormValues {
	allCostCenters: boolean;
	selectedCostCenters: string[];
}

export const CostCenterPermissions = <
	TFieldValues extends CostCenterPermissionsFormValues,
>({
	form,
}: {
	form: TFieldValues extends CostCenterPermissionsFormValues
		? UseFormReturn<TFieldValues>
		: never;
}) => {
	const { t } = useTranslation();
	const plan = useCurrentPlanOrThrow();
	const allCostCenters = form.watch("allCostCenters");

	return (
		<QueriesResult
			queries={{
				costCenters: useGetOrganizationalUnitQuery({
					companyDomainId: plan.companyId,
					planId: plan.id,
				}),
			}}
			render={({ costCenters }) => {
				return (
					<>
						<div>
							<Controller
								control={form.control}
								name="allCostCenters"
								render={({ field: { value, onChange, ...field } }) => {
									return (
										<CheckboxField
											{...field}
											checked={value}
											label={t("All cost centers")}
											onChange={(event) => {
												onChange(event.target.checked);
												if (event.target.checked) {
													form.setValue("selectedCostCenters", [], {
														shouldDirty: true,
													});
												}
											}}
										/>
									);
								}}
							/>
						</div>
						<Collapse in={!allCostCenters}>
							<Controller
								control={form.control}
								name="selectedCostCenters"
								rules={{
									validate: (value, formValues) => {
										if (formValues.allCostCenters) {
											return true;
										}

										if (value.length === 0) {
											return t("Select at least one cost center");
										}
									},
								}}
								render={({
									field: { value: currentValue, onChange, ...field },
									fieldState: { invalid, error },
								}) => {
									const sorted = organizationalUnitsSelectors
										.selectAll(costCenters)
										.sort((a, b) => {
											return stringSort(a.code, b.code);
										});
									return (
										<>
											<MultiSelect
												className="mt-1"
												ariaLabel={t("Cost centers")}
												withFilter
												clearable
												placeholder={t("Select cost centers")}
												onChange={(value) => {
													onChange(value);
												}}
												value={currentValue}
												{...field}
												options={sorted.map((costCenter) => {
													return {
														value: costCenter.nexusId,
														label: `${costCenter.code} - ${costCenter.name}`,
													};
												})}
												ariaInvalid={invalid}
											/>
											{error && (
												<ValidateHint error>{error.message}</ValidateHint>
											)}
										</>
									);
								}}
							/>
						</Collapse>
					</>
				);
			}}
		/>
	);
};
