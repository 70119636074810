import type { Builder, Dimension, LegalEntityEntity } from "../types.js";

export type EntityHierarchyGroup<
	T extends LegalEntityEntity = LegalEntityEntity,
	Attributes = Record<string, never>,
> = {
	id: string;
	name: string;
	sortOrder: number;
	parentId: string | null;
	items: T[];
	attributes: Attributes;
};

export type EntityHierarchy<
	T extends LegalEntityEntity,
	Attributes,
> = LegalEntityEntity &
	EntityHierarchyGroup<T, Attributes> & {
		children: EntityHierarchy<T, Attributes>[];
	};

export type EntityHierachyResponse<
	T extends LegalEntityEntity,
	Attributes,
> = LegalEntityEntity & {
	hierarchy: EntityHierarchy<T, Attributes>[];
	availableItems: T[];
};

export type CreateEntityHierarchyGroup<Attributes> = Pick<
	EntityHierarchyGroup<LegalEntityEntity, Attributes>,
	"name" | "sortOrder" | "parentId" | "attributes"
> & {
	itemIds: string[];
};

export type UpdateEntityHierarchyGroup<Attributes> = Partial<
	CreateEntityHierarchyGroup<Attributes>
>;

export type HierarchyType = "group" | "legalEntity";

export const buildGetHierarchyEndpoint = <
	O extends LegalEntityEntity,
	A = Record<string, never>,
>(
	dimension: Dimension,
	entityPath: string,
	builder: Builder,
	path = "/hierarchy",
) =>
	builder.query<
		EntityHierachyResponse<O, A>,
		{
			companyDomainId: string;
			legalEntityId: string;
			type: HierarchyType;
			used?: boolean | undefined;
		}
	>({
		query: ({ companyDomainId, legalEntityId, type, used }) => ({
			url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/${entityPath}${path}`,
			params: { type, used },
		}),
		providesTags: (_result, error, arg) => {
			if (error) {
				return [`${dimension}Hierarchy`];
			}
			return [
				`${dimension}Hierarchy`,
				{
					type: `${dimension}Hierarchy`,
					id: arg.legalEntityId,
				},
			];
		},
	});

export const buildCreateHierarchyGroupEndpoint = <
	O extends LegalEntityEntity,
	A = Record<string, never>,
>(
	dimension: Dimension,
	entityPath: string,
	builder: Builder,
	path = "/hierarchy",
) =>
	builder.mutation<
		EntityHierarchy<O, A>,
		{
			companyDomainId: string;
			legalEntityId: string;
			group: CreateEntityHierarchyGroup<A>;
			type: HierarchyType;
		}
	>({
		query: ({ companyDomainId, legalEntityId, group, type }) => ({
			url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/${entityPath}${path}`,
			body: group,
			params: { type },
			method: "POST",
		}),
		invalidatesTags: [`${dimension}Hierarchy`],
	});

export const buildUpdateHierarchyGroupEndpoint = <
	O extends LegalEntityEntity,
	A = Record<string, never>,
>(
	dimension: Dimension,
	entityPath: string,
	builder: Builder,
	path = "/hierarchy",
) =>
	builder.mutation<
		EntityHierarchy<O, A>,
		{
			companyDomainId: string;
			legalEntityId: string;
			groupId: string;
			group: UpdateEntityHierarchyGroup<A>;
			type: HierarchyType;
		}
	>({
		query: ({ companyDomainId, legalEntityId, groupId, group, type }) => ({
			url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/${entityPath}${path}/${groupId}`,
			body: group,
			params: { type },
			method: "PUT",
		}),
		invalidatesTags: [`${dimension}Hierarchy`],
	});

export const buildRemoveHierarchyGroupEndpoint = <
	O extends LegalEntityEntity,
	A = Record<string, never>,
>(
	dimension: Dimension,
	entityPath: string,
	builder: Builder,
	path = "/hierarchy",
) =>
	builder.mutation<
		EntityHierarchy<O, A>,
		{
			companyDomainId: string;
			legalEntityId: string;
			groupId: string;
			type: HierarchyType;
		}
	>({
		query: ({ companyDomainId, legalEntityId, groupId, type }) => ({
			url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/${entityPath}${path}/${groupId}`,
			params: { type },
			method: "DELETE",
		}),
		invalidatesTags: [`${dimension}Hierarchy`],
	});
