import type { ReactNode } from "react";

export const AppFrame = ({
	children,
}: {
	children?: ReactNode | undefined;
}) => {
	return (
		<div className="h-screen w-full flex-col overflow-auto sm:flex sm:flex-row">
			{children}
		</div>
	);
};

export const MainFrame = ({
	children,
}: {
	children?: ReactNode | undefined;
}) => {
	return (
		<main className="min-h-screen grow overflow-x-hidden">{children}</main>
	);
};
