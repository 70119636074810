import { IconSortAscending, IconSortDescending } from "@tabler/icons-react";
import type { HTMLAttributes, ReactNode } from "react";
import { PlanningTable2 } from "../../../../features/planning/components/PlanningTable";
import { Text } from "../typography/Text";

interface SortableTableCellProps extends HTMLAttributes<HTMLTableCellElement> {
	children: ReactNode;
	active: boolean;
	order: "asc" | "desc";
}

export const SortableTableCell = ({
	children,
	active,
	order,
	className,
	...props
}: SortableTableCellProps) => {
	return (
		<PlanningTable2.Th
			className={className}
			aria-sort={active ? `${order}ending` : undefined}
			{...props}
		>
			<div className="flex cursor-pointer items-center gap-x-1">
				<Text size="xs" weight="medium" color="text-grey-500" span>
					{children}
				</Text>
				{order === "asc" ? (
					<IconSortAscending size={18} className="text-purple-500" />
				) : (
					<IconSortDescending size={18} className="text-purple-500" />
				)}
			</div>
		</PlanningTable2.Th>
	);
};
