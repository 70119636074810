import type { Transaction } from "../types";
import type { DomainAccount } from "@exopengithub/planning-api-shared";

export const isBalanceAccount = (account: DomainAccount) => {
	return account.type
		? account.type.startsWith("assets") ||
				account.type.startsWith("liabilities")
		: false;
};

export function getTransactionAmountFromAccount(
	{ amount, accountId }: Transaction,
	accountById: Map<string, DomainAccount>,
): number {
	const account = accountById.get(accountId);
	if (!account) {
		return amount;
	}

	return isBalanceAccount(account) ? amount : amount * -1;
}
