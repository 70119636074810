import {
	useFloating,
	useClick,
	useDismiss,
	useRole,
	useInteractions,
} from "@floating-ui/react";
import { useState, useMemo, useCallback, useId } from "react";

export interface UseDialogOptions {
	initialOpen?: boolean;
	open?: boolean | undefined;
	onOpenChange?: ((open: boolean) => void) | undefined;
	onClose?: (() => void) | undefined;
	onOpen?: (() => void) | undefined;
	outsidePress?: boolean;
}

export function useDialog({
	initialOpen = false,
	open: controlledOpen,
	onOpenChange: setControlledOpen,
	onClose,
	onOpen,
	outsidePress = false,
}: UseDialogOptions = {}) {
	const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
	const labelId = useId();
	const descriptionId = useId();

	const open = controlledOpen ?? uncontrolledOpen;
	const setOpen = setControlledOpen ?? setUncontrolledOpen;

	const setOpenCtx = useCallback(
		(open: boolean) => {
			setOpen(open);
			if (open) {
				onOpen?.();
			} else {
				onClose?.();
			}
		},
		[onClose, onOpen, setOpen],
	);

	const data = useFloating({
		open,
		onOpenChange: setOpenCtx,
	});

	const context = data.context;

	const click = useClick(context);
	const dismiss = useDismiss(context, { outsidePress });
	const role = useRole(context);

	const interactions = useInteractions([click, dismiss, role]);

	return useMemo(
		() => ({
			open,
			setOpen: setOpenCtx,
			...interactions,
			...data,
			labelId,
			descriptionId,
		}),
		[open, setOpenCtx, interactions, data, labelId, descriptionId],
	);
}
