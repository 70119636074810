import { forwardRef } from "react";
import {
	Link,
	type LinkProps,
	type NavigateFunction,
	type NavigateOptions,
	type To,
	useLocation,
	useNavigate,
	type NavLinkProps,
	NavLink,
	type NavigateProps,
	Navigate,
} from "react-router-dom";
import { isPersistedQueryParam } from "./context/constants.js";

const mergeWithPersistedParams = (
	init: string,
	paramsToPersist: string[][],
) => {
	const search = new URLSearchParams(init);
	for (const [key, value] of paramsToPersist) {
		if (!search.has(key)) {
			search.set(key, value);
		}
	}
	return search;
};

const getPersistedTo = (to: To, paramsToPersist: string[][]) => {
	let persistedTo: To;
	if (typeof to === "string") {
		persistedTo = to;
		const s = persistedTo.indexOf("?");
		if (s !== -1) {
			const search = mergeWithPersistedParams(
				persistedTo.slice(s),
				paramsToPersist,
			);
			persistedTo = `${persistedTo.slice(0, s)}?${search}`;
		} else if (paramsToPersist.length > 0) {
			persistedTo = `${persistedTo}?${new URLSearchParams(paramsToPersist)}`;
		}
	} else {
		persistedTo = to;
		if (persistedTo.search) {
			const search = mergeWithPersistedParams(
				persistedTo.search,
				paramsToPersist,
			);
			persistedTo.search = `${search}`;
		} else {
			persistedTo.search = `${new URLSearchParams(paramsToPersist)}`;
		}
	}
	return persistedTo;
};

const usePersistedParameters = () => {
	const location = useLocation();
	return Array.from(new URLSearchParams(location.search).entries()).filter(
		([key]) => isPersistedQueryParam(key),
	);
};

export const PersistedLink = forwardRef<HTMLAnchorElement, LinkProps>(
	function PersistedLink({ to, ...props }, ref) {
		const searchParamsToPersist = usePersistedParameters();
		const persistedTo = getPersistedTo(to, searchParamsToPersist);
		return <Link ref={ref} to={persistedTo} {...props} />;
	},
);

export const PersistedNavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
	function PersistedNavLink({ to, ...props }, ref) {
		const searchParamsToPersist = usePersistedParameters();
		const persistedTo = getPersistedTo(to, searchParamsToPersist);
		return <NavLink ref={ref} to={persistedTo} {...props} />;
	},
);

export const PersistedNavigate = ({ to, ...props }: NavigateProps) => {
	const searchParamsToPersist = usePersistedParameters();
	const persistedTo = getPersistedTo(to, searchParamsToPersist);
	return <Navigate to={persistedTo} {...props} />;
};

export const usePersistedNavigate = (): NavigateFunction => {
	const nativeNavigate = useNavigate();
	const searchParamsToPersist = usePersistedParameters();

	const navigate: NavigateFunction = (
		to: To | number,
		options: NavigateOptions = {},
	) => {
		if (typeof to === "number") {
			return nativeNavigate(to);
		}

		const persistedTo = getPersistedTo(to, searchParamsToPersist);

		nativeNavigate(persistedTo, options);
	};

	return navigate;
};

export const useGetPersistedTo = (to: To, paramsToPersist?: string[][]) => {
	return getPersistedTo(to, paramsToPersist ?? []);
};
