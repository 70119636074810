export const auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN;
export const auth0ClientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
export const auth0Audience = import.meta.env.VITE_AUTH0_AUDIENCE;
export const serviceUrl = import.meta.env.VITE_SERVICE_URL;
export const planningApiUrl = import.meta.env.VITE_PLANNING_API_URL;
export const nexusApiUrl = import.meta.env.VITE_NEXUS_API_URL;
export const fusionHubApiUrl = import.meta.env.VITE_FUSION_HUB_API_URL;
export const appInsightsConnectionString = import.meta.env
	.VITE_APPINSIGHTS_CONNECTION_STRING;

export const isStaging = import.meta.env.VITE_STAGING === "true";

export const isProduction =
	auth0Domain === "auth.exopen.app" &&
	!isStaging &&
	import.meta.env.PROD &&
	!import.meta.env.VITE_CI;
