import {
	type DomainAccountingObject,
	type DomainAllocationKey,
	type DomainOrganizationalUnit,
	type DomainDriver,
	buildPlanModuleRoutePath,
} from "@exopengithub/planning-api-shared";
import { emptyPlanningApi } from "./emptyPlanningApi.js";
import type { EntityState } from "@reduxjs/toolkit";
import { conversationApi } from "./conversationApi.js";
import {
	driverModuleEntriesV2Adapter,
	driverModuleEntriesV2Selectors,
} from "../utils/driverModuleEntriesAdapter.js";
import { invariant } from "../../../common/utils/invariant.js";
import type {
	DomainDriverModuleEntryV2,
	DriverModuleEntryCreateManyV2,
	DriverModuleEntryUpsert,
} from "@exopengithub/planning-api-shared";

const driverModuleEntryApi = emptyPlanningApi.injectEndpoints({
	endpoints: (builder) => ({
		updateDriverModuleEntryV2: builder.mutation<
			unknown,
			{
				data: DriverModuleEntryUpsert;
				id: string;
				planId: string;
				legalEntityId: string;
				companyDomainId: string;
				driver: DomainDriver;
				costCenter: DomainOrganizationalUnit | null;
				customer: DomainAccountingObject | null;
				project: DomainAccountingObject | null;
				product: DomainAccountingObject | null;
				supplier: DomainAccountingObject | null;
				allocationKey?: DomainAllocationKey | null;
			}
		>({
			query({ id, planId, legalEntityId, companyDomainId, data }) {
				return {
					method: "PUT",
					url: buildPlanModuleRoutePath({
						refType: "driver",
						endpoint: `/${id}`,
						companyDomainId,
						planId,
						legalEntityId,
						version: "v2",
					}),
					body: {
						...data,
						inputs: data.inputs.map((input) => {
							return {
								...input,
								period: input.period.toString(),
							};
						}),
					},
				};
			},

			async onQueryStarted(
				{
					companyDomainId,
					planId,
					legalEntityId,
					data,
					id,
					driver,
					customer,
					product,
					project,
					supplier,
					costCenter,
				},
				{ dispatch, queryFulfilled },
			) {
				dispatch(
					driverModuleEntryApi.util.updateQueryData(
						"getDriverModuleEntriesV2",
						{
							companyDomainId,
							planId,
							legalEntityId,
						},
						(draft) => {
							const current = driverModuleEntriesV2Selectors.selectById(
								draft,
								id,
							);
							invariant(current);
							driverModuleEntriesV2Adapter.updateOne(draft, {
								id,
								changes: {
									conversationId: current.conversationId,
									quantity: data.inputs.reduce<number>((sum, input) => {
										return sum + input.quantity;
									}, 0),
									driver,
									costCenter,
									product,
									project,
									supplier,
									customer,
									inputs: data.inputs.map((input) => {
										return {
											quantity: input.quantity,
											period: input.period.toString(),
										};
									}),
								},
							});
						},
					),
				);

				try {
					await queryFulfilled;
				} catch {
					dispatch(
						driverModuleEntryApi.util.invalidateTags([
							{ type: "DriverModuleEntry", id },
							"AllocationKey",
							"Activity",
						]),
					);
				}
			},
			invalidatesTags: () => {
				return ["AllocationKey", "Activity"];
			},
		}),

		createDriverModuleEntryV2: builder.mutation<
			unknown,
			{
				planId: string;
				legalEntityId: string;
				companyDomainId: string;
				moduleEntries: DriverModuleEntryCreateManyV2;
			}
		>({
			query: ({ legalEntityId, moduleEntries, companyDomainId, planId }) => {
				return {
					method: "POST",
					url: buildPlanModuleRoutePath({
						refType: "driver",
						companyDomainId,
						planId,
						legalEntityId,
						endpoint: "",
						version: "v2",
					}),
					body: moduleEntries,
				};
			},
			invalidatesTags: () => {
				return [
					"DriverModuleEntry",
					"ModuleCount",
					"AllocationKey",
					"Activity",
				];
			},
		}),

		deleteDriverModuleEntry: builder.mutation<
			unknown,
			{
				ids: string[];
				legalEntityId: string;
				companyDomainId: string;
				planId: string;
			}
		>({
			query: ({ ids, legalEntityId, companyDomainId, planId }) => {
				return {
					method: "POST",
					url: buildPlanModuleRoutePath({
						refType: "driver",
						endpoint: "/delete-many",
						companyDomainId,
						planId,
						legalEntityId,
					}),
					body: { ids },
				};
			},
			async onQueryStarted(
				{ companyDomainId, planId, legalEntityId, ids },
				{ dispatch, queryFulfilled },
			) {
				const setOfIds = new Set(ids);

				dispatch(
					driverModuleEntryApi.util.updateQueryData(
						"getDriverModuleEntriesV2",
						{
							companyDomainId,
							planId,
							legalEntityId,
						},
						(draft) => {
							driverModuleEntriesV2Adapter.removeMany(draft, ids);
						},
					),
				);

				dispatch(
					conversationApi.util.updateQueryData(
						"getConversations",
						{ legalEntityId, planId, companyDomainId },
						(draft) => {
							for (let i = draft.length - 1; i >= 0; --i) {
								const { associatedEntry } = draft[i];
								const isConnected =
									associatedEntry &&
									associatedEntry.type === "driver" &&
									setOfIds.has(associatedEntry.id);
								if (isConnected) {
									draft.splice(i, 1);
								}
							}
						},
					),
				);

				try {
					await queryFulfilled;
				} catch {
					dispatch(
						driverModuleEntryApi.util.invalidateTags([
							...ids.map((id) => {
								return {
									type: "DriverModuleEntry" as const,
									id,
								};
							}),
							"ModuleCount",
							"Activity",

							// deleting an object transaction also deletes the connected conversation
							"Conversation",
						]),
					);
				}
			},
			invalidatesTags() {
				return ["AllocationKey", "ModuleCount", "DriverModuleEntry"];
			},
		}),

		getDriverModuleEntriesV2: builder.query<
			EntityState<DomainDriverModuleEntryV2, string>,
			{
				companyDomainId: string;
				planId: string;
				legalEntityId: string;
			}
		>({
			query({ planId, companyDomainId, legalEntityId }) {
				return {
					method: "GET",
					url: buildPlanModuleRoutePath({
						endpoint: "",
						refType: "driver",
						companyDomainId,
						planId,
						legalEntityId,
						version: "v2",
					}),
				};
			},
			transformResponse: (data: DomainDriverModuleEntryV2[]) => {
				return driverModuleEntriesV2Adapter.addMany(
					driverModuleEntriesV2Adapter.getInitialState(),
					data,
				);
			},
			providesTags(result) {
				return result
					? [
							...result.ids.map((id) => ({
								type: "DriverModuleEntry" as const,
								id: `${id}`,
							})),
							"DriverModuleEntry",
						]
					: ["DriverModuleEntry"];
			},
		}),
	}),
	overrideExisting: false,
});

export const {
	useCreateDriverModuleEntryV2Mutation,
	useDeleteDriverModuleEntryMutation,
	useGetDriverModuleEntriesV2Query,
	useUpdateDriverModuleEntryV2Mutation,
} = driverModuleEntryApi;
