import classes from "./EmptyState.module.css";
import type { CSSProperties, ReactNode } from "react";
import { Text } from "../../../common/components/atoms/typography/Text";
import { clsx } from "clsx";

interface EmptyStateProps {
	createButton?: ReactNode | undefined;
	header: string;
	subHeader?: string | undefined;
	className?: string | undefined;
	style?: CSSProperties | undefined;
}

export const EmptyState = ({
	createButton,
	header,
	subHeader,
	className,
	style,
}: EmptyStateProps) => {
	const withSubHeader = subHeader !== undefined;
	const withCreateButton = createButton;
	return (
		<div className={clsx(classes.bodyWrapper, className)} style={style}>
			<Text
				size="md"
				weight="medium"
				className={clsx(
					"text-center",
					withSubHeader || withCreateButton ? "mb-2" : undefined,
				)}
			>
				{header}
			</Text>
			<Text
				size="sm"
				weight="regular"
				color="text-grey-500"
				className={clsx(
					classes.bodySubtitle,
					"max-w-prose",
					createButton ? "mb-6" : undefined,
				)}
			>
				{subHeader}
			</Text>
			{createButton && <div className="space-x-4">{createButton}</div>}
		</div>
	);
};
