import { useCallback, useMemo } from "react";
import { useCurrentCompanyDomain } from "../company-domain/useCurrentCompanyDomain.js";
import { useGetCompanyHierarchyQuery } from "../service/nexus/nexusApi.js";
import { useCurrentLegalEntity } from "./useCurrentLegalEntity.js";
import { useLegalEntitiesOrThrow } from "./useLegalEntities.js";
import { getLegalEntityInHierarchy } from "./utils/getLegalEntityInHierarchy.js";

export const useLegalEntityHierarchy = () => {
	const { companyDomain } = useCurrentCompanyDomain();

	const { legalEntities } = useLegalEntitiesOrThrow();

	const { legalEntity: currentLegalEntity } = useCurrentLegalEntity();

	const {
		data: hierarchy,
		isLoading,
		isFetching,
	} = useGetCompanyHierarchyQuery(companyDomain.id);

	const isLegalEntityPartOfCorporateGroup = useCallback(
		(legalEntityId: string) => {
			if (!hierarchy?.hierarchy) {
				return false;
			}

			const matchingLegalEntity = legalEntities.find(
				(legalEntity) => legalEntity.id === legalEntityId,
			);

			if (!matchingLegalEntity) {
				return false;
			}

			const legalEntityPart = getLegalEntityInHierarchy(
				matchingLegalEntity,
				hierarchy.hierarchy,
			);

			return Boolean(legalEntityPart);
		},
		[hierarchy?.hierarchy, legalEntities],
	);

	const isCurrentLegalEntityPartOfCorporateGroup = useCallback(() => {
		if (!currentLegalEntity) {
			return false;
		}

		return isLegalEntityPartOfCorporateGroup(currentLegalEntity.id);
	}, [currentLegalEntity, isLegalEntityPartOfCorporateGroup]);

	const hasAnyCorporateGroup = useCallback(() => {
		if (!hierarchy?.hierarchy) {
			return false;
		}

		return true;
	}, [hierarchy?.hierarchy]);

	return useMemo(
		() => ({
			hierarchy,
			isLegalEntityPartOfCorporateGroup,
			isCurrentLegalEntityPartOfCorporateGroup,
			hasAnyCorporateGroup,
			isFetching,
			isLoading,
		}),
		[
			hierarchy,
			isLegalEntityPartOfCorporateGroup,
			isCurrentLegalEntityPartOfCorporateGroup,
			hasAnyCorporateGroup,
			isFetching,
			isLoading,
		],
	);
};
