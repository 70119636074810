import type { Supplier } from "src/common/service/nexus/endpoints/supplier/types.js";
import type { Builder, PaginatedResponse } from "../../types.js";
import { buildHierarchyEndpoints } from "./hierarchyEndpoints.js";

export const buildSupplierEndpoints = (builder: Builder) => ({
	...buildHierarchyEndpoints(builder),
	getSuppliers: builder.query<
		PaginatedResponse<Supplier>,
		{ companyDomainId: string; legalEntityId: string; take: number }
	>({
		query: ({ companyDomainId, legalEntityId, take }) => {
			return {
				url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/supplier`,
				params: {
					take,
				},
			};
		},
		providesTags: (result) => {
			if (!result) {
				return ["Supplier"];
			}
			return [
				"Supplier",
				...result.items.map((invoice) => {
					return {
						type: "Supplier" as const,
						id: invoice.id,
					};
				}),
			];
		},
	}),
});
