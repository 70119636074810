import { useTranslation } from "react-i18next";
import { Input } from "../../../common/components/atoms/input/Input.js";
import { Label } from "../../../common/components/atoms/label/Label.js";
import type { AccountHierarchyGroupAttributes } from "src/common/service/nexus/endpoints/account/types.js";
import { Select } from "../../../common/components/atoms/select/Select.js";
import { useId } from "react";

type Props = {
	attributes: AccountHierarchyGroupAttributes;
	disableGroupType: boolean;
	onChange: (attributes: AccountHierarchyGroupAttributes) => void;
	attributeErrors: Partial<
		Record<keyof AccountHierarchyGroupAttributes, string>
	> | null;
};

export const AccountHierarchyGroupAttributesEditor = ({
	attributes,
	disableGroupType,
	onChange,
	attributeErrors,
}: Props) => {
	const { t } = useTranslation();
	const typeLabelId = useId();

	return (
		<>
			{!disableGroupType && (
				<>
					<Label id={typeLabelId}>{t("Type")}</Label>
					<Select
						aria-labelledby={typeLabelId}
						options={[
							{
								label: t("Group"),
								value: "Group",
							},
							{
								label: t("Sum"),
								value: "Sum",
							},
						]}
						value={attributes.type}
						onChange={(value) => {
							if (value) {
								onChange({
									...attributes,
									type: value as AccountHierarchyGroupAttributes["type"],
								});
							}
						}}
						placeholder={t("Select type")}
					/>
				</>
			)}
			<Label className="mt-4">{t("Visualization Code")}</Label>
			<Input
				value={attributes.visualizationCode ?? ""}
				aria-invalid={!!attributeErrors?.visualizationCode}
				hint={attributeErrors?.visualizationCode}
				onChange={(event) => {
					const newValue = event.target.value;
					onChange({
						...attributes,
						visualizationCode: newValue === "" ? null : newValue,
					});
				}}
			/>
		</>
	);
};
