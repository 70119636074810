import { Button } from "../../../../common/components/atoms/button/Button.tsx";
import { Dialog } from "../../../../common/components/atoms/dialog/Dialog.tsx";
import { DialogActions } from "../../../../common/components/atoms/dialog/DialogActions.tsx";
import { DialogBody } from "../../../../common/components/atoms/dialog/DialogBody.tsx";
import { DialogContent } from "../../../../common/components/atoms/dialog/DialogContent.tsx";
import { DialogTitle } from "../../../../common/components/atoms/dialog/DialogTitle.tsx";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useEffect, useId } from "react";
import type {
	DomainUser,
	AddUserToPlanBody,
} from "@exopengithub/planning-api-shared";
import type { CostCenterPermissionsFormValues } from "./CostCenterPermissions.tsx";
import { EditPlanUserForm } from "./EditPlanUserForm.tsx";

export interface EditPlanUserFormValues
	extends CostCenterPermissionsFormValues {
	permissions: AddUserToPlanBody["permissions"];
}

const makeDefaultValues = (user: DomainUser | null): EditPlanUserFormValues => {
	return {
		permissions: user?.permissions ?? [],
		allCostCenters: true,
		selectedCostCenters: [],
	};
};

export const EditPlanUserDialog = ({
	permissions,
	user,
	onClose,
}: {
	user: DomainUser | null;
	onClose: () => void;
	permissions: {
		label: string;
		value: AddUserToPlanBody["permissions"][number];
	}[];
}) => {
	const { t } = useTranslation();
	const form = useForm({
		defaultValues: makeDefaultValues(user),
	});
	const formId = useId();

	const { reset } = form;
	useEffect(() => {
		if (form.formState.isSubmitSuccessful) {
			reset();
		}
	}, [form.formState.isSubmitSuccessful, reset]);

	useEffect(() => {
		if (user) {
			reset(makeDefaultValues(user));
		}
	}, [reset, user]);

	return (
		<Dialog
			open={user !== null}
			onClose={() => {
				reset();
				onClose();
			}}
		>
			<DialogContent>
				{({ close }) => {
					return (
						<>
							<DialogTitle>{t("Edit user")}</DialogTitle>
							<DialogBody>
								<EditPlanUserForm
									user={user}
									onSuccessfulSubmit={close}
									permissions={permissions}
									formId={formId}
									form={form}
								/>
							</DialogBody>
							<DialogActions withCancelButton>
								<Button
									variant="primary"
									type="submit"
									form={formId}
									isLoading={form.formState.isSubmitting}
									disabled={!form.formState.isDirty}
								>
									{t("Save changes")}
								</Button>
							</DialogActions>
						</>
					);
				}}
			</DialogContent>
		</Dialog>
	);
};
