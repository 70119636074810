import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../common/components/atoms/button/Button";
import { showErrorNotification } from "../../../common/components/atoms/notifications/events";
import type { LegalEntity } from "src/common/service/nexus/endpoints/companyDomain/types.js";
import { Dialog } from "components/dialog/Dialog.tsx";
import { DialogContent } from "components/dialog/DialogContent.tsx";
import { DialogTitle } from "components/dialog/DialogTitle.tsx";
import { DialogBody } from "components/dialog/DialogBody.tsx";
import { DialogActions } from "components/dialog/DialogActions.tsx";

interface Props {
	isOpen: boolean;
	legalEntity?: LegalEntity | undefined;
	onConfirm: () => Promise<void>;
	onCancel: () => void;
}

export const RemoveCompanyFromHierarchyModal = ({
	isOpen,
	legalEntity,
	onCancel,
	onConfirm,
}: Props) => {
	const { t } = useTranslation();

	const [isRemoving, setIsRemoving] = useState(false);

	return (
		<Dialog onClose={onCancel} open={isOpen}>
			<DialogContent>
				<DialogTitle>{t("Delete legal entity")}</DialogTitle>
				<DialogBody>
					<div className="text-sm">{`${t("Do you want to delete")} ${
						legalEntity?.name ?? t("Unknown")
					}`}</div>
				</DialogBody>
				<DialogActions withCancelButton>
					<Button
						className="ml-2"
						destructive={true}
						isLoading={isRemoving}
						onClick={async () => {
							setIsRemoving(true);
							try {
								await onConfirm();
							} catch (e) {
								showErrorNotification({
									message: t("Error"),
								});
							} finally {
								setIsRemoving(false);
							}
						}}
					>
						{t("Remove")}
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};
