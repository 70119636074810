import { useTranslation } from "react-i18next";
import { Button } from "../../../common/components/atoms/button/Button";
import { FeaturedIcon } from "../../../common/components/atoms/featuredIcon/FeaturedIcon";
import { IconSearch } from "@tabler/icons-react";

interface FilterNoResultsProps {
	onClearFilter: () => void;
}

export const FilterNoResults = ({ onClearFilter }: FilterNoResultsProps) => {
	const { t } = useTranslation();

	return (
		<div className="bg-white py-10 text-center">
			<FeaturedIcon
				color="primary"
				size="lg"
				theme="light_circle"
				className="mb-4"
			>
				<IconSearch />
			</FeaturedIcon>

			<div className="text-base leading-6 font-medium mb-1 text-gray-900">
				{t("No matches found")}
			</div>
			<div className="text-sm leading-5 text-gray-500 mb-6">
				{t("Your search did not match any result.")}
			</div>
			<Button
				onClick={() => {
					onClearFilter();
				}}
				variant="secondaryGray"
			>
				{t("Clear filters")}
			</Button>
		</div>
	);
};
