import { createContext, type ReactNode, useMemo } from "react";
import type { Permission } from "../../features/usersAndGroups/AddAccessGroupModal";
import { useGetCurrentUserQuery } from "../redux/api/exopenApi";
import type { User } from "../service/Types/UserTypes";
import { COMPANYADMIN } from "../service/users";
import { isCompanyAdmin } from "./isCompanyAdmin.js";

export type UserContextType =
	| {
			user: User | undefined;
			isFetchingUserInfo: boolean;
			userHasRequiredRole: (
				companyId: string,
				requiredRoles: string[],
			) => boolean;
			userIsSuperAdmin: () => boolean;
			userIsCompanyAdmin: (companyId: string) => boolean;
			userHasRequiredPermissions: (
				requiredPermissions: Permission[],
			) => boolean;
	  }
	| undefined;

export const UserContext = createContext<UserContextType>(undefined);

interface Props {
	children?: ReactNode | undefined;
}

// Provider hook that creates user object and handles state
function useProvideUser() {
	const { data: user, isLoading } = useGetCurrentUserQuery();

	const userPermissions = useMemo(() => {
		return user?.accessGroupsObjects.reduce(
			(permissions, accessGroupObject) => {
				permissions.add(accessGroupObject.objectId as unknown as Permission);
				return permissions;
			},
			new Set<Permission>(),
		);
	}, [user?.accessGroupsObjects]);

	return useMemo(() => {
		const userIsCompanyAdmin = (companyId: string) => {
			return isCompanyAdmin(user, Number(companyId));
		};
		const userIsSuperAdmin = () => {
			return user ? user.superAdmin : false;
		};
		const userHasRequiredRole = (
			companyId: string,
			requiredRoles: string[],
		) => {
			if (userIsSuperAdmin()) {
				return true;
			}

			if (
				requiredRoles.includes(COMPANYADMIN[0]) ||
				requiredRoles.includes(COMPANYADMIN[1])
			) {
				return userIsCompanyAdmin(companyId);
			}

			if (user?.accessGroups) {
				return user.accessGroups.some((role) => {
					return (
						role.companyId === Number(companyId) &&
						requiredRoles.includes(role.name)
					);
				});
			}

			return false;
		};

		const userHasRequiredPermissions = (requiredPermissions: Permission[]) => {
			return requiredPermissions.every((permission) => {
				return userPermissions?.has(permission);
			});
		};

		return {
			user,
			isFetchingUserInfo: isLoading,
			userHasRequiredRole,
			userHasRequiredPermissions,
			userIsSuperAdmin,
			userIsCompanyAdmin,
		};
	}, [user, isLoading, userPermissions]);
}

export function ProvideUser({ children }: Props) {
	const user = useProvideUser();
	return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
