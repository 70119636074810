import { type ReactNode, useId, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/components/atoms/button/Button";
import { useCreateCompanyMutation } from "../../common/redux/api/exopenApi";
import { Input } from "../../common/components/atoms/input/Input";
import { Label } from "../../common/components/atoms/label/Label";
import { showErrorNotification } from "../../common/components/atoms/notifications/events";
import { useLegalEntityQueryParameters } from "../../common/context/legacyLegalEntityContextUtils";
import { Select } from "../../common/components/atoms/select/Select.tsx";
import { DialogContent } from "../../common/components/atoms/dialog/DialogContent.tsx";
import { DialogActions } from "../../common/components/atoms/dialog/DialogActions.tsx";
import { Dialog } from "../../common/components/atoms/dialog/Dialog.tsx";
import { DialogBody } from "../../common/components/atoms/dialog/DialogBody.tsx";
import { DialogTitle } from "../../common/components/atoms/dialog/DialogTitle.tsx";
import { DialogTrigger } from "../../common/components/atoms/dialog/DialogTrigger.tsx";
import { companyTypes } from "./companyTypes.ts";

export const AddCompany = ({
	children,
	onClose,
	open,
}: {
	children?: ReactNode;
	open?: boolean;
	onClose?: () => void;
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [companyName, setCompanyName] = useState<string>("");
	const [companyType, setCompanyType] = useState<string>("");

	const search = useLegalEntityQueryParameters();

	const [createCompany, { isLoading: isCreatingCompany }] =
		useCreateCompanyMutation();

	const createNewCompany = async () => {
		const result = await createCompany({
			name: companyName,
			type: companyType,
		});

		if ("error" in result) {
			showErrorNotification({
				message: `${t("The company")} ${companyName} ${t("was not saved")}`,
			});
		} else {
			navigate({
				pathname: `/${result.data.id}`,
				...(search ? { search } : {}),
			});
		}
	};

	const typeLabelId = useId();

	return (
		<Dialog open={open} onClose={onClose}>
			{children && <DialogTrigger asChild>{children}</DialogTrigger>}
			<DialogContent>
				<DialogTitle>{t("New Company Domain")}</DialogTitle>
				<DialogBody>
					<Label id={typeLabelId}>{t("Type")}</Label>
					<Select
						aria-labelledby={typeLabelId}
						placeholder={t("Select type")}
						options={companyTypes}
						value={companyType}
						onChange={(value) => {
							if (value !== null) {
								setCompanyType(value);
							}
						}}
						className="mb-4"
					/>
					<Label htmlFor="create-company-name">Namn</Label>
					<Input
						id="create-company-name"
						onChange={(event) => {
							setCompanyName(event.target.value);
						}}
						value={companyName}
					/>
				</DialogBody>
				<DialogActions withCancelButton>
					<Button
						disabled={companyName === "" || companyType === ""}
						color="primary"
						onClick={createNewCompany}
						isLoading={isCreatingCompany}
					>
						{t("Create Company Domain")}
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};
