import { IconX, type IconProps } from "@tabler/icons-react";
import { clsx } from "clsx";
import {
	type HTMLAttributes,
	forwardRef,
	type MouseEventHandler,
	type ReactElement,
	cloneElement,
} from "react";
import { Text, type TextProps } from "../typography/Text";

type ChipColor =
	| "purple"
	| "error"
	| "grey"
	| "warning"
	| "blue-light"
	| "primary";

type ChipSize = "small" | "medium" | "large";

const chipPropValues: Record<
	ChipColor,
	{ color: string; bgColor: string; closeColor: string }
> = {
	primary: {
		color: "text-primary-700",
		bgColor: "bg-primary-50",
		closeColor: "text-primary-500",
	},
	"blue-light": {
		color: "text-blue-light-700",
		bgColor: "bg-blue-light-50",
		closeColor: "text-blue-light-500",
	},
	error: {
		color: "text-error-700",
		bgColor: "bg-error-50",
		closeColor: "text-error-500",
	},
	grey: {
		color: "text-gray-700",
		bgColor: "bg-gray-200",
		closeColor: "text-gray-500",
	},
	purple: {
		color: "text-purple-700",
		bgColor: "bg-purple-50",
		closeColor: "text-purple-500",
	},
	warning: {
		color: "text-warning-700",
		bgColor: "bg-warning-50",
		closeColor: "text-primary-500",
	},
};

const chipSizeValues: Record<
	ChipSize,
	{
		padding: string;
		paddingCloseBtn: string;
		textSize: TextProps["size"];
		iconSize: number;
	}
> = {
	small: {
		padding: "py-0.5 px-2",
		paddingCloseBtn: "py-0.5 pl-2 pr-0.5",
		textSize: "xs",
		iconSize: 12,
	},
	medium: {
		padding: "py-1 px-3",
		paddingCloseBtn: "py-1 pl-3 pr-1",
		textSize: "sm",
		iconSize: 16,
	},
	large: {
		padding: "py-2 px-4",
		paddingCloseBtn: "py-2 pl-4 pr-2",
		textSize: "md",
		iconSize: 20,
	},
};

export interface ChipProps extends HTMLAttributes<HTMLDivElement> {
	color?: ChipColor | undefined;
	size?: ChipSize;
	closeButton?: boolean;
	onClose?: MouseEventHandler<HTMLButtonElement>;
	leftIcon?: ReactElement<IconProps>;
}

export const Chip = forwardRef<HTMLDivElement, ChipProps>(function Chip(
	{
		className,
		color = "grey",
		leftIcon,
		size = "medium",
		children,
		closeButton,
		onClose,
		...props
	},
	ref,
) {
	return (
		<div
			ref={ref}
			className={clsx(
				"inline-flex items-center whitespace-nowrap rounded-full",
				closeButton
					? chipSizeValues[size].paddingCloseBtn
					: chipSizeValues[size].padding,
				chipPropValues[color].bgColor,
				className,
			)}
			{...props}
		>
			{leftIcon && (
				<div className="mr-1">{cloneElement(leftIcon, { size: "12" })}</div>
			)}
			<Text
				size={chipSizeValues[size].textSize}
				weight="medium"
				color={chipPropValues[color].color}
			>
				{children}
			</Text>
			{closeButton && (
				<button
					onClick={onClose}
					className={clsx(
						"ml-1 inline-flex cursor-pointer items-center rounded-full p-0.5 hover:bg-black/20 disabled:cursor-not-allowed",
						chipPropValues[color].closeColor,
					)}
					type="button"
				>
					<IconX size={chipSizeValues[size].iconSize} />
				</button>
			)}
		</div>
	);
});
