import type { Builder } from "../../types.js";
import {
	buildGetHierarchyEndpoint,
	type EntityHierarchy,
	type HierarchyType,
	type EntityHierachyResponse,
	buildCreateHierarchyGroupEndpoint,
	buildUpdateHierarchyGroupEndpoint,
	buildRemoveHierarchyGroupEndpoint,
} from "../../utils/hierarchy.js";
import type { Account, AccountHierarchyGroupAttributes } from "./types.js";

export const buildHierarchyEndpoints = (builder: Builder) => ({
	getIncomeStatementHierarchy: buildGetHierarchyEndpoint<
		Account,
		AccountHierarchyGroupAttributes
	>("Account", "account", builder, "/income-statement"),
	getAutoIncomeStatementHierarchy: buildGetHierarchyEndpoint<
		Account,
		AccountHierarchyGroupAttributes
	>("Account", "account", builder, "/auto-income-statement"),
	getIncomeStatementArrangementHierarchy: builder.query<
		EntityHierarchy<Account, AccountHierarchyGroupAttributes>[],
		{ companyDomainId: string; legalEntityId: string; type: HierarchyType }
	>({
		query({ companyDomainId, legalEntityId, type }) {
			return {
				url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/account/arrangement/income-statement`,
				params: { type },
			};
		},
		providesTags: (_result, error, arg) => {
			if (error) {
				return ["AccountArrangement"];
			}
			return [
				"AccountArrangement",
				{
					type: "AccountArrangement",
					id: arg.legalEntityId,
				},
			];
		},
	}),
	migrateAutoIncomeStatementHierarchy: builder.mutation<
		EntityHierachyResponse<Account, AccountHierarchyGroupAttributes>,
		{ companyDomainId: string; legalEntityId: string; type: HierarchyType }
	>({
		query: ({ companyDomainId, legalEntityId, type }) => ({
			url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/account/auto-income-statement`,
			method: "POST",
			params: { type },
		}),
		invalidatesTags: ["AccountHierarchy"],
	}),
	createIncomeStatementHierarchyGroup: buildCreateHierarchyGroupEndpoint<
		Account,
		AccountHierarchyGroupAttributes
	>("Account", "account", builder, "/income-statement"),
	updateIncomeStatementHierarchyGroup: buildUpdateHierarchyGroupEndpoint<
		Account,
		AccountHierarchyGroupAttributes
	>("Account", "account", builder, "/income-statement"),
	removeIncomeStatementHierarchyGroup: buildRemoveHierarchyGroupEndpoint<
		Account,
		AccountHierarchyGroupAttributes
	>("Account", "account", builder, "/income-statement"),

	getBalanceSheetHierarchy: buildGetHierarchyEndpoint<
		Account,
		AccountHierarchyGroupAttributes
	>("Account", "account", builder, "/balance-sheet"),
	getAutoBalanceSheetHierarchy: buildGetHierarchyEndpoint<
		Account,
		AccountHierarchyGroupAttributes
	>("Account", "account", builder, "/auto-balance-sheet"),
	getBalanceSheetArrangementHierarchy: builder.query<
		EntityHierarchy<Account, AccountHierarchyGroupAttributes>[],
		{ companyDomainId: string; legalEntityId: string; type: HierarchyType }
	>({
		query({ companyDomainId, legalEntityId, type }) {
			return {
				url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/account/arrangement/balance-sheet`,
				params: { type },
			};
		},
		providesTags: (_result, error, arg) => {
			if (error) {
				return ["AccountArrangement"];
			}
			return [
				"AccountArrangement",
				{
					type: "AccountArrangement",
					id: arg.legalEntityId,
				},
			];
		},
	}),
	migrateAutoBalanceSheetHierarchy: builder.mutation<
		EntityHierachyResponse<Account, AccountHierarchyGroupAttributes>,
		{ companyDomainId: string; legalEntityId: string; type: HierarchyType }
	>({
		query: ({ companyDomainId, legalEntityId, type }) => ({
			url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/account/auto-balance-sheet`,
			method: "POST",
			params: { type },
		}),
		invalidatesTags: ["AccountHierarchy"],
	}),
	createBalanceSheetHierarchyGroup: buildCreateHierarchyGroupEndpoint<
		Account,
		AccountHierarchyGroupAttributes
	>("Account", "account", builder, "/balance-sheet"),
	updateBalanceSheetHierarchyGroup: buildUpdateHierarchyGroupEndpoint<
		Account,
		AccountHierarchyGroupAttributes
	>("Account", "account", builder, "/balance-sheet"),
	removeBalanceSheetHierarchyGroup: buildRemoveHierarchyGroupEndpoint<
		Account,
		AccountHierarchyGroupAttributes
	>("Account", "account", builder, "/balance-sheet"),
});
