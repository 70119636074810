import {
	useMergeRefs,
	FloatingPortal,
	FloatingOverlay,
	FloatingFocusManager,
	useTransitionStyles,
} from "@floating-ui/react";
import {
	type ComponentPropsWithoutRef,
	type MutableRefObject,
	type ReactNode,
	forwardRef,
	useRef,
} from "react";
import { clsx } from "clsx";
import { useDialogContext } from "./useDialogContext.js";
import { zIndicies } from "src/common/utils/zIndicies.js";
import { maybe } from "../../../utils/maybe.js";

type ModalSize = "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl";

const sizes: Record<ModalSize, number> = {
	xs: 320,
	sm: 380,
	md: 440,
	lg: 620,
	xl: 780,
	"2xl": 960,
	"3xl": 1180,
};

interface DialogContentProps
	extends Omit<ComponentPropsWithoutRef<"div">, "children"> {
	maxHeightClassName?: string;
	size?: ModalSize;
	children:
		| ReactNode
		| ((options: {
				initialElementRef: MutableRefObject<HTMLElement | null>;
				close: () => void;
		  }) => ReactNode);
}

export const DialogContent = forwardRef<HTMLDivElement, DialogContentProps>(
	function DialogContent(
		{
			children,
			maxHeightClassName = "sm:max-h-[calc(100vh_-_32px)]",
			className,
			style,
			size = "md",
			...rest
		},
		propRef,
	) {
		const {
			context: floatingContext,
			setOpen,
			refs,
			labelId,
			descriptionId,
			getFloatingProps,
		} = useDialogContext();
		const ref = useMergeRefs([refs.setFloating, propRef]);

		const { isMounted, styles: floatingTransitionStyles } = useTransitionStyles(
			floatingContext,
			{
				duration: 200,
				initial: { opacity: 0, transform: "scale(.9) translateY(10px)" },
				open: { opacity: 1, transform: "scale(1)" },
				close: { opacity: 0, transform: "scale(.9) translateY(10px)" },
			},
		);
		const { styles: overlayTransitionStyles } = useTransitionStyles(
			floatingContext,
			{
				duration: 200,
				initial: { opacity: 0 },
				open: { opacity: 1 },
				close: { opacity: 0 },
			},
		);

		const initialElementRef = useRef<HTMLElement | null>(null);

		if (!isMounted) {
			return null;
		}

		return (
			<FloatingPortal>
				<FloatingOverlay
					className={clsx(
						"flex items-center justify-center bg-black/70 px-2 sm:px-4",
						zIndicies.DIALOG,
					)}
					style={overlayTransitionStyles}
					lockScroll
				>
					<FloatingFocusManager
						context={floatingContext}
						{...maybe(typeof children === "function", {
							initialFocus: initialElementRef,
						})}
					>
						<div
							ref={ref}
							aria-labelledby={labelId}
							aria-describedby={descriptionId}
							{...getFloatingProps({
								...rest,
								style: {
									...style,
									...floatingTransitionStyles,
									width: sizes[size],
								},
								className: clsx(
									"rounded-md bg-white m-auto flex flex-col max-h-[calc(100vh_-_16px)] focus:outline-none",
									maxHeightClassName,
									className,
								),
							})}
						>
							{typeof children === "function"
								? children({
										initialElementRef,
										close: () => {
											setOpen(false);
										},
									})
								: children}
						</div>
					</FloatingFocusManager>
				</FloatingOverlay>
			</FloatingPortal>
		);
	},
);
