import {
	type DomainAccount,
	type DomainAccountingObject,
	type DomainOrganizationalUnit,
	buildPlanModuleRoutePath,
	type DomainAccountModuleEntryV2,
	type AccountModuleEntryUpsertPayload,
	type AccountModuleEntryCreateManyV2Payload,
} from "@exopengithub/planning-api-shared";
import { emptyPlanningApi } from "./emptyPlanningApi.js";
import type { EntityState } from "@reduxjs/toolkit";
import { conversationApi } from "./conversationApi.js";
import { accountModuleEntriesV2Adapter } from "../utils/accountModuleEntriesAdapter.js";
import type { PaginatedResponse } from "node_modules/@exopengithub/planning-api-shared/build/src/features/nexus/schemas.js";

const accountModuleEntryApi = emptyPlanningApi.injectEndpoints({
	endpoints: (builder) => ({
		updateAccountModuleEntryV2: builder.mutation<
			unknown,
			{
				data: AccountModuleEntryUpsertPayload;
				id: string;
				planId: string;
				companyDomainId: string;
				legalEntityId: string;
				account: DomainAccount;
				costCenter: DomainOrganizationalUnit | null;
				customer: DomainAccountingObject | null;
				project: DomainAccountingObject | null;
				product: DomainAccountingObject | null;
				supplier: DomainAccountingObject | null;
			}
		>({
			query({ id, planId, legalEntityId, data, companyDomainId }) {
				return {
					method: "PUT",
					url: buildPlanModuleRoutePath({
						refType: "account",
						endpoint: `/${id}`,
						companyDomainId,
						planId,
						legalEntityId,
						version: "v2",
					}),
					body: data,
				};
			},
			async onQueryStarted(
				{
					companyDomainId,
					planId,
					legalEntityId,
					data,
					id,
					account,
					costCenter,
					customer,
					product,
					project,
					supplier,
				},
				{ dispatch, queryFulfilled },
			) {
				dispatch(
					accountModuleEntryApi.util.updateQueryData(
						"getAccountModuleEntriesV2",
						{
							companyDomainId,
							planId,
							legalEntityId,
						},
						(draft) => {
							accountModuleEntriesV2Adapter.updateOne(draft, {
								id,
								changes: {
									...data,
									account,
									costCenter,
									customer,
									product,
									project,
									supplier,
								},
							});
						},
					),
				);

				try {
					await queryFulfilled;
				} catch {
					dispatch(
						accountModuleEntryApi.util.invalidateTags([
							{ type: "AccountModuleEntry", id },
							"Activity",
						]),
					);
				}
			},
			invalidatesTags: () => {
				return ["Activity", "UnsyncedAccount"];
			},
		}),

		createAccountModuleEntryV2: builder.mutation<
			unknown,
			{
				planId: string;
				legalEntityId: string;
				companyDomainId: string;
				moduleEntries: AccountModuleEntryCreateManyV2Payload;
			}
		>({
			query: ({ legalEntityId, moduleEntries, companyDomainId, planId }) => {
				return {
					method: "POST",
					url: buildPlanModuleRoutePath({
						refType: "account",
						companyDomainId,
						planId,
						legalEntityId,
						endpoint: "",
						version: "v2",
					}),
					body: moduleEntries,
				};
			},
			invalidatesTags: () => {
				return [
					"AccountModuleEntry",
					"ModuleCount",
					"Activity",
					"PlanningAccountNumber",
					"UnsyncedAccount",
				];
			},
		}),

		deleteAccountModuleEntry: builder.mutation<
			unknown,
			{
				ids: string[];
				legalEntityId: string;
				companyDomainId: string;
				planId: string;
			}
		>({
			query: ({ ids, legalEntityId, companyDomainId, planId }) => {
				return {
					method: "POST",
					url: buildPlanModuleRoutePath({
						refType: "account",
						endpoint: "/delete-many",
						companyDomainId,
						planId,
						legalEntityId,
					}),
					body: { ids },
				};
			},
			async onQueryStarted(
				{ companyDomainId, planId, legalEntityId, ids },
				{ dispatch, queryFulfilled },
			) {
				const setOfIds = new Set(ids);

				dispatch(
					accountModuleEntryApi.util.updateQueryData(
						"getAccountModuleEntriesV2",
						{
							companyDomainId,
							planId,
							legalEntityId,
						},
						(draft) => {
							accountModuleEntriesV2Adapter.removeMany(draft, ids);
						},
					),
				);

				dispatch(
					conversationApi.util.updateQueryData(
						"getConversations",
						{ legalEntityId, planId, companyDomainId },
						(draft) => {
							for (let i = draft.length - 1; i >= 0; --i) {
								const { associatedEntry } = draft[i];
								const isConnected =
									associatedEntry &&
									associatedEntry.type === "account" &&
									setOfIds.has(associatedEntry.id);
								if (isConnected) {
									draft.splice(i, 1);
								}
							}
						},
					),
				);

				try {
					await queryFulfilled;
				} catch {
					dispatch(
						accountModuleEntryApi.util.invalidateTags([
							...ids.map((id) => {
								return {
									type: "AccountModuleEntry" as const,
									id,
								};
							}),
							"ModuleCount",
							"Activity",
							"PlanningAccountNumber",

							// deleting an object transaction also deletes the connected conversation
							"Conversation",
						]),
					);
				}
			},
			invalidatesTags() {
				return [
					"AllocationKey",
					"ModuleCount",
					"AccountModuleEntry",
					"Activity",
					"PlanningAccountNumber",
					"Conversation",
					"UnsyncedAccount",
				];
			},
		}),

		getAccountModuleEntriesV2: builder.query<
			EntityState<DomainAccountModuleEntryV2, string>,
			{
				companyDomainId: string;
				planId: string;
				legalEntityId: string;
			}
		>({
			query({ planId, companyDomainId, legalEntityId }) {
				return {
					method: "GET",
					url: `${buildPlanModuleRoutePath({
						endpoint: "",
						refType: "account",
						companyDomainId,
						planId,
						legalEntityId,
						version: "v3",
					})}?pageSize=${Number.MAX_SAFE_INTEGER}`,
				};
			},
			transformResponse: (
				data: PaginatedResponse<DomainAccountModuleEntryV2>,
			) => {
				return accountModuleEntriesV2Adapter.addMany(
					accountModuleEntriesV2Adapter.getInitialState(),
					data.items,
				);
			},
			providesTags(result) {
				return result
					? [
							...result.ids.map((id) => ({
								type: "AccountModuleEntry" as const,
								id: `${id}`,
							})),
							"AccountModuleEntry",
						]
					: ["AccountModuleEntry"];
			},
		}),
	}),
	overrideExisting: false,
});

export const {
	useCreateAccountModuleEntryV2Mutation,
	useDeleteAccountModuleEntryMutation,
	useGetAccountModuleEntriesV2Query,
	useUpdateAccountModuleEntryV2Mutation,
} = accountModuleEntryApi;
