import {
	useGetAutoBalanceSheetHierarchyQuery,
	useGetBalanceSheetHierarchyQuery,
	useCreateBalanceSheetHierarchyGroupMutation,
	useUpdateBalanceSheetHierarchyGroupMutation,
	useRemoveBalanceSheetHierarchyGroupMutation,
	useMigrateAutoBalanceSheetHierarchyMutation,
} from "src/common/service/nexus/endpoints/account/index.js";
import { FinancialStatementPage } from "./FinancialStatementPage.js";

export const BalanceSheetHierarchyPage = () => {
	return (
		<FinancialStatementPage
			useAutoQuery={useGetAutoBalanceSheetHierarchyQuery}
			useHierarchyQuery={useGetBalanceSheetHierarchyQuery}
			createGroupMutation={useCreateBalanceSheetHierarchyGroupMutation()}
			updateGroupMutation={useUpdateBalanceSheetHierarchyGroupMutation()}
			removeGroupMutation={useRemoveBalanceSheetHierarchyGroupMutation()}
			migrateHierarchyMutation={useMigrateAutoBalanceSheetHierarchyMutation()}
		/>
	);
};
