import { IconMoodSadDizzy } from "@tabler/icons-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Button } from "../components/atoms/button/Button.tsx";
import {
	usePersistedNavigate,
	useGetPersistedTo,
} from "../persistentRouterFunctions.tsx";
import { Text } from "../components/atoms/typography/Text.tsx";

export function DynamicImportFailedError() {
	const { t } = useTranslation();
	const navigate = usePersistedNavigate();
	const to = useGetPersistedTo({ search: "?lr" });
	const [searchParams] = useSearchParams();

	useEffect(() => {
		if (!searchParams.has("lr") && typeof to === "object") {
			location.replace(`?${to.search}`);
		}
	}, [navigate, searchParams, to]);

	if (!searchParams.has("lr")) {
		return null;
	}

	return (
		<div className="mx-auto flex max-w-[600px] flex-col items-center justify-center gap-4 px-6 py-10">
			<IconMoodSadDizzy size={60} className="text-error-500" />
			<Text size="xl" weight="medium">
				{t("The page you're looking for is taking a siesta")}
			</Text>
			<Text size="md">
				{t(
					"This problem can most likely be resolved by reloading the page. Should the problem persist after reload, please contact support.",
				)}
			</Text>
			<div className="flex gap-4">
				<Button
					type="button"
					onClick={() => {
						navigate(-1);
					}}
				>
					{t("Go back")}
				</Button>
				<Button
					type="button"
					onClick={() => {
						location.reload();
					}}
					variant="secondaryGray"
				>
					{t("Reload the page")}
				</Button>
			</div>
		</div>
	);
}
