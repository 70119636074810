import { useEffect, useState } from "react";

export const useLocalStorageState = <T>(
	key: string,
	defaultValue: T,
	mappingFns?: {
		serializer: (data: T) => string;
		deserializer: (data: string) => T;
	},
) => {
	const [value, setValue] = useState<T>(() => {
		const storedData = localStorage.getItem(key);
		if (storedData === null) {
			return defaultValue;
		}
		let deserializedData: T;
		if (mappingFns?.deserializer) {
			deserializedData = mappingFns.deserializer(storedData);
		} else {
			deserializedData = storedData as unknown as T;
		}
		return deserializedData;
	});

	useEffect(() => {
		let serializedData: string;
		if (mappingFns?.serializer) {
			serializedData = mappingFns.serializer(value);
		} else {
			serializedData = value as unknown as string;
		}
		localStorage.setItem(key, serializedData);
	}, [key, mappingFns, value]);

	return [value, setValue] as const;
};

export const parseBoolean = (value: string) => {
	try {
		const parsed = JSON.parse(value);
		return typeof parsed === "boolean" ? parsed : false;
	} catch {
		return false;
	}
};
