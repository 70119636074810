import { QueriesResult } from "components/queryResult/QueriesResult.js";
import { arrayToRecordByKey } from "src/common/utils/array-utils/arrayToRecordByKey.js";
import { useGetAccountModuleEntriesV2Query } from "../../api/accountModuleEntryApi.js";
import { DrilldownDetailsItem } from "../../drilldown/DrilldownDetails.js";
import { useCurrentPlanOrThrow } from "../../useCurrentPlan.js";
import { accountModuleEntriesV2Selectors } from "../../utils/accountModuleEntriesAdapter.js";
import { getTransactionAmountFromAccount } from "../../utils/getTransactionAmount.js";
import type { DrilldownDetailsRenderProps } from "../common/types.js";

export const AccountTransactionDetailsComponent = ({
	transactions,
	accountById,
}: DrilldownDetailsRenderProps) => {
	const plan = useCurrentPlanOrThrow();
	return (
		<QueriesResult
			queries={{
				accountModuleEntries: useGetAccountModuleEntriesV2Query({
					companyDomainId: plan.companyId,
					legalEntityId: plan.nexusLegalEntityId,
					planId: plan.id,
				}),
			}}
			render={({ accountModuleEntries }) => {
				const accountModuleEntryById = arrayToRecordByKey(
					accountModuleEntriesV2Selectors.selectAll(accountModuleEntries),
					"id",
				);
				return (
					<>
						{transactions.map((transaction, index) => {
							const accountModuleEntry =
								accountModuleEntryById[transaction.refId];

							if (!accountModuleEntry) {
								return null;
							}

							return (
								<DrilldownDetailsItem
									key={index.toString()}
									name={accountModuleEntry.account.accountName}
									amount={getTransactionAmountFromAccount(
										transaction,
										accountById,
									)}
								/>
							);
						})}
					</>
				);
			}}
		/>
	);
};
