import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { QUERY_PARAMS_TO_PERSIST } from "./constants";
import { LegacyLegalEntitiesContext } from "./LegacyLegalEntityContext";

export const useLegacyLegalEntities = () => {
	const context = useContext(LegacyLegalEntitiesContext);
	if (!context) {
		throw new Error(
			"could not find legal entities context value; please ensure the component is wrapped in a <ProvideLegalEntities>",
		);
	}
	return context;
};

export const useLegalEntityQueryParameters = () => {
	const currentSearchParams = new URLSearchParams(useLocation().search);
	const searchParams = new URLSearchParams();
	for (const params of QUERY_PARAMS_TO_PERSIST) {
		const param = currentSearchParams.get(params);
		if (param !== null) {
			searchParams.set(params, param);
		}
	}
	const search = String(searchParams);
	return search.length === 0 ? undefined : search;
};
