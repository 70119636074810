import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from "react-router-dom";
import { AppError } from "../appError/AppError";
import { ChangeLog } from "./change-log/ChangeLog";

export const PlanPageWrapper = () => (
	<ErrorBoundary FallbackComponent={AppError}>
		<ChangeLog />
		<Outlet />
	</ErrorBoundary>
);
