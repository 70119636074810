import type { IconProps } from "@tabler/icons-react";
import { clsx } from "clsx";
import {
	cloneElement,
	forwardRef,
	type MouseEvent,
	type ReactElement,
	type ComponentPropsWithoutRef,
} from "react";
import { Chip } from "../chip/Chip";
import { Text } from "../typography/Text";

interface ToggleTabButtonProps extends ComponentPropsWithoutRef<"button"> {
	selected: boolean;
	count?: number | undefined;
	icon?: ReactElement<IconProps> | undefined;
}

export const ToggleTabButton = forwardRef<
	HTMLButtonElement,
	ToggleTabButtonProps
>(function ToggleTabButton(
	{ selected, children, count, onClick, icon, className, ...other },
	ref,
) {
	return (
		<button
			className={clsx(
				"inline-flex items-center whitespace-nowrap px-3 py-[6px]",
				selected && "rounded-md bg-white shadow",
				selected ? "text-gray-700" : "text-gray-600",
				className,
			)}
			onClick={(event: MouseEvent<HTMLButtonElement>) => {
				event.currentTarget.scrollIntoView({
					block: "nearest",
					inline: "nearest",
				});
				onClick?.(event);
			}}
			ref={ref}
			{...other}
		>
			{icon
				? cloneElement(icon, {
						size: 18,
						color: "currentColor",
						className: "mr-2",
					})
				: null}
			<Text size="sm" weight="medium" color="inherit">
				{children}
			</Text>
			{count !== undefined && (
				<Chip size="small" color="grey" className="ml-2">
					{count}
				</Chip>
			)}
		</button>
	);
});
