export const QUERY_PARAMS_TO_PERSIST = [
	"legal_entity",
	"legal_entity_mode",
] as const;

type PersistedQueryParam = (typeof QUERY_PARAMS_TO_PERSIST)[number];

export const isPersistedQueryParam = (
	value: string,
): value is PersistedQueryParam => {
	return QUERY_PARAMS_TO_PERSIST.includes(value as PersistedQueryParam);
};
