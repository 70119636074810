import { useContext } from "react";
import { DialogContext } from "./Dialog.js";

export const useDialogContext = () => {
	const context = useContext(DialogContext);

	if (context == null) {
		throw new Error("Dialog components must be wrapped in <Dialog />");
	}

	return context;
};
