import { IconRestore } from "@tabler/icons-react";
import { Button } from "../../../common/components/atoms/button/Button.tsx";
import { Dialog } from "../../../common/components/atoms/dialog/Dialog.tsx";
import { DialogActions } from "../../../common/components/atoms/dialog/DialogActions.tsx";
import { DialogBody } from "../../../common/components/atoms/dialog/DialogBody.tsx";
import { DialogContent } from "../../../common/components/atoms/dialog/DialogContent.tsx";
import { DialogTitle } from "../../../common/components/atoms/dialog/DialogTitle.tsx";
import { DialogTrigger } from "../../../common/components/atoms/dialog/DialogTrigger.tsx";
import { useTranslation } from "react-i18next";
import { useCurrentPlanOrThrow } from "../useCurrentPlan.ts";
import { useResetIncomingBalanceForPlanMutation } from "../api/incomingBalanceApi.ts";
import { showErrorNotification } from "../../../common/components/atoms/notifications/events.tsx";
import type { Ref } from "react";

export const ResetIncomingBalanceDialog = () => {
	const { t } = useTranslation();
	const [reset, { isLoading }] = useResetIncomingBalanceForPlanMutation();
	const plan = useCurrentPlanOrThrow();

	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button variant="secondaryGray" icon={<IconRestore />}>
					{t("Reset Incoming Balances")}
				</Button>
			</DialogTrigger>
			<DialogContent>
				{({ close, initialElementRef }) => {
					return (
						<>
							<DialogTitle>{t("Reset Incoming Balances")}</DialogTitle>
							<DialogBody>
								{t("All incoming balances in this plan will be reset.")}
							</DialogBody>
							<DialogActions
								withCancelButton
								cancelButtonRef={initialElementRef as Ref<HTMLButtonElement>}
							>
								<Button
									onClick={async () => {
										const result = await reset({
											planId: plan.id,
											legalEntityId: plan.nexusLegalEntityId,
											companyDomainId: plan.companyId,
										});
										if ("error" in result) {
											showErrorNotification({
												message: t("Something failed..."),
											});
										}
										close();
									}}
									isLoading={isLoading}
								>
									{t("Reset Incoming Balances")}
								</Button>
							</DialogActions>
						</>
					);
				}}
			</DialogContent>
		</Dialog>
	);
};
