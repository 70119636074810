import type { HierarchyType } from "src/common/service/nexus/utils/hierarchy.js";
import { useLegalEntityMode } from "../../../common/context/legalEntityContextUtils";
import { LegalEntityMode } from "../../../common/context/legalEntityMode.js";
import { useCurrentLegalEntityOrThrow } from "../../../common/legal-entities/useCurrentLegalEntity.js";
import { useLegalEntityHierarchy } from "../../../common/legal-entities/useLegalEntityHierarchy.js";
import { invariant } from "../../../common/utils/invariant.js";

export const useCurrentHierarchyMode = () => {
	const { legalEntity } = useCurrentLegalEntityOrThrow();

	const { legalEntityMode } = useLegalEntityMode();
	const { hierarchy } = useLegalEntityHierarchy();

	const parentLegalEntityId =
		legalEntityMode === LegalEntityMode.Company
			? legalEntity.id
			: hierarchy?.hierarchy?.legalEntity.id;

	invariant(parentLegalEntityId);

	const hierarchyType: HierarchyType =
		legalEntityMode === LegalEntityMode.Company ? "legalEntity" : "group";

	return { parentLegalEntityId, hierarchyType };
};
