import { clsx } from "clsx";
import { type CSSProperties, forwardRef, type ComponentProps } from "react";

type DisplaySize = "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
type DisplayWeight = "regular" | "medium" | "semibold" | "bold";

const fontWeightByWeight: Record<DisplayWeight, number> = {
	bold: 700,
	semibold: 600,
	medium: 500,
	regular: 400,
};

const typographyProperties: Record<
	DisplaySize,
	Pick<CSSProperties, "lineHeight" | "fontSize">
> = {
	"2xl": { fontSize: 72, lineHeight: "90px" },
	xl: { fontSize: 60, lineHeight: "72px" },
	lg: { fontSize: 48, lineHeight: "60px" },
	md: { fontSize: 36, lineHeight: "44px" },
	sm: { fontSize: 30, lineHeight: "38px" },
	xs: { fontSize: 24, lineHeight: "32px" },
};

interface DisplayProps extends ComponentProps<"div"> {
	size: DisplaySize;
	weight: DisplayWeight;
}

export const Display = forwardRef<HTMLDivElement, DisplayProps>(
	function Display({ className, size, weight, style, ...others }, ref) {
		const styleFromSize = typographyProperties[size];
		return (
			<div
				ref={ref}
				className={clsx("font-display", className)}
				style={{
					...styleFromSize,
					fontWeight: fontWeightByWeight[weight],
					...style,
				}}
				{...others}
			/>
		);
	},
);
