import { clsx } from "clsx";
import { forwardRef, type LabelHTMLAttributes } from "react";
import { Text, type TextProps } from "../typography/Text";
import { Radio, type RadioProps } from "./Radio";

interface RadioFieldProps extends RadioProps {
	label?: string;
	labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
	textProps?: Partial<TextProps>;
}

export const RadioField = forwardRef<HTMLInputElement, RadioFieldProps>(
	({ label, labelProps, textProps, ...props }, ref) => {
		const { className: labelClassName, ...extraLabelProps } = labelProps ?? {};
		const { className, ...restProps } = props;

		return (
			<label
				className={clsx(
					"inline-flex cursor-pointer items-center",
					labelClassName,
				)}
				{...extraLabelProps}
			>
				<Radio
					{...restProps}
					className={clsx("mr-2 shrink-0", className)}
					ref={ref}
					style={{ marginInlineEnd: 8 }}
				/>
				<Text size="sm" weight="medium" color="text-grey-700" {...textProps}>
					{label}
				</Text>
			</label>
		);
	},
);

RadioField.displayName = "RadioField";
