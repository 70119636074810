import {
	useMergeRefs,
	FloatingPortal,
	FloatingOverlay,
	FloatingFocusManager,
	useTransitionStyles,
} from "@floating-ui/react";
import {
	type ComponentPropsWithoutRef,
	type MutableRefObject,
	type ReactNode,
	forwardRef,
	useRef,
} from "react";
import { clsx } from "clsx";
import { useDrawerContext } from "./DrawerContext.js";
import { zIndicies } from "../../../utils/zIndicies.js";
import { maybe } from "../../../utils/maybe.js";

interface DrawerContentProps
	extends Omit<ComponentPropsWithoutRef<"div">, "children"> {
	children:
		| ReactNode
		| ((options: {
				initialElementRef: MutableRefObject<HTMLElement | null>;
				close: () => void;
		  }) => JSX.Element);
	returnFocus?: boolean;
	disableFocusTrap?: boolean;
	disableOverlay?: boolean;
	closeOnOutsidePress?: boolean;
}

export const DrawerContent = forwardRef<HTMLDivElement, DrawerContentProps>(
	function DrawerContent(
		{
			children,
			returnFocus = true,
			disableFocusTrap,
			disableOverlay,
			className,
			style,
			...rest
		},
		propRef,
	) {
		const {
			context: floatingContext,
			position,
			setOpen,
			...context
		} = useDrawerContext();
		const ref = useMergeRefs([context.refs.setFloating, propRef]);

		const { isMounted, styles: floatingTransitionStyles } = useTransitionStyles(
			floatingContext,
			{
				duration: 200,
				initial: {
					opacity: 0,
					transform: `translateX(${(position === "left" ? -1 : 1) * 100}%)`,
				},
				open: {
					opacity: 1,
					transform: "translateX(0)",
				},
				close: {
					opacity: 0,
					transform: `translateX(${(position === "left" ? -1 : 1) * 100}%)`,
				},
			},
		);
		const { styles: overlayTransitionStyles } = useTransitionStyles(
			floatingContext,
			{
				duration: 200,
				initial: { opacity: 0 },
				open: { opacity: 1 },
				close: { opacity: 0 },
			},
		);

		const initialElementRef = useRef<HTMLElement | null>(null);

		if (!isMounted) {
			return null;
		}

		return (
			<FloatingPortal>
				{!disableOverlay && (
					<FloatingOverlay
						className={clsx("bg-black/70", zIndicies.DRAWER)}
						style={overlayTransitionStyles}
						lockScroll
					/>
				)}
				<FloatingFocusManager
					context={floatingContext}
					{...maybe(typeof children === "function", {
						initialFocus: initialElementRef,
					})}
					returnFocus={returnFocus}
					{...maybe(disableFocusTrap !== undefined, {
						disabled: disableFocusTrap,
					})}
				>
					<div
						className={clsx(
							"pointer-events-none fixed inset-0 flex",
							position === "left" ? "justify-start" : "justify-end",
							zIndicies.DRAWER,
						)}
					>
						<div
							ref={ref}
							aria-labelledby={context.labelId}
							aria-describedby={context.descriptionId}
							{...context.getFloatingProps({
								...rest,
								style: {
									...style,
									...floatingTransitionStyles,
								},
								className: clsx(
									"w-96 min-h-full bg-white shadow-lg pointer-events-auto overflow-auto",
									className,
								),
							})}
						>
							{typeof children === "function"
								? children({
										initialElementRef,
										close() {
											setOpen(false);
										},
									})
								: children}
						</div>
					</div>
				</FloatingFocusManager>
			</FloatingPortal>
		);
	},
);
