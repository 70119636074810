import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { Button } from "../button/Button.tsx";
import { Dialog } from "./Dialog.tsx";
import { DialogActions } from "./DialogActions.tsx";
import { DialogBody } from "./DialogBody.tsx";
import { DialogContent } from "./DialogContent.tsx";
import { DialogTitle } from "./DialogTitle.tsx";
import type { Except } from "type-fest";
import { DialogTrigger } from "./DialogTrigger.tsx";

type ConfirmDialogProps = Except<
	ComponentPropsWithoutRef<typeof Dialog>,
	"children"
> & {
	onConfirm(): void;
	title: string;
	description?: string;
	confirmButtonLabel: string;
	isLoading?: boolean;
	destructive?: boolean;
	children?: ReactNode;
};

export const ConfirmDialog = ({
	onConfirm,
	confirmButtonLabel,
	onClose,
	open,
	title,
	description,
	destructive = false,
	isLoading = false,
	children,
}: ConfirmDialogProps) => {
	return (
		<Dialog open={open} onClose={onClose}>
			{children && <DialogTrigger asChild>{children}</DialogTrigger>}
			<DialogContent>
				<DialogTitle>{title}</DialogTitle>
				<DialogBody>{description}</DialogBody>
				<DialogActions withCancelButton>
					<Button
						destructive={destructive}
						onClick={onConfirm}
						isLoading={isLoading}
						disabled={isLoading}
					>
						{confirmButtonLabel}
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	);
};
