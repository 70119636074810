import { createContext, useContext } from "react";
import type { makeNumberFormatter } from "../../utils.js";

export type FormatAmountVariant = "exact" | "thousands";

export type FormatAmountFn = ReturnType<typeof makeNumberFormatter>;

export interface AmountFormatContextValue {
	formatter: FormatAmountFn;
	formatVariant: FormatAmountVariant;
}

export const AmountFormatContext = createContext<AmountFormatContextValue>(
	null as any,
);

export const useAmountFormatter = () => {
	const { formatter } = useContext(AmountFormatContext);
	return formatter;
};
