import type { ReactNode } from "react";
import { BreadcrumbsLayout } from "../../../common/components/layout/BreadcrumbsLayout.js";

type Props = {
	title: string;
	subTitle?: string | undefined;
	children?: ReactNode;
	maxWidthClassName?: "max-w-screen-xl" | "max-w-screen-lg";
};

export const EntityHierarchyLayout = ({
	title,
	subTitle,
	children,
	maxWidthClassName = "max-w-screen-lg",
}: Props) => {
	return (
		<BreadcrumbsLayout
			maxWidthClassName={maxWidthClassName}
			breadcrumbs={
				subTitle ? [{ name: title }, { name: subTitle }] : [{ name: title }]
			}
		>
			{children}
		</BreadcrumbsLayout>
	);
};
