import { clsx } from "clsx";
import type { ComponentPropsWithoutRef } from "react";
import { Text } from "../typography/Text";

interface DividerProps extends ComponentPropsWithoutRef<"hr"> {
	label?: string | undefined;
}

export const Divider = ({ className, label, ...props }: DividerProps) => {
	const withLabel = label !== undefined;
	return (
		<div
			role="separator"
			className={clsx(
				"border-0 border-t border-solid border-t-gray-200",
				withLabel && "border-t-0",
				className,
			)}
			{...props}
		>
			{withLabel && (
				<Text
					size="xs"
					style={{ marginTop: 2 }}
					className="flex items-center before:mr-2 before:hidden before:h-[1px] before:flex-1 before:border-t before:border-t-gray-200 after:ml-2 after:flex-1 after:border-t after:border-t-gray-200"
					color="text-grey-600"
				>
					{label}
				</Text>
			)}
		</div>
	);
};
