import type { HTMLAttributes, ReactNode } from "react";
import styles from "./Alert.module.css";
import { clsx } from "clsx";
import { Text } from "../typography/Text";
import {
	type Icon,
	IconAlertTriangle,
	IconCircleCheck,
	IconInfoCircle,
	IconX,
} from "@tabler/icons-react";

type AlertType = "info" | "warning" | "error" | "success";

const iconColor: Record<AlertType, string> = {
	info: "text-grey-600",
	error: "text-error-600",
	success: "text-primary-600",
	warning: "text-warning-600",
};

const iconComponent: Record<AlertType, Icon> = {
	info: IconInfoCircle,
	error: IconInfoCircle,
	success: IconCircleCheck,
	warning: IconAlertTriangle,
};

interface AlertProps
	extends Omit<HTMLAttributes<HTMLDivElement>, "title" | "children"> {
	onClose?: () => void;
	title?: string;
	color: AlertType;
	supportingText?: ReactNode;
	actions?: ReactNode;
	children?: ReactNode;
}

export const Alert = ({
	onClose,
	color,
	title,
	supportingText,
	className,
	actions,
	children,
	...props
}: AlertProps) => {
	const Icon = iconComponent[color];
	return (
		<div
			className={clsx(styles.alert, styles[`color_${color}`], className)}
			{...props}
		>
			<div className={styles.iconWrapper}>
				<Icon className={iconColor[color]} size={20} />
			</div>
			<div className="grow">
				{title && <div className={styles.title}>{title}</div>}
				{supportingText && (
					<Text size="sm" weight="regular" className={styles.supportingText}>
						{supportingText}
					</Text>
				)}
				{children}
				{actions && <div className={styles.actions}>{actions}</div>}
			</div>
			{onClose && (
				<button type="button" className={styles.closeButton} onClick={onClose}>
					<IconX />
				</button>
			)}
		</div>
	);
};
