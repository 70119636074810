import type { MergeSystem } from "src/common/service/fusionHub/types";
import { CreateMergeAccountingIntegrationModal } from "./CreateIntegrationModal";
import type {
	MergeAccountingCompanyInfo,
	MergeAccountingToken,
} from "src/common/service/fusionHub/integrations/mergeAccountingApi";

type Props = {
	token: MergeAccountingToken;
	companyInfo: MergeAccountingCompanyInfo;
	system: MergeSystem;
};
export const NotConnectedCompanyInfo = ({
	token,
	companyInfo,
	system,
}: Props) => {
	return (
		<tr>
			<td>{companyInfo.name}</td>
			<td>
				<img src={system.logo} alt={system.name} width="75" />
			</td>
			<td>
				<CreateMergeAccountingIntegrationModal
					companyInfo={companyInfo}
					system={system}
					token={token}
				/>
			</td>
		</tr>
	);
};
