import {
	combineReducers,
	configureStore,
	type Reducer,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
	type TypedUseSelectorHook,
	useDispatch,
	useSelector,
} from "react-redux";
import { accessTokenSlice } from "./slices/accessTokenSlice";
import { exopenApi } from "./api/exopenApi";
import type { CreatePlanState } from "../../features/planning/plans/CreatePlanPage/createPlanState";
import { emptyPlanningApi } from "src/features/planning/api/emptyPlanningApi.js";
import { emptyFusionHubApi } from "src/common/service/fusionHub/emptyFusionHubApi.ts";
import { emptyNexusApi } from "src/common/service/nexus/emptyNexusApi.ts";

const staticReducers = {
	[emptyPlanningApi.reducerPath]: emptyPlanningApi.reducer,
	[emptyNexusApi.reducerPath]: emptyNexusApi.reducer,
	[exopenApi.reducerPath]: exopenApi.reducer,
	[emptyFusionHubApi.reducerPath]: emptyFusionHubApi.reducer,
	[accessTokenSlice.name]: accessTokenSlice.reducer,
};

export const makeStore = () => {
	return configureStore({
		reducer: staticReducers,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
			}).concat(
				emptyPlanningApi.middleware,
				exopenApi.middleware,
				emptyNexusApi.middleware,
				emptyFusionHubApi.middleware,
			),
	});
};

// don't use in tests
export const store = makeStore();

setupListeners(store.dispatch);

type RootState = ReturnType<typeof store.getState> & {
	createPlanState: CreatePlanState;
};
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

function createReducer(asyncReducers: Record<string, Reducer>) {
	return combineReducers({
		...staticReducers,
		...asyncReducers,
	});
}

const asyncReducers: Record<string, Reducer> = {};

export const injectReducer = (key: string, asyncReducer: Reducer) => {
	asyncReducers[key] = asyncReducer;
	store.replaceReducer(createReducer(asyncReducers));
};
