import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { useCurrentCompanyDomain } from "src/common/company-domain/useCurrentCompanyDomain";
import { useCurrentLegalEntity } from "src/common/legal-entities/useCurrentLegalEntity";

export const RedirectLegacyPlanningPage = () => {
	const { companyDomain } = useCurrentCompanyDomain();
	const { legalEntity } = useCurrentLegalEntity();
	const { "*": wildcard } = useParams();
	const [searchParams] = useSearchParams();

	if (!legalEntity) {
		return <Navigate to="/" />;
	}
	return (
		<Navigate
			to={{
				pathname: `/company-domains/${companyDomain.id}/legal-entities/${legalEntity.id}/${wildcard}`,
				search: `?${searchParams.toString()}`,
			}}
		/>
	);
};
