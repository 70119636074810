import { emptyFusionHubApi } from "src/common/service/fusionHub/emptyFusionHubApi";

const fortnoxApi = emptyFusionHubApi.injectEndpoints({
	endpoints: (builder) => ({
		getFortnoxAuthorizationUrl: builder.query<
			{ authorizationUrl: string },
			{
				companyDomainId: string;
				successRedirectUrl: string;
				errorRedirectUrl: string;
			}
		>({
			query: ({ companyDomainId, successRedirectUrl, errorRedirectUrl }) => ({
				url: "/integrations/fortnox/authorization-url",
				params: { successRedirectUrl, errorRedirectUrl, companyDomainId },
			}),
		}),
		getFortnoxReauthorizeUrl: builder.query<
			{ authorizationUrl: string },
			{
				integrationId: number;
				successRedirectUrl: string;
				errorRedirectUrl: string;
			}
		>({
			query: ({ integrationId, successRedirectUrl, errorRedirectUrl }) => ({
				url: `/integrations/${integrationId}/fortnox-raw/re-authorization-url`,
				params: { successRedirectUrl, errorRedirectUrl },
				method: "GET",
			}),
		}),
	}),
	overrideExisting: false,
});

export const {
	useLazyGetFortnoxAuthorizationUrlQuery,
	useLazyGetFortnoxReauthorizeUrlQuery,
} = fortnoxApi;
