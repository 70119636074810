import { createApi } from "@reduxjs/toolkit/query/react";
import { makeBaseQueryWithUnauthentication } from "../../redux/api/baseQuery";
import { fusionHubApiUrl } from "src/env.ts";
import { TAGS } from "./types.ts";

export const emptyFusionHubApi = createApi({
	reducerPath: "fusionHubApi",
	baseQuery: makeBaseQueryWithUnauthentication({
		baseUrl: fusionHubApiUrl,
	}),
	tagTypes: TAGS,
	endpoints: () => ({}),
});
