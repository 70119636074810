import type { Builder } from "../../types.js";
import {
	buildGetHierarchyEndpoint,
	buildCreateHierarchyGroupEndpoint,
	buildUpdateHierarchyGroupEndpoint,
	buildRemoveHierarchyGroupEndpoint,
} from "../../utils/hierarchy.js";
import type { Product } from "./types.js";

export const buildHierarchyEndpoints = (builder: Builder) => ({
	getProductHierarchy: buildGetHierarchyEndpoint<Product>(
		"Product",
		"product",
		builder,
	),
	createProductHierarchyGroup: buildCreateHierarchyGroupEndpoint<Product>(
		"Product",
		"product",
		builder,
	),
	updateProductHierarchyGroup: buildUpdateHierarchyGroupEndpoint<Product>(
		"Product",
		"product",
		builder,
	),
	removeProductHierarchyGroup: buildRemoveHierarchyGroupEndpoint<Product>(
		"Product",
		"product",
		builder,
	),
});
