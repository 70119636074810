import { BreadcrumbsLayout } from "../../common/components/layout/BreadcrumbsLayout";
import { useDocumentTitle } from "../../common/utils/hooks/useDocumentTitle";
import { Ewr } from "./Ewr";

export const Reports = () => {
	const pageName = "Exopen Web Reports";
	useDocumentTitle(pageName);

	return (
		<BreadcrumbsLayout breadcrumbs={[{ name: pageName }]}>
			<Ewr />
		</BreadcrumbsLayout>
	);
};
