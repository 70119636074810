import { clsx } from "clsx";
import { type CSSProperties, forwardRef } from "react";
import { createPolymorphicComponent } from "../../../utils/createPolymorphicComponent";

type TextSize = "xs" | "sm" | "md" | "lg" | "xl";
type TextWeight = "regular" | "medium" | "semibold" | "bold";

const classBySize: Record<TextSize, string> = {
	xl: clsx("text-xl"),
	lg: clsx("text-lg"),
	md: clsx("text-base"),
	sm: clsx("text-sm"),
	xs: clsx("text-xs"),
};

const classByWeight: Record<TextWeight, string> = {
	regular: clsx("font-normal"),
	medium: clsx("font-medium"),
	semibold: clsx("font-semibold"),
	bold: clsx("font-bold"),
};

export interface TextProps {
	size?: TextSize | undefined;
	weight?: TextWeight | undefined;
	color?: string | undefined;
	span?: boolean | undefined;
	className?: string | undefined;
}

const _Text = forwardRef<HTMLDivElement, TextProps & { component?: any }>(
	function Text(
		{
			className,
			size = "md",
			weight = "regular",
			span = false,
			color = "text-grey-900",
			component,
			...others
		},
		ref,
	) {
		const Component = component ? component : span ? "span" : "div";

		const style: CSSProperties = {};
		let colorClass: string | undefined = undefined;
		if (color?.startsWith("#")) {
			style.color = color;
		} else {
			colorClass = color;
		}

		return (
			<Component
				ref={ref}
				className={clsx(
					"font-text",
					classBySize[size],
					classByWeight[weight],
					colorClass,
					className,
				)}
				style={style}
				{...others}
			/>
		);
	},
);

export const Text = createPolymorphicComponent<"div", TextProps>(_Text);
