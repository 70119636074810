import { useCallback, useMemo } from "react";
import { useGetAllCompaniesQuery } from "../redux/api/exopenApi.js";
import { invariant } from "../utils/invariant.js";
import type { CompanyDomain } from "./types.js";

export const useCompanyDomains = () => {
	const {
		data: companyDomains,
		isLoading,
		isError,
	} = useGetAllCompaniesQuery();

	const convertedCompanyDomains = useMemo(() => {
		return companyDomains?.map<CompanyDomain>((companyDomain) => ({
			...companyDomain,
			id: `${companyDomain.id}`,
		}));
	}, [companyDomains]);

	const getById = useCallback(
		(companyDomainId: string | number) => {
			return (
				(convertedCompanyDomains ?? []).find(
					(companyDomain) => companyDomain.id === `${companyDomainId}`,
				) ?? null
			);
		},
		[convertedCompanyDomains],
	);

	return useMemo(
		() => ({
			companyDomains: convertedCompanyDomains ?? null,
			isLoading,
			isError,
			getById,
		}),
		[convertedCompanyDomains, getById, isError, isLoading],
	);
};

export const useCompanyDomainsOrThrow = () => {
	const { companyDomains, ...rest } = useCompanyDomains();

	invariant(companyDomains, "Company domains are not loaded");

	return useMemo(
		() => ({
			companyDomains,
			getById: rest.getById,
		}),
		[companyDomains, rest.getById],
	);
};
