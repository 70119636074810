import { IconHelp } from "@tabler/icons-react";
import { clsx } from "clsx";
import { Tooltip } from "components/tooltip/Tooltip.js";
import { TooltipTrigger } from "components/tooltip/TooltipTrigger.js";
import { TooltipContent } from "components/tooltip/TooltipContent.js";
import { Text } from "components/typography/Text.js";
import type { ComponentProps, ReactNode } from "react";

interface HelpIconProps {
	label?: string;
	tooltipPlacement?: ComponentProps<typeof Tooltip>["placement"];
	className?: string;
	children?: ReactNode | undefined;
}

export const HelpIcon = ({
	label,
	className,
	tooltipPlacement,
	children,
}: HelpIconProps) => {
	return (
		<Tooltip placement={tooltipPlacement}>
			<TooltipTrigger>
				<IconHelp
					size={16}
					className={clsx("text-grey-400 hover:text-grey-500", className)}
				/>
			</TooltipTrigger>
			<TooltipContent className="max-w-xs">
				{label ? <Text color="text-white">{label}</Text> : children}
			</TooltipContent>
		</Tooltip>
	);
};
