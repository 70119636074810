import { Button } from "components/button/Button.js";
import type { Ref, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useDialogContext } from "./useDialogContext.js";

export const DialogActions = ({
	children,
	withCancelButton = false,
	cancelButtonRef,
	onCancel,
}: {
	children?: ReactNode | undefined;
	withCancelButton?: boolean;
	cancelButtonRef?: Ref<HTMLButtonElement>;
	onCancel?(): void;
}) => {
	const { t } = useTranslation();
	const { setOpen } = useDialogContext();
	return (
		<div className="flex justify-end gap-4 p-4">
			{withCancelButton && (
				<Button
					type="button"
					variant="secondaryGray"
					onClick={() => {
						setOpen(false);
						onCancel?.();
					}}
					ref={cancelButtonRef}
				>
					{t("Cancel")}
				</Button>
			)}
			{children}
		</div>
	);
};
