import { IconCaretDownFilled, IconX } from "@tabler/icons-react";
import { Popover } from "../atoms/popoverMenu/Popover.js";
import { PopoverContent } from "../atoms/popoverMenu/PopoverContent.js";
import { PopoverTrigger } from "../atoms/popoverMenu/PopoverTrigger.js";
import {
	type CSSProperties,
	useRef,
	type ReactNode,
	useEffect,
	type MutableRefObject,
	type ChangeEventHandler,
} from "react";
import { Text } from "../atoms/typography/Text.js";
import { useVirtualizer, type VirtualItem } from "@tanstack/react-virtual";
import { Input } from "components/input/Input.js";
import { useDebouncedCallback } from "src/common/utils/hooks/useDebouncedCallback.js";
import { useTranslation } from "react-i18next";

const Content = <T,>({
	header,
	items,
	children,
	close,
	activeIndex,
	initialElementRef,
	onFilter,
	placeholderLabel,
}: {
	header: string;
	items: T[];
	children: (props: {
		close: () => void;
		virtualItem: VirtualItem;
		style: CSSProperties;
	}) => ReactNode;
	close: () => void;
	activeIndex: number | null;
	initialElementRef: MutableRefObject<HTMLElement | null>;
	onFilter?: ((value: string) => void) | undefined;
	placeholderLabel?: string | undefined;
}) => {
	const { t } = useTranslation();
	const parentRef = useRef<HTMLDivElement>(null);

	const rowVirtualizer = useVirtualizer({
		count: items.length,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 40,
	});

	useEffect(() => {
		if (typeof activeIndex === "number") {
			rowVirtualizer.scrollToIndex(activeIndex, { align: "center" });
		}
	}, [rowVirtualizer.scrollToIndex, activeIndex]);

	const onChange: ChangeEventHandler<HTMLInputElement> = useDebouncedCallback(
		(event) => {
			onFilter?.(event.target.value.trim().toLocaleLowerCase());
		},
		250,
	);

	return (
		<Popover.ContentContainer className="overflow-hidden w-[300px]">
			<div className="border-b p-2 px-3 flex">
				<Text weight="medium" size="sm" className="grow">
					{header}
				</Text>
				<button
					type="button"
					onClick={() => {
						close();
					}}
				>
					<IconX className="text-gray-500 hover:text-gray-700" size={18} />
				</button>
			</div>
			{onFilter && (
				<div className="p-3 border-b space-y-3">
					<Input
						placeholder={placeholderLabel}
						ref={initialElementRef as any}
						onChange={onChange}
						onFocus={(event) => {
							event.target.select();
						}}
					/>
					{items?.length === 0 && (
						<div className="text-center text-gray-500">
							{t("No results matched your search.")}
						</div>
					)}
				</div>
			)}
			<div className="max-h-[300px] overflow-y-auto" ref={parentRef}>
				<div
					style={{
						height: `${rowVirtualizer.getTotalSize()}px`,
						width: "100%",
						position: "relative",
					}}
					className="flex flex-col"
				>
					{rowVirtualizer.getVirtualItems().map((virtualItem) => {
						return children({
							close,
							virtualItem,
							style: {
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: `${virtualItem.size}px`,
								transform: `translateY(${virtualItem.start}px)`,
								whiteSpace: "nowrap",
							},
						});
					})}
				</div>
			</div>
		</Popover.ContentContainer>
	);
};

export const FilterPopover = <T,>({
	label,
	header,
	children,
	isActive,
	items,
	activeIndex,
	onFilter,
	onClose,
	placeholderLabel,
}: {
	label: string;
	header: string;
	children: (props: {
		close: () => void;
		virtualItem: VirtualItem;
		style: CSSProperties;
	}) => ReactNode;
	isActive: boolean;
	items: T[];
	activeIndex: number | null;
	onFilter?: ((value: string) => void) | undefined;
	onClose?: (() => void) | undefined;
	placeholderLabel?: string | undefined;
}) => {
	return (
		<Popover role="dialog" placement="bottom-end" onClose={onClose}>
			<PopoverTrigger>
				<div className="group flex items-center gap-1 rounded-lg hover:bg-black/10 px-3 py-2">
					<Text
						size="sm"
						weight="medium"
						color={isActive ? "text-purple-500" : "text-gray-500"}
						className="transition-colors group-hover:text-gray-900"
					>
						{label}
					</Text>
					<IconCaretDownFilled
						size={16}
						className="text-gray-500 transition-colors group-hover:text-gray-900"
					/>
				</div>
			</PopoverTrigger>
			<PopoverContent>
				{({ close, initialElementRef }) => {
					return (
						<Content
							header={header}
							close={close}
							items={items}
							activeIndex={activeIndex}
							initialElementRef={initialElementRef}
							onFilter={onFilter}
							placeholderLabel={placeholderLabel}
						>
							{children}
						</Content>
					);
				}}
			</PopoverContent>
		</Popover>
	);
};
