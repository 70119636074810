import { forwardRef, type ReactNode } from "react";
import { createPolymorphicComponent } from "../../../utils/createPolymorphicComponent";
import { Text, type TextProps } from "../typography/Text";
import { clsx } from "clsx";
import { IconExternalLink } from "@tabler/icons-react";
import type { PartialBy } from "src/common/utils/PartialBy.js";

export const DEFAULT_LINK_COLOR = "text-purple-500";

interface AnchorProps extends PartialBy<TextProps, "size"> {
	external?: boolean;
	children?: ReactNode | undefined;
}

const _Anchor = forwardRef<HTMLAnchorElement, AnchorProps & { component: any }>(
	(
		{
			component,
			className,
			external = false,
			children,
			color = DEFAULT_LINK_COLOR,
			size = "sm",
			weight = "medium",
			...others
		},
		ref,
	) => {
		const childrenWithExternal = external ? (
			<span
				className="self-start overflow-hidden text-ellipsis whitespace-nowrap"
				title={typeof children === "string" ? children : undefined}
			>
				{children}
			</span>
		) : (
			children
		);

		return (
			<Text
				ref={ref}
				component={component ?? "a"}
				color={color}
				size={size}
				weight={weight}
				className={clsx(
					"inline-flex max-w-full cursor-pointer items-center gap-1 hover:underline",
					className,
				)}
				{...others}
			>
				<span className="truncate">{childrenWithExternal}</span>
				{external && <IconExternalLink className="shrink-0" size={16} />}
			</Text>
		);
	},
);

_Anchor.displayName = "Anchor";

export const Anchor = createPolymorphicComponent<"a", AnchorProps>(_Anchor);
