import { emptyFusionHubApi } from "src/common/service/fusionHub/emptyFusionHubApi";
import type { MergeSystem } from "src/common/service/fusionHub/types";

export type MergeAccountingCompanyInfo = {
	name: string;
	legalName: string | null;
	id: string;
};

export type MergeAccountingTrackingCategory = {
	name: string | null;
	status: string | null;
	categoryType: string | null;
	parentCategory: string | null;
	company: string | null;
};

type MergeAccountingIntegrationConfig = {
	id: string;
	integrationId: number;
	tokenId: string;
	companyInfoMergeId: string;
	costCenterTrackingCategoryType: string | null;
};

export type MergeAccountingToken = {
	id: string;
	linkedAccountId: string;
	system: string;
	companyDomainId: string | null;
};

export type MergeAccountingTokenAggregated = {
	token: MergeAccountingToken;
	integrationConfigs: MergeAccountingIntegrationConfig[];
	companyInfos: MergeAccountingCompanyInfo[];
	isDataSourceReady: boolean;
};

const mergeAccountingApi = emptyFusionHubApi.injectEndpoints({
	endpoints: (builder) => ({
		getMergeAccountingSystems: builder.query<{ systems: MergeSystem[] }, void>({
			query: () => "/integrations/merge-accounting/systems",
		}),
		getMergeAccountingMagicLinkUrl: builder.query<
			{
				magicLink: {
					linkToken: string;
					integrationName?: string;
					magicLinkUrl?: string;
				};
			},
			{ companyDomainId: string; system: string; createMagicLinkUrl: boolean }
		>({
			query: (body) => ({
				url: "/integrations/merge-accounting/tokens/magic-link",
				method: "POST",
				body,
			}),
			providesTags: [{ type: "MergeAccountingMagicLink" }],
		}),
		createMergeAccountingToken: builder.mutation<
			{ id: number },
			{ companyDomainId: string; publicToken: string; system: string }
		>({
			query: ({ companyDomainId, publicToken, system }) => ({
				url: "/integrations/merge-accounting/tokens",
				method: "POST",
				body: { companyDomainId, publicToken, system },
			}),
			invalidatesTags: [
				{ type: "MergeAccountingTokens" },
				{ type: "MergeAccountingMagicLink" },
			],
		}),
		getMergeAccountingTokensAggregated: builder.query<
			{ tokens: MergeAccountingTokenAggregated[] },
			{ companyDomainId: string }
		>({
			query: ({ companyDomainId }) => ({
				url: "/integrations/merge-accounting/tokens-aggregated",
				params: { companyDomainId },
			}),
			providesTags: () => [
				{
					type: "MergeAccountingTokens",
				},
			],
		}),
		getMergeAccountingTrackingCategories: builder.query<
			{
				trackingCategories: MergeAccountingTrackingCategory[];
			},
			{ tokenId: string; mergeCompanyId: string }
		>({
			query: ({ tokenId, mergeCompanyId }) =>
				`/integrations/merge-accounting/tokens/${tokenId}/company/${mergeCompanyId}/tracking-categories`,
		}),
		createMergeAccountingIntegration: builder.mutation<
			{ integrationId: number },
			{
				companyInfoId: string;
				costCenterTrackingCategoryType: string | undefined;
				tokenId: string;
			}
		>({
			query: ({ companyInfoId, costCenterTrackingCategoryType, tokenId }) => ({
				url: `/integrations/merge-accounting/tokens/${tokenId}/integrations`,
				method: "POST",
				body: { costCenterTrackingCategoryType, companyInfoId },
			}),
			invalidatesTags: [
				{ type: "Integrations" },
				{ type: "MergeAccountingTokens" },
			],
		}),
	}),
	overrideExisting: false,
});

export const {
	useGetMergeAccountingSystemsQuery,
	useGetMergeAccountingMagicLinkUrlQuery,
	useCreateMergeAccountingTokenMutation,
	useGetMergeAccountingTokensAggregatedQuery,
	useGetMergeAccountingTrackingCategoriesQuery,
	useCreateMergeAccountingIntegrationMutation,
} = mergeAccountingApi;
