import { baseUrl, fetchWithAuth } from "./request";

export async function getInvoice(path: string, token: string): Promise<any> {
	const url = `${baseUrl}${path.slice(1)}.pdf`;

	const response = await fetchWithAuth({
		url,
		token,
	});
	if (response.status >= 200 && response.status <= 299) {
		return response.blob();
	}
	throw new Error(response.statusText);
}
