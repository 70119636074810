import { clsx } from "clsx";

export const SideMenu = ({
	className,
	...rest
}: React.ComponentProps<"div">) => (
	<div
		className={clsx(
			"flex w-full shrink-0 flex-col gap-1 pl-3 lg:w-52",
			className,
		)}
		{...rest}
	/>
);
