import type { PaginatedResponse, Builder } from "../../types.js";
import type { IncomingBalance } from "./types.js";
import { loadAllPages } from "../../utils/loadAllPages.js";
import { formatYYYYMMDD } from "src/features/planning/utils.js";
import type {
	FetchBaseQueryError,
	FetchBaseQueryMeta,
	QueryReturnValue,
} from "@reduxjs/toolkit/query";

export const buildIncomingBalanceEndpoints = (builder: Builder) => ({
	getIncomingBalances: builder.query<
		IncomingBalance[],
		{
			companyDomainId: string;
			legalEntityId: string;
			date: Date;
		}
	>({
		queryFn(
			{ companyDomainId, legalEntityId, date },
			_api,
			_extraOptions,
			baseQuery,
		) {
			return loadAllPages(({ take, skip }) => {
				return baseQuery({
					url: `/v1/company-domain/${companyDomainId}/legal-entity/${legalEntityId}/incoming-balance`,
					params: {
						date: formatYYYYMMDD(date),
						take,
						skip,
					},
				}) as Promise<
					QueryReturnValue<
						PaginatedResponse<IncomingBalance>,
						FetchBaseQueryError,
						FetchBaseQueryMeta
					>
				>;
			});
		},
	}),
});
