import { useAuth0 } from "@auth0/auth0-react";
import {
	createContext,
	type ReactNode,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { setAccessToken } from "../redux/slices/accessTokenSlice";
import { LoadingState } from "../components/atoms/loadingState/LoadingState";
import { auth0Audience } from "../../env";

type TokenContextType =
	| {
			accessToken: string;
			refreshAccessToken: () => void;
	  }
	| undefined;

export const TokenContext = createContext<TokenContextType>(undefined);
TokenContext.displayName = "TokenContext";

function useProvideToken() {
	const { getAccessTokenSilently, isLoading } = useAuth0();
	const [counter, setCounter] = useState(0);
	const dispatch = useAppDispatch();

	useEffect(() => {
		let didCancel = false;
		const fetchToken = async () => {
			if (!isLoading) {
				const accessToken = await getAccessTokenSilently({
					authorizationParams: {
						audience: auth0Audience,
					},
				});
				if (!didCancel) {
					dispatch(setAccessToken(accessToken));
				}
			}
		};
		void fetchToken();
		return () => {
			didCancel = true;
		};
	}, [getAccessTokenSilently, isLoading, counter, dispatch]);

	return useMemo(() => {
		function refreshAccessToken() {
			setCounter(counter + 1);
		}
		return {
			refreshAccessToken,
		};
	}, [counter]);
}

interface ProvideAccessTokenProps {
	children?: ReactNode | undefined;
}

export function ProvideAccessToken({ children }: ProvideAccessTokenProps) {
	const { refreshAccessToken } = useProvideToken();
	const accessToken = useAppSelector((state) => state.accessToken.accessToken);

	if (accessToken === null) {
		return <LoadingState />;
	}

	return (
		<TokenContext.Provider value={{ accessToken, refreshAccessToken }}>
			{children}
		</TokenContext.Provider>
	);
}
