import { useTranslation } from "react-i18next";
import { type UseFormReturn, Controller } from "react-hook-form";
import { Button } from "components/button";
import { FormSectionContainer } from "src/features/planning/components/formSection/FormSectionContainer";
import { IconArrowRight } from "@tabler/icons-react";
import { CheckboxField } from "components/checkbox/CheckboxField";
import { ValidateHint } from "../../planning/components/ValidateHint";
import { Card } from "src/features/planning/components/Card.js";

interface Props {
	form: UseFormReturn<any>;
	disableOnBack?: boolean;
	onBack?: () => void;
	disableOnNextStep?: boolean;
	onNextStep?: () => void;
	disableOnSubmit?: boolean;
	onSubmit?: boolean;
	children?: React.ReactNode | undefined;
}

export const AddDataSourceNavigation = ({
	form,
	disableOnBack,
	onBack,
	disableOnNextStep,
	onNextStep,
	disableOnSubmit,
	onSubmit,
	children,
}: Props) => {
	const { t } = useTranslation();

	return (
		<>
			<Card className="p-6">
				<FormSectionContainer>{children}</FormSectionContainer>
			</Card>
			<div className="mt-4 flex flex-col items-end gap-2">
				{onSubmit && (
					<Controller
						control={form.control}
						name="approve"
						rules={{ required: true }}
						render={({ field: { value, onChange, ...field }, fieldState }) => {
							return (
								<div>
									<CheckboxField
										disabled={form.formState.isSubmitting}
										onChange={(event) => {
											onChange(event.target.checked);
										}}
										{...field}
										checked={value}
										label={t("Allow Exopen to fetch my data")}
									/>
									{fieldState.error !== undefined && (
										<ValidateHint error>
											{t("Must be approved before data can be collected")}
										</ValidateHint>
									)}
								</div>
							);
						}}
					/>
				)}
				<div className="flex justify-end gap-4">
					{onBack && (
						<Button
							disabled={disableOnBack}
							onClick={onBack}
							variant="secondaryGray"
							type="button"
							ariaLabel="back"
						>
							{t("Back")}
						</Button>
					)}
					{onNextStep && (
						<Button
							variant="primary"
							type="button"
							disabled={disableOnNextStep}
							onClick={onNextStep}
							icon={<IconArrowRight />}
							iconPosition="right"
							ariaLabel="next-step"
						>
							{t("Next step")}
						</Button>
					)}
					{onSubmit && (
						<Button
							variant="primary"
							type="submit"
							isLoading={form.formState.isSubmitting}
							disabled={disableOnSubmit}
							ariaLabel="submit-form"
						>
							{t("Confirm")}
						</Button>
					)}
				</div>
			</div>
		</>
	);
};
