import { useAuth0 } from "@auth0/auth0-react";
import { IconLogout } from "@tabler/icons-react";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";
import { Avatar } from "../../common/components/atoms/avatar/Avatar";
import { Text } from "../../common/components/atoms/typography/Text";
import type { User } from "../../common/service/Types/UserTypes";
import { SwitchLanguage } from "./SwitchLanguage.js";
import { getGravatarUrl } from "../planning/utils/getGravatarUrl.js";
import { PopoverTrigger } from "components/popoverMenu/PopoverTrigger.js";
import { PopoverContent } from "components/popoverMenu/PopoverContent.js";
import { PopoverMenu } from "components/popoverMenu/menu/PopoverMenu.js";
import { PopoverMenuItem } from "components/popoverMenu/menu/PopoverMenuItem.js";
import { Popover } from "components/popoverMenu/Popover.js";
import { TooltipTrigger } from "components/tooltip/TooltipTrigger.js";
import { TooltipContent } from "components/tooltip/TooltipContent.js";
import { Tooltip } from "components/tooltip/Tooltip.js";

interface SidebarUserSectionProps {
	sidebarOpen: boolean;
	user: User;
}

export const SidebarUserSection = ({
	sidebarOpen,
	user,
}: SidebarUserSectionProps) => {
	const { t } = useTranslation();
	const { logout } = useAuth0();

	const avatar = (
		<Avatar
			src={getGravatarUrl(user.email)}
			size="md"
			alt=""
			className="mr-1 align-middle"
		/>
	);

	return (
		<div className={clsx("flex items-center gap-2", sidebarOpen && "pl-2")}>
			<div>
				<Popover role="menu" placement="right-end">
					<PopoverTrigger>{avatar}</PopoverTrigger>
					<PopoverContent>
						<PopoverMenu>
							<PopoverMenuItem
								label={t("Sign out")}
								icon={<IconLogout />}
								onClick={() => {
									void logout({
										logoutParams: { returnTo: window.location.origin },
									});
								}}
							/>
						</PopoverMenu>
					</PopoverContent>
				</Popover>
			</div>
			{sidebarOpen && (
				<>
					<div className="grow overflow-hidden">
						<Text
							size="sm"
							weight="medium"
							color="text-grey-700"
							className="truncate"
						>
							{user.name}
						</Text>
						<Text
							size="xs"
							weight="regular"
							color="text-grey-500"
							className="truncate"
						>
							{user.email}
						</Text>
					</div>
					<div className="flex gap-2">
						<SwitchLanguage />
						<Tooltip>
							<TooltipTrigger asChild>
								<button
									aria-label={t("Sign out")}
									className="text-grey-500"
									type="button"
									onClick={() => {
										void logout({
											logoutParams: { returnTo: window.location.origin },
										});
									}}
								>
									<IconLogout size={20} />
								</button>
							</TooltipTrigger>
							<TooltipContent>{t("Sign out")}</TooltipContent>
						</Tooltip>
					</div>
				</>
			)}
		</div>
	);
};
