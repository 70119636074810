import { Text } from "components/typography/Text.js";
import type { ReactNode } from "react";
import { useDialogContext } from "./useDialogContext";

interface DialogTitleProps {
	children?: ReactNode | undefined;
	sideContent?: ReactNode;
}

export const DialogTitle = ({ children, sideContent }: DialogTitleProps) => {
	const { labelId } = useDialogContext();
	return (
		<div className="flex p-6 pb-3">
			<Text size="lg" weight="bold" component="h2" id={labelId}>
				{children}
			</Text>
			<div className="grow" />
			<div>{sideContent}</div>
		</div>
	);
};
