import type {
	LegalEntity,
	LegalEntityHierarchy,
} from "src/common/service/nexus/endpoints/companyDomain/types.js";

export const getLegalEntityInHierarchy = (
	legalEntity: LegalEntity,
	hierarchy: LegalEntityHierarchy,
): LegalEntityHierarchy | null => {
	if (hierarchy.legalEntity.id === legalEntity.id) {
		return hierarchy;
	}

	for (const child of hierarchy.children) {
		const legalEntityInChild = getLegalEntityInHierarchy(legalEntity, child);
		if (legalEntityInChild) {
			return legalEntityInChild;
		}
	}

	return null;
};
