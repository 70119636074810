import { useTranslation } from "react-i18next";
import { Button } from "../../common/components/atoms/button/Button";
import type { CompanyInfo } from "../../common/service/companies";
import type { EwrPackageInfo } from "../../common/service/ewr";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../common/components/atoms/notifications/events";
import { IconChevronRight } from "@tabler/icons-react";
import { Card } from "../planning/components/Card";
import { Text } from "../../common/components/atoms/typography/Text";
import { useDeployEwrPackageMutation } from "src/common/redux/api/exopenApi";

type EwrPackageCardProps = {
	ewrPackage: EwrPackageInfo;
	companyInfo: CompanyInfo;
};

export default function EwrPackageCard({
	ewrPackage,
	companyInfo,
}: EwrPackageCardProps) {
	const { t } = useTranslation();
	const [deployEwrPackage, { isLoading: isDeploying }] =
		useDeployEwrPackageMutation();

	const deploy = async (pId: string) => {
		const result = await deployEwrPackage({
			companyId: companyInfo.company.id,
			packageId: pId,
		});
		if ("error" in result) {
			showErrorNotification({
				message: t("Report package not activated,"),
			});
		} else {
			showSuccessNotification({
				message: `${t("Report package")} ${pId} ${t("activated")}`,
			});
		}
	};

	return (
		<Card className="flex flex-col p-4">
			<div className="mb-4 grow">
				<Text size="md" weight="medium" className="mb-2">
					{ewrPackage.package.name}
				</Text>
				<Text size="sm">
					{ewrPackage.package.numReports} {t("reports")}
				</Text>
			</div>
			<div>
				{ewrPackage.deployed ? (
					<Button disabled>{t("Activated")}</Button>
				) : (
					<Button
						isLoading={isDeploying}
						icon={<IconChevronRight />}
						iconPosition="right"
						onClick={() => {
							return deploy(ewrPackage.package.id);
						}}
					>
						{t("Activate")}
					</Button>
				)}
			</div>
		</Card>
	);
}
