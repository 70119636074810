import type {
	FetchBaseQueryError,
	FetchBaseQueryMeta,
	QueryReturnValue,
} from "@reduxjs/toolkit/query";
import type {
	OffsetBasedPaginatedResponse,
	OffsetBasedPaginationParams,
} from "node_modules/@exopengithub/planning-api-shared/build/src/utils/pagination";

export const loadAllPages = async <T>(
	loadPage: ({
		page,
		pageSize,
	}: OffsetBasedPaginationParams) => Promise<
		QueryReturnValue<
			OffsetBasedPaginatedResponse<T>,
			FetchBaseQueryError,
			FetchBaseQueryMeta
		>
	>,
) => {
	const pageSize = 10 * 1000;
	let hasMore = true;
	let page = 0;
	const allItems: T[] = [];

	while (hasMore) {
		page = page + 1;
		const result = await loadPage({ page, pageSize });

		if (result.error) {
			return result;
		}

		const loadedPage = result.data;
		allItems.push(...loadedPage.items);
		hasMore =
			page * pageSize < loadedPage.total && Boolean(loadedPage.items.length);
	}

	return {
		data: allItems,
	};
};
