type ValidKeyType = string | number | bigint;
export type OnlyWithKeysToKeys<T> = {
	[K in keyof T as T[K] extends ValidKeyType ? K : never]: T[K];
};

/**
 * Converts an array of objects to a record object with the specified key as the record key.
 * @param arr The array of objects to convert to a record.
 * @param key The key to use as the record key. Defaults to "id".
 * @returns A record object with the specified key as the record key.
 */
export const arrayToRecordByKey = <
	T,
	Key extends
		keyof OnlyWithKeysToKeys<T> = "id" extends keyof OnlyWithKeysToKeys<T>
		? "id"
		: never,
>(
	arr: T[],
	key: Key = "id" as Key,
): Key extends keyof T ? Record<string, T> : never =>
	arr.reduce<Record<string, T>>(
		(acc, entry) => {
			acc[`${entry[key as keyof T]}`] = entry;
			return acc;
		},
		{} as Record<string, T>,
	) as any;
