import { IconAlertCircle, IconCircleCheck } from "@tabler/icons-react";
import { createUseExternalEvents } from "./utils/createUseExternalEvents";
import type { NotificationProps } from "./notificationTypes";

type NotificationsEvents = {
	show(notification: NotificationProps): void;
	hide(id: string): void;
	update(notification: NotificationProps & { id: string }): void;
	clean(): void;
	cleanQueue(): void;
};

const [useNotificationsEvents, createEvent] =
	createUseExternalEvents<NotificationsEvents>("exopen-notifications");

export { useNotificationsEvents };

const showNotification = createEvent("show");

export const showSuccessNotification = (
	props: Omit<NotificationProps, "icon" | "iconColor">,
) => {
	showNotification({
		...props,
		icon: <IconCircleCheck />,
		iconColor: "primary",
	});
};

export const showErrorNotification = (
	props: Omit<NotificationProps, "icon" | "iconColor">,
) => {
	showNotification({
		...props,
		icon: <IconAlertCircle />,
		iconColor: "error",
	});
};
