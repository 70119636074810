import { clsx } from "clsx";
import { forwardRef, type LabelHTMLAttributes, type ReactNode } from "react";
import { Text } from "../typography/Text";
import { Checkbox, type CheckboxProps } from "./Checkbox";

interface CheckboxFieldProps extends Omit<CheckboxProps, "children"> {
	label?: ReactNode | string;
	labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
	supportingText?: string;
}

export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>(
	function CheckboxField({ label, labelProps, supportingText, ...props }, ref) {
		const { className: labelClassName, ...extraLabelProps } = labelProps ?? {};
		const { className, ...restProps } = props;

		const labelElement = (
			<label
				className={clsx(
					"inline-flex cursor-pointer items-center",
					labelClassName,
				)}
				{...extraLabelProps}
			>
				<Checkbox
					className={clsx("mr-2 shrink-0", className)}
					{...restProps}
					ref={ref}
				/>
				{typeof label === "string" ? (
					<Text span size="sm" weight="medium" color="text-grey-700">
						{label}
					</Text>
				) : (
					label
				)}
			</label>
		);

		return supportingText ? (
			<div>
				{labelElement}
				{supportingText && (
					<Text
						size="sm"
						weight="regular"
						color="text-grey-500"
						className="flex items-center pl-6"
					>
						{supportingText}
					</Text>
				)}
			</div>
		) : (
			labelElement
		);
	},
);
