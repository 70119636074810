import { useTranslation } from "react-i18next";
import { invariant } from "../../../../common/utils/invariant";
import {
	showErrorNotification,
	showSuccessNotification,
} from "../../../../common/components/atoms/notifications/events";
import type { DomainPlan } from "@exopengithub/planning-api-shared";
import { useDeletePlanMutation } from "../../api/planApi.js";
import { ConfirmDialog } from "../../../../common/components/atoms/dialog/ConfirmDialog.js";

interface DeletePlanDialogProps {
	plan?: DomainPlan | undefined;
	onClose: () => void;
	open: boolean;
	onDelete?: (() => void) | undefined;
}

export const DeletePlanDialog = ({
	plan,
	open,
	onClose,
	onDelete,
}: DeletePlanDialogProps) => {
	const { t } = useTranslation();
	const [deletePlan, { isLoading: isDeleting }] = useDeletePlanMutation();

	const handleDelete = async () => {
		invariant(plan);
		const result = await deletePlan({
			planId: plan.id,
			companyDomainId: plan.companyId,
		});
		if ("error" in result) {
			showErrorNotification({ message: t("The plan couldn't be removed") });
		} else {
			if (onDelete) {
				onDelete();
			}
			onClose();
			showSuccessNotification({ message: t("The plan was removed") });
		}
	};

	return (
		<ConfirmDialog
			destructive
			open={open}
			onClose={onClose}
			onConfirm={handleDelete}
			isLoading={isDeleting}
			title={t('Delete plan "{{plan}}"', {
				plan: plan?.name,
			})}
			confirmButtonLabel={t("Remove")}
			description={t(
				"Are you sure you want to delete this plan? This action can not be undone.",
			)}
		/>
	);
};
