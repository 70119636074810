import { IconUserPlus } from "@tabler/icons-react";
import { Button } from "../../../../common/components/atoms/button/Button.tsx";
import { Dialog } from "../../../../common/components/atoms/dialog/Dialog.tsx";
import { DialogActions } from "../../../../common/components/atoms/dialog/DialogActions.tsx";
import { DialogBody } from "../../../../common/components/atoms/dialog/DialogBody.tsx";
import { DialogContent } from "../../../../common/components/atoms/dialog/DialogContent.tsx";
import { DialogTitle } from "../../../../common/components/atoms/dialog/DialogTitle.tsx";
import { DialogTrigger } from "../../../../common/components/atoms/dialog/DialogTrigger.tsx";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useEffect, useId } from "react";
import type { AddUserToPlanBody } from "@exopengithub/planning-api-shared";
import { AddUserToPlanForm } from "./AddUserToPlanForm.tsx";
import type { CostCenterPermissionsFormValues } from "./CostCenterPermissions.tsx";

export interface PlanUserFormValues extends CostCenterPermissionsFormValues {
	user: string | null;
	permissions: AddUserToPlanBody["permissions"];
}

const makeDefaultValues = (): PlanUserFormValues => {
	return {
		user: null,
		permissions: [],
		allCostCenters: true,
		selectedCostCenters: [],
	};
};

export const AddUserToPlanDialog = ({
	users,
	permissions,
}: {
	users: {
		name: string;
		email: string;
	}[];
	permissions: {
		label: string;
		value: AddUserToPlanBody["permissions"][number];
	}[];
}) => {
	const { t } = useTranslation();
	const form = useForm({
		defaultValues: makeDefaultValues(),
	});
	const formId = useId();

	const { reset } = form;
	useEffect(() => {
		if (form.formState.isSubmitSuccessful) {
			reset();
		}
	}, [form.formState.isSubmitSuccessful, reset]);

	return (
		<Dialog
			onClose={() => {
				reset();
			}}
		>
			<DialogTrigger asChild>
				<Button icon={<IconUserPlus />}>{t("Add people")}</Button>
			</DialogTrigger>
			<DialogContent>
				{({ close }) => {
					return (
						<>
							<DialogTitle>{t("Add people to plan")}</DialogTitle>
							<DialogBody>
								<AddUserToPlanForm
									onSuccessfulSubmit={close}
									users={users}
									permissions={permissions}
									formId={formId}
									form={form}
								/>
							</DialogBody>
							<DialogActions withCancelButton>
								<Button
									variant="primary"
									type="submit"
									form={formId}
									isLoading={form.formState.isSubmitting}
									disabled={!form.formState.isDirty}
								>
									{t("Add user")}
								</Button>
							</DialogActions>
						</>
					);
				}}
			</DialogContent>
		</Dialog>
	);
};
