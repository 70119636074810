import { Text } from "../../../common/components/atoms/typography/Text";
import type { ReactNode } from "react";
import type { TFunction } from "i18next";
import { Divider } from "../../../common/components/atoms/divider/Divider.tsx";

interface ChangeLogItem {
	id: string;
	title: (options: { t: TFunction }) => string;
	date: Date;
	content: (options: { t: TFunction }) => ReactNode;
	affectsPlanCalculations?: true;
}

/** The order matters, add new change log items to top of this array */
export const changeLogItems: ChangeLogItem[] = [
	{
		id: "61d7f8da-0c34-4940-a3c0-89148a7793a3",
		title: ({ t }) => t("New input mode for account and driver assumptions"),
		date: new Date("2024-07-04"),
		content: ({ t }) => {
			return (
				<>
					<Text size="sm" component="p" className="mb-4">
						{t(
							"The account and driver assumption inputs have had a major overhaul. The new input mode is designed to make it easier to work with assumptions and to provide a better overview of the data.",
						)}
					</Text>
					<Text size="sm" component="p" className="mb-4">
						{t(
							"The input is now only based on periods of the plan, and you enter amount or quantity per month:",
						)}
					</Text>
					<div className="flex items-center justify-center">
						<img
							src="/images/release_driver_account_input.png"
							alt={t("New input mode for account and driver assumptions")}
							className="my-4 max-h-[400px] object-contain"
						/>
					</div>
					<Text size="sm" component="p" className="mb-4 italic">
						{t(
							"Click the arrow (or use SHIFT + ENTER) to fill the remaining periods automatically.",
						)}
					</Text>
					<Text size="sm" component="p" className="mb-4">
						{t(
							"As of now options for daily, weekly or quarterly inputs and the ability to use allocation keys is removed, please contact our support if you rely on this features.",
						)}
					</Text>
				</>
			);
		},
	},
	{
		id: "d49a4c65-591e-404c-9ac6-230446e96deb",
		title: ({ t }) => t("Driver connections and advanced offsets"),
		date: new Date("2024-05-22"),
		content: ({ t }) => {
			return (
				<>
					<Text size="sm" component="p" className="mb-4">
						{t(
							"Driver connections is a new feature that allows you to connect drivers to each other. This enables the creation of more advanced driver structures, such as structured articles.",
						)}
					</Text>
					<Text size="sm" component="p" className="mb-4">
						{t(
							"You can also use driver connections to model your business processes and tasks that lead to transactions. For instance, you can design a sales flow where an advertising cost driver triggers an effect on sales with varying percentages.",
						)}
					</Text>
					<div className="flex items-center justify-center">
						<img
							src="/images/release_driver_connections_offsets_1.png"
							alt={t("Example of business process driver")}
							className="my-4 max-h-[400px] object-contain"
						/>
					</div>
					<Text size="sm" component="p" className="mb-4">
						{t(
							"Advanced offsets is a new way to specify when account recipes and driver connections should be triggered. You can now offset your transactions by various time units such as weeks, months, and years, in addition to days.",
						)}
					</Text>
					<Text size="sm" component="p" className="mb-4">
						{t(
							"Additionally, we have introduced a new offset feature. This allows you to set your transactions to occur on a specific day of the next given month.",
						)}
					</Text>
					<div className="flex items-center justify-center">
						<img
							src="/images/release_driver_connections_offsets_2.png"
							alt={t("Example of advanced offset")}
							className="my-4 max-w-[600px] object-contain"
						/>
					</div>
					<Text size="sm" component="p" className="mb-4">
						{t(
							"Moreover, the driver edit form now includes an option to preview the transactions generated by the driver. This feature helps verify that your driver is set up correctly.",
						)}
					</Text>
					<div className="flex items-center justify-center">
						<img
							src="/images/release_driver_connections_offsets_3.png"
							alt={t("Example of driver transaction preview")}
							className="my-4 max-h-[400px] object-contain"
						/>
					</div>
				</>
			);
		},
	},
	{
		id: "35e339d0-9f95-41b3-8151-afc351f91cd8",
		title: ({ t }) => t("Account recipe changes"),
		date: new Date("2024-04-30"),
		content: ({ t }) => {
			return (
				<>
					<Text size="sm" component="p" className="mb-4">
						{t(
							"We have made changes to the structure and usage of account recipes. This is introduced to make account recipes more flexible and easier to reuse across different modules and inputs.",
						)}
					</Text>
					<Text size="sm" component="p">
						{t(
							"The major change is that account recipes no longer sets the days offset for the transaction, this instead moved to the settings of your module. This will, for example,  make it possible to create drivers that have the same account reipes, but behave different:",
						)}
					</Text>
					<img
						src="/images/release_new_account_recipes.png"
						alt={t("Example of drivers with new account recipes")}
						className="my-4 object-contain"
					/>
					<Text size="sm" component="p">
						{t(
							"Your existing account recipes have been migrated to work with the new structure",
						)}
					</Text>
				</>
			);
		},
	},
	{
		id: "5555b395-e786-4a36-90fb-f792422718e4",
		title: ({ t }) => t("Plan users and permissions"),
		date: new Date("2024-03-20"),
		content: ({ t }) => {
			return (
				<>
					<Text size="sm" component="p">
						{t(
							"We've added a page that lists plan users. Here, you can get an overview of exactly who can access your plan, and you can invite other users within your company domain to plans, even if they are not planning admins. You can also grant specific permissions within the plan. Currently, we have a single permission that controls whether someone can see sensitive information about personnel, such as salary, etc. If this permission isn't enabled for a particular user, they won't have access to sensitive personnel information, which will be censored as much as possible.",
						)}
					</Text>

					<Divider className="my-6" />

					<Text size="sm" className="pb-2" component="p">
						{t("The users page lists all plan users.")}
					</Text>
					<img
						src="/images/release_permissions_1.png"
						alt={t("User page")}
						className="my-4 object-contain"
					/>
					<Text size="sm" className="pb-2" component="p">
						{t(
							"You select a user whom you wish to add to the plan and specify the permissions they should be granted. You can also modify the permissions of users after they have been added.",
						)}
					</Text>
					<img
						src="/images/release_permissions_2.png"
						alt={t("Add plan user dialog")}
						className="my-4 object-contain"
					/>

					<Text size="sm" className="pb-2" component="p">
						{t(
							"Users without permissions won't have access to any personnel details.",
						)}
					</Text>
					<img
						src="/images/release_permissions_3.png"
						alt={t("Personnel Assumptions without Permission.")}
						className="my-4 w-full object-contain"
					/>
				</>
			);
		},
	},
	{
		id: "88ca05fc-b4f1-4a3c-aa5e-19f50fadb54e",
		title: ({ t }) => t("Employee cost center split"),
		date: new Date("2024-03-01"),
		content: ({ t }) => (
			<Text size="sm">
				<p className="pb-2">
					{t(
						"It is now possible to split employee costs between different cost centers. This is done in the employee module assumptions table, where you can add a cost center split to each employee.",
					)}
				</p>
			</Text>
		),
	},
	{
		id: "af7fcb86-72ca-4093-892a-b071906a54aa",
		title: ({ t }) => t("Liquidity plan and account types"),
		date: new Date("2024-02-16"),
		content: ({ t }) => (
			<Text size="sm">
				<p className="pb-2">
					{t(
						"With the help of the liquidity plan, you get a clear picture of how the various parts of your plan will affect the cash register and can check the reasonableness of the assumptions you have made. All transactions affecting the cash register are grouped into a category and marked with which module created them.",
					)}
				</p>
				<img
					src="/images/release_liquidity_plan.png"
					alt={t("Liquidity plan")}
					className="my-4 max-h-[200px] w-full object-contain"
				/>
				<p className="pb-2">
					{t(
						"Which accounts are considered cash accounts are set in the account type settings found in Account under Data Perspective. There are several other account types that have different effects. The basic setting is according to the BAS account plan and if no changes are made, new accounts are mapped in according to BAS. This means that there is no need to make any changes here in most cases. These settings are global and affect all parts of Exopen's products.",
					)}
				</p>
				<img
					src="/images/release_account_type_editor.png"
					alt={t("Account")}
					className="my-4 max-h-[200px] w-full object-contain"
				/>
			</Text>
		),
	},
	{
		id: "02e0cbde-429a-4c02-820d-263d95f44f75",
		title: ({ t }) => t("New layout and updates in the employee module"),
		date: new Date("2024-01-16"),
		affectsPlanCalculations: true,
		content: ({ t }) => (
			<Text size="sm">
				<p className="pb-2">
					{t(
						"We have updated employer fees and taxes for 2024 in the employee module. The change includes the following:",
					)}
				</p>
				<ul className="list-disc pb-2 pl-8">
					<li className="my-1">{t("New breakpoints for state tax")}</li>
					<li className="my-1">
						{t(
							"Updated rules regarding social fees and employer contributions",
						)}
					</li>
				</ul>
				<p className="pb-2">
					{t(
						"In the plans, there are now two tabs, assumptions and definitions. In the assumptions tab, you will find the module functions that were previously in the plan, that is, the data input.",
					)}
				</p>
				<img
					src="/images/release_new_layout.png"
					alt="New layout"
					className="my-4 max-h-[200px] w-full object-contain"
				/>
				<p className="mb-8 pb-2">
					{t(
						"Under definitions, all settings and parameters of the modules are now collected, which were previously found under settings. This is to make it clearer what affects what in the plan, and to make it easier to work on your plans.",
					)}
				</p>
			</Text>
		),
	},
];
