import type * as React from "react";

export function mergeRefs<T = any>(
	refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>,
): React.RefCallback<T> {
	return (value) => {
		for (const ref of refs) {
			if (typeof ref === "function") {
				ref(value);
			} else if (ref !== null) {
				(ref as React.MutableRefObject<T | null>).current = value;
			}
		}
	};
}
