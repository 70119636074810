import { useContext } from "react";
import { LegalEntityModeContext } from "./LegalEntityModeContext";

export const useLegalEntityMode = () => {
	const context = useContext(LegalEntityModeContext);
	if (!context) {
		throw new Error(
			"could not find legal entity mode context value; please ensure the component is wrapped in a <ProvideLegalEntityMode>",
		);
	}
	return context;
};
