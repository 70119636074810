import type { ReactNode } from "react";
import { FormSectionContext } from "./FormSectionContext.js";

export const FormSectionContainer = ({ children }: { children: ReactNode }) => {
	return (
		<FormSectionContext.Provider value>
			<div className="@container">{children}</div>
		</FormSectionContext.Provider>
	);
};
