import type {
	LegalEntityHierarchy,
	LegalEntity,
} from "src/common/service/nexus/endpoints/companyDomain/types.js";

export const extractLegalEntitiesFromHierarchy = (
	hierarchy: LegalEntityHierarchy,
): LegalEntity[] => {
	const legalEntities: LegalEntity[] = [
		hierarchy.legalEntity,
		...hierarchy.children.flatMap((child) =>
			extractLegalEntitiesFromHierarchy(child),
		),
	];

	return legalEntities;
};
