import { createContext } from "react";
import type { Transaction } from "../../types.js";

type PlanContextValue =
	| {
			onRecalculateChange: (changes: Transaction[]) => void;
			currentAccountIds: Set<string>;
			readOnly: boolean;
	  }
	| undefined;

export const PlanContext = createContext<PlanContextValue>(undefined);
