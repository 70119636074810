import {
	useMergeRefs,
	FloatingPortal,
	useTransitionStyles,
	FloatingArrow,
} from "@floating-ui/react";
import {
	forwardRef,
	type ComponentPropsWithoutRef,
	type ReactNode,
} from "react";
import { clsx } from "clsx";
import { useTooltipContext } from "./TooltipContext.js";
import { ARROW_HEIGHT } from "./tooltipConstants.js";
import { zIndicies } from "src/common/utils/zIndicies.js";
import type { Except } from "type-fest";

const ARROW_WIDTH = 12;

type TooltipContentProps = Except<
	ComponentPropsWithoutRef<"div">,
	"children"
> & {
	arrowClassName?: string;
	children: ReactNode | ((options: { close: () => void }) => JSX.Element);
};

export const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>(
	function TooltipContent(
		{ style, className, arrowClassName, children, ...props },
		propRef,
	) {
		const {
			context: floatingContext,
			arrowRef,
			middlewareData,
			refs,
			floatingStyles,
			getFloatingProps,
			setOpen,
		} = useTooltipContext();
		const ref = useMergeRefs([refs.setFloating, propRef]);

		const arrowX = middlewareData.arrow?.x ?? 0;
		const arrowY = middlewareData.arrow?.y ?? 0;
		const transformX = arrowX + ARROW_WIDTH / 2;
		const transformY = arrowY + ARROW_HEIGHT;

		const { styles: transitionStyles, isMounted } = useTransitionStyles(
			floatingContext,
			{
				duration: 100,
				initial: {
					opacity: 0,
					transform: "scale(0.95)",
				},
				common: ({ side }) => ({
					transformOrigin: {
						top: `${transformX}px calc(100% + ${ARROW_HEIGHT}px)`,
						bottom: `${transformX}px ${-ARROW_HEIGHT}px`,
						left: `calc(100% + ${ARROW_HEIGHT}px) ${transformY}px`,
						right: `${-ARROW_HEIGHT}px ${transformY}px`,
					}[side],
				}),
			},
		);

		if (!isMounted) {
			return null;
		}

		const { className: floatingClassName, ...floatingProps } = getFloatingProps(
			{
				...props,
			},
		);

		return (
			<FloatingPortal>
				<div
					ref={ref}
					style={{ ...floatingStyles, ...style }}
					className={clsx(
						floatingClassName as string | undefined,
						zIndicies.TOOLTIP,
					)}
					{...floatingProps}
				>
					<div
						style={transitionStyles}
						className={clsx(
							"rounded-md bg-gray-900 px-3 py-2 text-sm text-white outline-0",
							className,
						)}
					>
						{typeof children === "function"
							? children({
									close: () => {
										setOpen(false);
									},
								})
							: children}
						<FloatingArrow
							ref={arrowRef}
							context={floatingContext}
							width={ARROW_WIDTH}
							height={ARROW_HEIGHT}
							className={clsx("fill-gray-900", arrowClassName)}
						/>
					</div>
				</div>
			</FloatingPortal>
		);
	},
);
