import { Temporal } from "@js-temporal/polyfill";

export const dateToPlainYearMonth = (date: Date | string) => {
	const d: Date = date instanceof Date ? date : new Date(date);
	return Temporal.PlainYearMonth.from(
		`${d.getFullYear()}-${`${d.getMonth() + 1}`.padStart(2, "0")}`,
	);
};

export const dateToPlainDate = (date: Date | string) => {
	const d: Date = date instanceof Date ? date : new Date(date);
	const instant = d.toTemporalInstant();
	const zonedInstant = instant.toZonedDateTimeISO(Temporal.Now.timeZoneId());

	return zonedInstant.toPlainDate();
};
