import FortnoxLogo from "./logos/fortnox.png";
import VismaLogo from "./logos/visma.png";
import TfsOfficeLogo from "./logos/tfsoffice.png";
import XledgerLogo from "./logos/xledger.png";
import BusinessCentralLogo from "./logos/business-central.png";
import MergeDev from "./logos/mergecrm.jpeg";
import NextTech from "./logos/next-tech.png";
import Xero from "./logos/xero.png";
import Sie from "./../../sie/sie-logo.jpg";
import EDM from "./logos/edm.png";
import PeAccounting from "./logos/peaccounting.png";
import KlovernFsab from "./logos/klovern_fsab.png";
import Skatteverket from "./logos/skatteverket.png";
import { Text } from "../../../../common/components/atoms/typography/Text";
import { getSystemDisplayName } from "src/features/connector/connectors/ConnectorsTable/getSystemDisplayName";
import VitecLogoFull from "../../addConnector/vitec/vitec-logo-full.svg";

type Props = {
	sourceSystemName: string;
};

export const SourceSystemImage = ({ sourceSystemName }: Props) => {
	switch (sourceSystemName.toLowerCase()) {
		case "businesscentral":
			return (
				<img
					src={BusinessCentralLogo}
					alt="Dynamics 365 Business Central logo"
					width="90"
				/>
			);
		case "fortnox":
			return <img src={FortnoxLogo} alt="Fortnox logo" width="90" />;
		case "vismanet":
			return <img src={VismaLogo} alt="Visma logo" width="90" />;
		case "tfsoffice":
			return <img src={TfsOfficeLogo} alt="24SevenOffice logo" width="90" />;
		case "xledger":
			return <img src={XledgerLogo} alt="Xledger logo" width="90" />;
		case "mergecrm":
		case "merge_accounting":
			return <img src={MergeDev} alt="Merge logo" width="90" />;
		case "vitec_cloud":
			return <img src={VitecLogoFull} alt="Vitec logo" width="90" />;
		case "xero":
			return <img src={Xero} alt="Xero logo" width="75" />;
		case "sie":
			return <img src={Sie} alt="SIE logo" width="49" height="28" />;
		case "peaccounting":
		case "pe_accounting":
			return <img src={PeAccounting} alt="PeAccounting logo" width="90" />;
		case "klovern":
			return <img src={KlovernFsab} alt="FSAB logo" width="90" />;
		case "next_tech":
			return <img src={NextTech} alt="Next Tech logo" width="90" />;
		case "exopendatamodelapi":
			return <img src={EDM} alt="Exopen logo" width="90" />;
		case "skatteverket":
			return <img src={Skatteverket} alt="Exopen logo" width="90" />;
		default:
			return (
				<Text size="sm" weight="regular" color="text-grey-500">
					{getSystemDisplayName(sourceSystemName)}
				</Text>
			);
	}
};
