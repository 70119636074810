import { useTranslation } from "react-i18next";
import {
	useGetVismaNetBranchQuery,
	useGetVismaNetDimensionsQuery,
} from "../../../../common/redux/api/exopenApi";
import { type UseFormReturn, Controller } from "react-hook-form";
import { Input } from "components/input/Input";
import { Label } from "components/label/Label";
import { Select } from "components/select/Select";
import { FormSection } from "src/features/planning/components/formSection/FormSection";
import { TooltipTrigger } from "components/tooltip/TooltipTrigger";
import { Tooltip } from "components/tooltip/Tooltip";
import { TooltipContent } from "components/tooltip/TooltipContent";
import findTenantIdGif from "./find-tenant-id.gif";

interface Props {
	form: UseFormReturn<any>;
}

export const VismaNetDimensionSection = ({ form }: Props) => {
	const { t } = useTranslation();
	const tenantId = form.watch("tenantId");

	const { data: branch } = useGetVismaNetBranchQuery(
		{
			tenantId,
		},
		{
			// Skip the query if the tenant id is not valid guid or empty
			skip:
				!tenantId ||
				!/^[a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12}$/i.test(tenantId),
		},
	);

	const { data: dimensions } = useGetVismaNetDimensionsQuery({
		tenantId,
	});

	return (
		<>
			<FormSection
				title={t("Tenant")}
				description={t(
					"Enter the tenant id of the VismaNet company that is being onboarded. It can be found in the Visma App Store.",
				)}
			>
				<div className="mb-4">
					<Label htmlFor="tenant-id">
						{t("Tenant id")}{" "}
						<Tooltip>
							<TooltipTrigger>
								<div className="text-purple-900">
									{t("(Show me where to find it)")}
								</div>
							</TooltipTrigger>
							<TooltipContent>
								<img src={findTenantIdGif} alt="find-tenant-id" width="860" />
							</TooltipContent>
						</Tooltip>
					</Label>
					<Input
						{...form.register("tenantId", {
							required: t("This field is required"),
						})}
						placeholder={t("Enter tenant id")}
						aria-invalid={form.formState.errors.tenantId !== undefined}
						ariaLabel="Tenant id input"
						outsideRight={tenantId ? branch?.name : undefined}
					/>
				</div>
			</FormSection>
			<FormSection
				title={t("Dimensions")}
				description={t(
					"Map the VismaNet dimensions to the corresponding Exopen dimensions, cost center and project. The mapping is optional but certain features require the mapping to be set up.",
				)}
			>
				<div className="mb-4 w-min">
					<Label>{t("Cost center dimension")}</Label>
					<Controller
						control={form.control}
						name="costCenterId"
						render={({ field: { onChange, ...rhfField }, fieldState }) => {
							return (
								<Select
									className="mb-2"
									withFilter
									clearable
									ariaLabel="Cost center selection"
									placeholder={t("Select a VismaNet dimension")}
									{...rhfField}
									onChange={(value) => {
										onChange(value);
									}}
									options={
										dimensions
											? dimensions.map((dimension) => {
													return {
														value: dimension.id,
														label: dimension.name,
													};
												})
											: []
									}
									disabled={!dimensions}
									ariaInvalid={fieldState.error !== undefined}
								/>
							);
						}}
					/>
				</div>
				<div className="mb-4 w-min">
					<Label>{t("Project dimension")}</Label>
					<Controller
						control={form.control}
						name="projectId"
						render={({ field: { onChange, ...rhfField }, fieldState }) => {
							return (
								<Select
									className="mb-2"
									withFilter
									clearable
									ariaLabel="Project selection"
									placeholder={t("Select a VismaNet dimension")}
									{...rhfField}
									onChange={(value) => {
										onChange(value);
									}}
									options={
										dimensions
											? dimensions.map((dimension) => {
													return {
														value: dimension.id,
														label: dimension.name,
													};
												})
											: []
									}
									disabled={!dimensions}
									ariaInvalid={fieldState.error !== undefined}
								/>
							);
						}}
					/>
				</div>
			</FormSection>
		</>
	);
};
