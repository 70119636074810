import { createApi } from "@reduxjs/toolkit/query/react";
import { nexusApiUrl } from "../../../env";
import { makeBaseQueryWithUnauthentication } from "../../redux/api/baseQuery";
import { TAGS } from "./types";

export const emptyNexusApi = createApi({
	reducerPath: "nexusApi",
	baseQuery: makeBaseQueryWithUnauthentication({
		baseUrl: nexusApiUrl,
		prepareHeaders: (headers) => {
			headers.set("x-api-version", "2.0.0");
		},
	}),
	tagTypes: TAGS,
	endpoints: () => ({}),
});
