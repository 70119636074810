import {
	IconAlertCircle,
	IconMail,
	type IconProps,
	IconSearch,
	IconX,
} from "@tabler/icons-react";
import { clsx } from "clsx";
import {
	cloneElement,
	type ComponentProps,
	forwardRef,
	type HTMLAttributes,
	type ReactElement,
	useRef,
	type ComponentPropsWithoutRef,
} from "react";
import { Text } from "../typography/Text";
import { ValidateHint } from "../../../../features/planning/components/ValidateHint";
import { COMMON_BORDERLESS_CLASS, COMMON_INPUT_CLASS } from "./inputUtils";
import { useTranslation } from "react-i18next";
import { mergeRefs } from "../../../utils/mergeRefs.ts";
import type { Except } from "type-fest";
import { Tooltip } from "../tooltip/Tooltip.tsx";
import { TooltipTrigger } from "../tooltip/TooltipTrigger.tsx";
import { TooltipContent } from "../tooltip/TooltipContent.tsx";

const INPUT_HEIGHT_CLASS = "h-[40px]";
const ICON_CONTAINER_CLASS =
	"absolute text-gray-500 h-full flex items-center px-3";

export interface InputProps
	extends Except<ComponentPropsWithoutRef<"input">, "aria-invalid"> {
	iconLeft?: ReactElement<IconProps> | undefined;
	iconRight?: ReactElement<IconProps> | undefined;
	hint?: ComponentProps<typeof ValidateHint>["children"];
	containerProps?: HTMLAttributes<HTMLDivElement>;
	outsideLeft?: string | undefined;
	outsideRight?: string | undefined;
	leftIconClassName?: string;
	rightIconClassName?: string;
	borderless?: boolean;
	hintAsPopup?: boolean;
	withInlineErrorIcon?: boolean;
	onDismissError?: () => void;
	ariaLabelledby?: string;
	ariaLabel?: string;
	"aria-invalid"?: boolean | undefined;
	clearable?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
	{
		className,
		type,
		iconLeft,
		iconRight,
		outsideLeft,
		outsideRight,
		"aria-invalid": ariaInvalid = false,
		ariaLabelledby,
		ariaLabel,
		hint,
		containerProps,
		leftIconClassName,
		rightIconClassName,
		borderless = false,
		hintAsPopup = false,
		onDismissError,
		withInlineErrorIcon = true,
		clearable = type === "search",
		value,
		...props
	},
	ref,
) {
	const { t } = useTranslation();
	const leftIcon =
		iconLeft ??
		(type === "search" ? (
			<IconSearch />
		) : type === "email" ? (
			<IconMail />
		) : null);

	const inputRef = useRef<HTMLInputElement | null>(null);
	const displayClearIcon =
		clearable && typeof value === "string" && value.length > 0;

	const clearButton = displayClearIcon ? (
		<button
			type="button"
			aria-label={t("Clear")}
			onClick={() => {
				if (inputRef.current) {
					const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
						window.HTMLInputElement.prototype,
						"value",
					)!.set!;
					nativeInputValueSetter.call(inputRef.current, "");
					inputRef.current.dispatchEvent(new Event("input", { bubbles: true }));
				}
			}}
			className="rounded-md p-1 hover:bg-purple-50"
		>
			<IconX
				size={18}
				className="shrink-0 text-purple-500 group-aria-invalid:text-red-500"
			/>
		</button>
	) : null;

	const rightIcon = ariaInvalid ? (
		iconRight ? (
			cloneElement(iconRight, { className: "text-error-500" })
		) : withInlineErrorIcon ? (
			<IconAlertCircle className="text-error-500" />
		) : null
	) : (
		iconRight
	);

	const outsideLeftElement = outsideLeft ? (
		<Text size="sm" weight="regular" color="text-grey-500">
			{outsideLeft}
		</Text>
	) : null;

	const outsideRightElement = outsideRight ? (
		typeof outsideRight === "string" ? (
			<Text size="sm" weight="regular" color="text-grey-500">
				{outsideRight}
			</Text>
		) : (
			outsideRight
		)
	) : null;

	const hintAsAPopup = borderless || hintAsPopup;

	const outsideAppendixClass =
		"border border-gray-300 py-2 px-4 flex items-center";

	const input = (
		<div
			className={clsx("flex items-center", !borderless && "shadow-sm")}
			data-exopen-input
		>
			{outsideLeftElement && (
				<div
					className={clsx(
						INPUT_HEIGHT_CLASS,
						outsideAppendixClass,
						"rounded-l-lg border-r-0",
					)}
				>
					{outsideLeftElement}
				</div>
			)}
			<div className="relative grow">
				{leftIcon && (
					<span className={clsx(ICON_CONTAINER_CLASS, leftIconClassName)}>
						{cloneElement<IconProps>(leftIcon, {
							size: 18,
							className: ariaInvalid ? "text-red-500" : "text-gray-700",
						})}
					</span>
				)}
				<input
					type={type}
					aria-invalid={ariaInvalid}
					aria-label={ariaLabel}
					aria-labelledby={ariaLabelledby}
					className={clsx(
						"w-full bg-white text-sm font-normal text-gray-900 outline-offset-0 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 aria-[invalid=true]:text-red-500 aria-[invalid=true]:placeholder-red-500 search-cancel:invisible",
						leftIcon ? "pl-10" : "pl-4",
						rightIcon ? "pr-10" : clearButton ? "pr-7" : "pr-4",
						borderless ? COMMON_BORDERLESS_CLASS : COMMON_INPUT_CLASS,
						INPUT_HEIGHT_CLASS,
						outsideLeftElement && "rounded-l-none",
						outsideRightElement && "rounded-r-none",
						className,
					)}
					ref={mergeRefs([inputRef, ref])}
					value={value}
					{...props}
				/>
				{clearButton ? (
					<span className="absolute right-0 top-0 flex h-full items-center px-2 text-gray-500">
						{clearButton}
					</span>
				) : (
					rightIcon && (
						<span
							className={clsx(
								ICON_CONTAINER_CLASS,
								"right-0 top-0",
								rightIconClassName,
							)}
						>
							{rightIcon}
						</span>
					)
				)}
			</div>
			{outsideRightElement && (
				<div
					className={clsx(
						INPUT_HEIGHT_CLASS,
						outsideAppendixClass,
						"rounded-r-lg border-l-0",
					)}
				>
					{outsideRightElement}
				</div>
			)}
		</div>
	);

	const persistedHint = useRef<
		ComponentProps<typeof ValidateHint>["children"] | null
	>(null);
	if (hint) {
		persistedHint.current = hint;
	}
	const tooltipContent = (
		<Text size="sm" color="text-white">
			{persistedHint.current}
		</Text>
	);

	return (
		<div {...containerProps}>
			{hintAsAPopup ? (
				<Tooltip open={ariaInvalid}>
					<TooltipTrigger asChild>{input}</TooltipTrigger>
					<TooltipContent
						className="cursor bg-red-500"
						arrowClassName="fill-red-500"
					>
						{onDismissError ? (
							<button
								type="button"
								aria-label={t("Dismiss error")}
								onClick={() => {
									onDismissError();
								}}
							>
								{tooltipContent}
							</button>
						) : (
							tooltipContent
						)}
					</TooltipContent>
				</Tooltip>
			) : (
				input
			)}
			{hint && !borderless && !hintAsPopup && (
				<ValidateHint className="inline-block" error={ariaInvalid}>
					{hint}
				</ValidateHint>
			)}
		</div>
	);
});
