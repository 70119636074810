import { clsx } from "clsx";
import type { ComponentPropsWithoutRef } from "react";
import { Text } from "../../../common/components/atoms/typography/Text";
import styles from "./ValidateHint.module.css";

interface ValidateHintProps extends ComponentPropsWithoutRef<"div"> {
	error?: boolean;
}

export const ValidateHint = ({
	error,
	children,
	className,
	...props
}: ValidateHintProps) => {
	return (
		<Text
			size="sm"
			weight="regular"
			color={error ? "text-error-500" : "text-grey-500"}
			className={clsx(styles.validateHint, className)}
			{...props}
		>
			{children}
		</Text>
	);
};
