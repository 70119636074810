import { serviceUrl } from "../../env";

export enum HttpStatusCode {
	BadRequest = 400,
	Unauthorized = 401,
	PaymentRequired = 402,
	Forbidden = 403,
	NotFound = 404,
	MethodNotAllowed = 405,
	NotAcceptable = 406,
	ProxyAuthenticationRequired = 407,
	RequestTimeout = 408,
	Conflict = 409,
	Gone = 410,
	LengthRequired = 411,
	PreconditionFailed = 412,
	RequestEntityTooLarge = 413,
	RequestURITooLong = 414,
	UnsupportedMediaType = 415,
	RequestedRangeNotSatisfiable = 416,
	ExpectationFailed = 417,
	InternalServerError = 500,
	NotImplemented = 501,
	BadGateway = 502,
	ServiceUnavailable = 503,
	GatewayTimeout = 504,
	HttpVersionNotSupported = 505,
}

class ResponseError extends Error {
	status: number;

	response: Response;

	constructor(status: number, message: string, response: Response) {
		super(message);
		this.status = status;
		this.response = response;
	}
}

export interface FetchRequestOptions extends RequestInit {
	body?: any;
}

interface RequestParams {
	url: string;
	token: string;
	options?: FetchRequestOptions;
	contentType?: string | null;
	refreshAccessToken?: () => void;
}

export const baseUrl = serviceUrl;

export async function fetchWithAuth({
	url,
	options,
	token,
	contentType = "application/json",
	refreshAccessToken,
}: RequestParams) {
	const headers = new Headers();
	const bearer = `Bearer ${token}`;

	headers.append("Authorization", bearer);
	if (contentType) headers.append("Content-Type", contentType);
	headers.append("Accept", "application/json");

	const requestOptions: RequestInit = {
		...options,
		method: options?.method ?? "GET",
		headers,
		cache: "default",
	};

	if (options?.body) {
		if (contentType === "application/json") {
			requestOptions.body = JSON.stringify(options.body);
		} else {
			requestOptions.body = options.body;
		}
	}

	const response = await fetch(url, requestOptions);

	if (refreshAccessToken && response.status === HttpStatusCode.Unauthorized) {
		refreshAccessToken();
	}

	if (!response.ok) {
		const body = await response.json();
		const errorMessage = body.usermessage || response.statusText;
		const errorStatus = body.usermessage || response.status;

		throw new ResponseError(errorStatus, errorMessage, response);
	}

	return response;
}
