import type { PlanningModule } from "./common/types.js";
import { DriverDrilldownDetails } from "./driver/components/DriverDrilldownDetails.js";
import { AccountTransactionDetailsComponent } from "./account/AccountTransactionDetailsComponent.js";
import { ModuleDrilldownDetails } from "./common/components/ModuleDrilldownDetails.js";
import type { DriverModuleTransactionMetaData } from "./driver/types.js";

type ModuleInfo<TMetaData = any> = Pick<
	PlanningModule<TMetaData>,
	"getTitle" | "refType" | "drilldown"
>;

export const driverModuleInfo: ModuleInfo<DriverModuleTransactionMetaData> = {
	getTitle: ({ t }) => t("Driver"),
	refType: "driver",
	drilldown: {
		TransactionDetailsComponent: DriverDrilldownDetails,
	},
};

export const consultancyModuleInfo: ModuleInfo = {
	getTitle: ({ t }) => t("Consultancy"),
	refType: "consultancy",
	drilldown: { TransactionDetailsComponent: ModuleDrilldownDetails },
};

export const accountModuleInfo: ModuleInfo = {
	refType: "account",
	getTitle: ({ t }) => t("Account"),
	drilldown: {
		TransactionDetailsComponent: AccountTransactionDetailsComponent,
	},
};

export const assetModuleInfo: ModuleInfo = {
	refType: "asset",
	getTitle: ({ t }) => t("Assets"),
	drilldown: { TransactionDetailsComponent: ModuleDrilldownDetails },
};

export const employeeModuleInfo: ModuleInfo = {
	refType: "employee_pp",
	getTitle: ({ t }) => t("Personnel"),
	drilldown: { TransactionDetailsComponent: ModuleDrilldownDetails },
};

export const financeModuleInfo: ModuleInfo = {
	refType: "finance",
	getTitle: ({ t }) => t("Finance"),
	drilldown: { TransactionDetailsComponent: ModuleDrilldownDetails },
};

export const PLANNING_MODULE_INFO: ModuleInfo[] = [
	driverModuleInfo,
	consultancyModuleInfo,
	accountModuleInfo,
	assetModuleInfo,
	employeeModuleInfo,
	financeModuleInfo,
];
