import { useLocation, useParams } from "react-router-dom";
import { invariant } from "../utils/invariant.js";
import { useLegalEntitiesOrThrow } from "./useLegalEntities.js";
import { usePersistedNavigate } from "../persistentRouterFunctions.js";
import { useCurrentCompanyDomain } from "../company-domain/useCurrentCompanyDomain.js";
import type { LegalEntityMode } from "../context/legalEntityMode.js";

export const useCurrentLegalEntity = () => {
	const { search } = useLocation();
	const { legalEntityId } = useParams();
	const { companyDomain } = useCurrentCompanyDomain();
	const navigate = usePersistedNavigate();

	const { legalEntities, getByLeMasterKey, getById } =
		useLegalEntitiesOrThrow();

	const changeLegalEntity = ({
		legalEntityMasterKey,
		mode,
	}: {
		legalEntityMasterKey: string;
		mode: LegalEntityMode;
	}) => {
		navigate({
			pathname: `/${companyDomain.id}`,
			search: `?legal_entity=${legalEntityMasterKey}&legal_entity_mode=${mode}`,
		});
	};

	const legacySelectedLegalEntityKey = new URLSearchParams(search).get(
		"legal_entity",
	);

	const urlSelectedLegalEntitity = legacySelectedLegalEntityKey
		? getByLeMasterKey(legacySelectedLegalEntityKey)
		: null;

	const fallbackLegalEntity =
		legalEntities.length > 0 ? legalEntities[0] : null;

	const selectedLegalEntity =
		(legalEntityId ? getById(legalEntityId) : urlSelectedLegalEntitity) ??
		fallbackLegalEntity;

	return {
		legalEntity: selectedLegalEntity,
		changeLegalEntity,
		type: legalEntityId ? "default" : "legacy",
	};
};

export const useCurrentLegalEntityOrThrow = () => {
	const parent = useCurrentLegalEntity();

	invariant(parent.legalEntity);

	return {
		...parent,
		legalEntity: parent.legalEntity,
	};
};
