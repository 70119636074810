import type { Builder } from "../../types.js";
import {
	buildGetHierarchyEndpoint,
	buildCreateHierarchyGroupEndpoint,
	buildUpdateHierarchyGroupEndpoint,
	buildRemoveHierarchyGroupEndpoint,
} from "../../utils/hierarchy.js";
import type { Project } from "./types.js";

export const buildHierarchyEndpoints = (builder: Builder) => ({
	getProjectHierarchy: buildGetHierarchyEndpoint<Project>(
		"Project",
		"project",
		builder,
	),
	createProjectHierarchyGroup: buildCreateHierarchyGroupEndpoint<Project>(
		"Project",
		"project",
		builder,
	),
	updateProjectHierarchyGroup: buildUpdateHierarchyGroupEndpoint<Project>(
		"Project",
		"project",
		builder,
	),
	removeProjectHierarchyGroup: buildRemoveHierarchyGroupEndpoint<Project>(
		"Project",
		"project",
		builder,
	),
});
