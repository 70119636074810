import { clsx } from "clsx";
import {
	type ChangeEvent,
	useRef,
	useCallback,
	useEffect,
	type Ref,
} from "react";
import {
	type FieldValues,
	type Control,
	useController,
	type FieldPath,
	type UseControllerProps,
	type Path,
} from "react-hook-form";
import { mergeRefs } from "../../../common/utils/mergeRefs";
import {
	Input,
	type InputProps,
} from "../../../common/components/atoms/input/Input";
import { numberFormatter, numberFormatterNoFractions } from "../utils";
import { useDebouncedCallback } from "src/common/utils/hooks/useDebouncedCallback.js";
import type { Except } from "type-fest";
import { isDefined } from "../../../common/utils/filters/isDefined.ts";
import { trimValue } from "./amountInputUtils.ts";

const customErrors = {
	NotANumber: "Inte ett giltligt belopp",
};

type RHFAmountInputProps<TFieldValues extends FieldValues> = Except<
	InputProps,
	"value" | "hint" | "aria-invalid"
> & {
	control: Control<TFieldValues>;
	name: FieldPath<TFieldValues>;
	controlProps?:
		| Omit<
				UseControllerProps<TFieldValues, Path<TFieldValues>>,
				"control" | "name"
		  >
		| undefined;
	debounceOnChange?: number | undefined;
	ref?: Ref<HTMLInputElement> | undefined;
} & (
		| {
				showFractions?: never;
				customFormatter: (value: number | null) => string;
		  }
		| { showFractions?: boolean | undefined; customFormatter?: never }
	);

export const RHFAmountInput = <TFieldValues extends FieldValues>({
	control,
	name,
	onBlur,
	onFocus,
	onChange,
	className,
	controlProps,
	debounceOnChange,
	customFormatter,
	showFractions,
	ref: customRef,
	...other
}: RHFAmountInputProps<TFieldValues>) => {
	const { field, fieldState } = useController({
		control,
		name,
		...controlProps,
		rules: {
			...controlProps?.rules,
			validate: (value, formValues) => {
				if (Number.isNaN(value)) {
					return customErrors.NotANumber;
				}

				if (typeof controlProps?.rules?.validate === "function") {
					return controlProps.rules.validate(value, formValues);
				}
				return true;
			},
		},
	});

	const getDisplayValue = useCallback(
		(value: number | null) => {
			return typeof customFormatter === "function"
				? customFormatter(value)
				: value === null
					? ""
					: (showFractions
							? numberFormatter
							: numberFormatterNoFractions
						).format(value);
		},
		[customFormatter, showFractions],
	);

	// always holds the real value(without amount conversion)
	const realValue = useRef(field.value === null ? null : Number(field.value));

	const inputRef = useRef<HTMLInputElement | null>(null);
	const ref = mergeRefs([field.ref, inputRef, customRef].filter(isDefined));

	const setInputValue = useCallback((value: string) => {
		const input = inputRef.current;
		if (input) {
			input.value = value;
		}
	}, []);

	// listen and apply changes from react hook form
	useEffect(() => {
		realValue.current = field.value === null ? null : Number(`${field.value}`);
		if (Number.isNaN(realValue.current)) {
			realValue.current = null;
		}
		const inputEl = inputRef.current;
		if (inputEl === document.activeElement) {
			if (inputEl && inputEl.value !== "-") {
				setInputValue(realValue.current === null ? "" : `${realValue.current}`);
			}
		} else {
			setInputValue(getDisplayValue(realValue.current));
		}
	}, [field.value, getDisplayValue, setInputValue]);

	const changeHandler = useDebouncedCallback(
		(event: ChangeEvent<HTMLInputElement>, value: number | null) => {
			field.onChange(value);
			onChange?.(event);
		},
		debounceOnChange ?? 0,
	);

	return (
		<Input
			className={clsx(className, "text-right")}
			onBlur={(event) => {
				onBlur?.(event);
				field.onBlur();
				const numberValue = trimValue(event.target.value);
				if (Number.isNaN(numberValue)) {
					setInputValue(getDisplayValue(null));
				} else if (numberValue !== null) {
					setInputValue(getDisplayValue(numberValue));
				}
			}}
			ref={ref}
			onFocus={(event) => {
				onFocus?.(event);

				// is this isNaN check really necessarry?
				if (realValue.current !== null && !Number.isNaN(realValue.current)) {
					let convertedValue = `${realValue.current}`;

					// replace the decimal separator(.) with swedish separator(,)
					convertedValue = convertedValue.replace(".", ",");
					setInputValue(convertedValue);

					// this is needed so that the "select all on focus" works
					event.target.value = convertedValue;
				}

				event.target.select();
			}}
			onChange={(event) => {
				// remove bad characters
				let input = event.target.value.replaceAll(/[^0-9\s,.\-−]+/g, "");
				let hasDotOrComma = false;
				let hasMinusSign = false;
				const toDeleteFromInput = [];

				for (let i = 0; i < input.length; ++i) {
					// find the index of all commas or dots(expect the first one)
					if (input[i] === "." || input[i] === ",") {
						if (hasDotOrComma) {
							toDeleteFromInput.push(i);
							continue;
						}
						hasDotOrComma = true;
					} else if (input[i] === "-" || input[i] === "−") {
						// if there already is a minus sign in the input or the minus
						// sign is not at the first position, mark it for deletion
						if (hasMinusSign || i !== 0) {
							toDeleteFromInput.push(i);
						}
						hasMinusSign = true;
					}
				}

				// delete all commas and dots but one
				for (let i = toDeleteFromInput.length - 1; i >= 0; --i) {
					input =
						input.slice(0, toDeleteFromInput[i]) +
						input.slice(toDeleteFromInput[i] + 1);
				}

				// convert comma and remove whitespace... making it convertable to a numeric value
				const valueAsNumber = input === "-" ? null : trimValue(input);

				changeHandler(event, valueAsNumber);
				realValue.current = valueAsNumber;

				setInputValue(input);
			}}
			hint={fieldState.error?.message}
			autoComplete="off"
			aria-invalid={fieldState.error?.message !== undefined}
			{...other}
		/>
	);
};
