import { useTranslation } from "react-i18next";
import {
	useCreateCostCenterHierarchyGroupMutation,
	useRemoveCostCenterHierarchyGroupMutation,
	useGetCostCenterHierarchyQuery,
	useUpdateCostCenterHierarchyGroupMutation,
} from "../../../common/service/nexus/nexusApi.js";
import { EntityHierarchyEditor } from "../components/EntityHierarchyEditor.js";
import { EntityHierarchyLayout } from "../components/EntityHierarchyLayout.js";
import { EntityHierarchyProvider } from "../EntityHierarchyProvider.js";
import { useCurrentHierarchyMode } from "../hooks/useCurrentHierarchyMode.js";

export const CostCenterHierarchyPage = () => {
	const { t } = useTranslation();
	const { hierarchyType } = useCurrentHierarchyMode();

	return (
		<EntityHierarchyLayout title={t("Cost centers")}>
			<EntityHierarchyProvider
				labels={{
					entityName: t("Cost center"),
					entityNamePlural: t("Cost centers"),
				}}
				useHierarchyQuery={useGetCostCenterHierarchyQuery}
				createGroupMutation={useCreateCostCenterHierarchyGroupMutation()}
				updateGroupMutation={useUpdateCostCenterHierarchyGroupMutation()}
				removeGroupMutation={useRemoveCostCenterHierarchyGroupMutation()}
				getItemLabel={(item) => item.name}
				entityColumns={[
					{ key: "code", label: t("Code") },
					{ key: "name", label: t("Name") },
				]}
				type={hierarchyType}
			>
				<EntityHierarchyEditor />
			</EntityHierarchyProvider>
		</EntityHierarchyLayout>
	);
};
