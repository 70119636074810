import { useContext } from "react";
import { EntityHierarchyContext } from "./EntityHierarchyProvider";

export const useEntityHierarchy = () => {
	const context = useContext(EntityHierarchyContext);

	if (!context) {
		throw new Error(
			"useEntityHierarchy must be used within an EntityHierarchyProvider",
		);
	}

	return context;
};
