import { useParams } from "react-router-dom";
import { invariant } from "src/common/utils/invariant.js";
import { useCurrentCompanyDomain } from "src/common/company-domain/useCurrentCompanyDomain.js";
import { useGetPlanQuery } from "./api/planApi.js";
import { useCurrentLegalEntityOrThrow } from "../../common/legal-entities/useCurrentLegalEntity.js";

export const useCurrentPlan = () => {
	const { companyDomain } = useCurrentCompanyDomain();
	const { legalEntity } = useCurrentLegalEntityOrThrow();
	const { planId } = useParams();

	invariant(planId);

	const {
		data: plan,
		isLoading,
		isError,
	} = useGetPlanQuery({
		planId,
		companyDomainId: companyDomain.id,
		legalEntityId: legalEntity.id,
	});

	return { planId, plan, isLoading, isError };
};

export const useCurrentPlanOrThrow = () => {
	const currentPlan = useCurrentPlan();
	invariant(currentPlan.plan);

	return currentPlan.plan;
};
