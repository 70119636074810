import { useTranslation } from "react-i18next";
import { useCurrentCompanyDomain } from "src/common/company-domain/useCurrentCompanyDomain.js";

import {
	showErrorNotification,
	showSuccessNotification,
} from "components/notifications/events";
import { Button } from "components/button/Button.js";
import { useForm } from "react-hook-form";
import { type ReactNode, useEffect, useId, type Ref } from "react";
import { Label } from "components/label/Label.js";
import { DialogBody } from "components/dialog/DialogBody.js";
import { DialogTitle } from "components/dialog/DialogTitle.js";
import { DialogContent } from "components/dialog/DialogContent.js";
import { DialogActions } from "components/dialog/DialogActions.js";
import { Dialog } from "components/dialog/Dialog.js";
import { DialogTrigger } from "components/dialog/DialogTrigger.js";
import type { DomainIncomingBalance } from "@exopengithub/planning-api-shared";
import { useUpdateIncomingBalanceForPlanMutation } from "../api/incomingBalanceApi.ts";
import { useCurrentPlanOrThrow } from "../useCurrentPlan.ts";
import { RHFAmountInput } from "../components/RHFAmountInput.tsx";

interface Props {
	incomingBalance: DomainIncomingBalance;
	children: ReactNode;
}

export const EditIncomingBalanceDialog = ({
	incomingBalance,
	children,
}: Props) => {
	const { t } = useTranslation();
	const { companyDomain } = useCurrentCompanyDomain();
	const plan = useCurrentPlanOrThrow();

	const formId = useId();
	const inputId = useId();

	const [updateIncomingBalance] = useUpdateIncomingBalanceForPlanMutation();

	const form = useForm({
		defaultValues: {
			amount: incomingBalance.amount,
		},
	});

	useEffect(() => {
		form.reset({
			amount: incomingBalance.amount,
		});
	}, [form, incomingBalance]);

	return (
		<Dialog
			onClose={() => {
				form.reset();
			}}
		>
			<DialogTrigger asChild>{children}</DialogTrigger>
			<DialogContent>
				{({ close, initialElementRef }) => {
					return (
						<>
							<DialogTitle>{t("Update incoming balance")}</DialogTitle>
							<DialogBody>
								<form
									id={formId}
									onSubmit={form.handleSubmit(async (data) => {
										const result = await updateIncomingBalance({
											companyDomainId: companyDomain.id,
											planId: incomingBalance.planId,
											accountId: incomingBalance.accountId,
											amount: data.amount,
											legalEntityId: plan.nexusLegalEntityId,
										});
										if ("error" in result) {
											showErrorNotification({
												message: t("Failed to update incoming balance"),
											});
										} else {
											showSuccessNotification({
												message: t("The incoming balance was updated"),
											});
											close();
										}
									})}
								>
									<Label htmlFor={inputId}>{t("Enter incoming balance")}</Label>
									<RHFAmountInput
										control={form.control}
										name="amount"
										id={inputId}
										controlProps={{
											rules: {
												required: t("This field is required"),
											},
										}}
										showFractions
										ref={initialElementRef as Ref<HTMLInputElement>}
										containerProps={{ className: "mb-4" }}
									/>
								</form>
							</DialogBody>
							<DialogActions withCancelButton>
								<Button
									type="submit"
									form={formId}
									isLoading={form.formState.isSubmitting}
								>
									{t("Save")}
								</Button>
							</DialogActions>
						</>
					);
				}}
			</DialogContent>
		</Dialog>
	);
};
