import type { SelectOption } from "./selectTypes.ts";
import {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	useFloating,
	autoUpdate,
	shift,
	flip,
	offset,
	size,
	useListNavigation,
	useClick,
	useDismiss,
	useRole,
	useInteractions,
	useTransitionStyles,
} from "@floating-ui/react";
import { useVirtualizer } from "@tanstack/react-virtual";
import memoize from "fast-memoize";

const mapOptions = memoize(
	(options: SelectOption[] | ReadonlyArray<SelectOption>) => {
		return options.map((option, index) => {
			return { ...option, index };
		});
	},
);

export const useSelect = ({
	withFilter,
	keepOpenAfterSelection,
	disabled,
	options,
	value,
	onClose,
	onChange,
	maxWidth,
}: {
	withFilter: boolean;
	keepOpenAfterSelection: boolean;
	disabled: boolean;
	options: SelectOption[] | ReadonlyArray<SelectOption>;
	value: string | null;
	onClose?: (() => void) | undefined;
	onChange?: ((value: string | null) => void) | undefined;
	maxWidth: number;
}) => {
	const hasFilter = withFilter && options.length >= 15;
	const [isOpen, setIsOpen] = useState(false);
	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const selectedIndex = useMemo(() => {
		const index = options.findIndex((option) => option.value === value);
		if (index === -1) {
			return null;
		}
		return index;
	}, [options, value]);

	const { refs, floatingStyles, context } = useFloating({
		placement: "bottom-start",
		open: isOpen,
		onOpenChange: (open) => {
			setIsOpen(open);
			if (!open) {
				onClose?.();
			}
		},
		whileElementsMounted: autoUpdate,
		middleware: [
			shift(),
			flip(),
			offset({ mainAxis: 4 }),
			size({
				apply({ rects, elements }) {
					Object.assign(elements.floating.style, {
						width: `${Math.max(rects.reference.width, maxWidth)}px`,
					});
				},
			}),
		],
	});

	const [inputValue, setInputValue] = useState("");

	useEffect(() => {
		if (!isOpen) {
			setInputValue("");
		}
	}, [isOpen]);

	const elementsRef = useRef<Array<HTMLElement | null>>([]);

	const handleSelect = useCallback(
		(index: number | null) => {
			if (index === null) {
				onChange?.(null);
			} else {
				onChange?.(options[index].value);
			}
			if (keepOpenAfterSelection && options.length !== 1) {
				return;
			}
			setIsOpen(false);
		},
		[keepOpenAfterSelection, onChange, options],
	);

	const listNav = useListNavigation(context, {
		listRef: elementsRef,
		activeIndex,
		selectedIndex,
		onNavigate: setActiveIndex,
		virtual: true,
		scrollItemIntoView: { block: "center" },
		enabled: !disabled,
	});
	const click = useClick(context, { enabled: !disabled });
	const dismiss = useDismiss(context, { enabled: !disabled });
	const role = useRole(context, { role: "combobox", enabled: !disabled });

	const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
		[listNav, click, dismiss, role],
	);

	const selectContext = useMemo(
		() => ({
			activeIndex,
			selectedIndex,
			getItemProps,
			handleSelect,
		}),
		[activeIndex, selectedIndex, getItemProps, handleSelect],
	);

	const { styles: transitionStyles, isMounted } = useTransitionStyles(context, {
		duration: 100,
		initial({ side }) {
			return {
				opacity: 0,
				transform: `translate3d(0, ${(side === "top" ? 1 : -1) * 8}px, 0)`,
			};
		},
	});

	const items = useMemo(() => {
		const mappedOptions = mapOptions(options);
		if (inputValue.length === 0) {
			return mappedOptions;
		}

		return mappedOptions.filter((option) => {
			return option.label
				.toLocaleLowerCase()
				.includes(inputValue.toLocaleLowerCase());
		});
	}, [inputValue, options]);

	useEffect(() => {
		if (isOpen) {
			setActiveIndex(items.length > 0 ? items[0].index : null);
		}
	}, [isOpen, items]);

	const parentRef = useRef<HTMLDivElement | null>(null);
	const inputRef = useRef<HTMLInputElement | null>(null);

	const rowVirtualizer = useVirtualizer({
		count: items.length,
		getScrollElement: () => parentRef.current,
		estimateSize: () => 32,
	});

	useLayoutEffect(() => {
		if (selectedIndex && isOpen && isMounted) {
			setTimeout(() => {
				rowVirtualizer.scrollToIndex(selectedIndex, { align: "center" });
			}, 0);
		}
	}, [isMounted, isOpen, rowVirtualizer, selectedIndex]);

	return {
		items,
		selectedIndex,
		activeIndex,
		handleSelect,
		setActiveIndex,
		setInputValue,
		inputValue,
		isMounted,
		selectContext,
		floatingContext: context,
		floatingStyles,
		transitionStyles,
		getFloatingProps,
		getReferenceProps,
		refs,
		hasFilter,
		inputRef,
		parentRef,
		rowVirtualizer,
		elementsRef,
	};
};
