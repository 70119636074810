import { createContext, type ReactNode } from "react";
import { type UseDialogOptions, useDialog } from "./useDialog.js";

type ContextType = ReturnType<typeof useDialog> | null;

export const DialogContext = createContext<ContextType>(null);

export function Dialog({
	children,
	...options
}: {
	children: ReactNode;
} & UseDialogOptions) {
	const dialog = useDialog(options);
	return (
		<DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>
	);
}
