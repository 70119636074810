import { useDialog } from "../dialog/useDialog.ts";

export interface UseDrawerOptions {
	initialOpen?: boolean;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
	position?: "left" | "right";
	onClose?(): void;
	closeOnOutsidePress?: boolean;
}

export function useDrawer({
	initialOpen = false,
	open,
	onOpenChange,
	position = "left",
	onClose,
	closeOnOutsidePress = true,
}: UseDrawerOptions = {}) {
	return {
		...useDialog({
			initialOpen,
			open,
			onOpenChange,
			onClose,
			outsidePress: closeOnOutsidePress,
		}),
		position,
	};
}
