export interface AddDataSourceInputs {
	operation: "new" | "add";
	companyDomainName: string;
	companyDomainId: string | null;
	approve: boolean;
}

export const getDefaultValuesForAddDataSource = (
	operation: AddDataSourceInputs["operation"],
): AddDataSourceInputs => {
	return {
		operation: operation,
		companyDomainName: "",
		companyDomainId: null,
		approve: false,
	};
};
