import { AppError } from "./features/appError/AppError";
import { ErrorBoundary } from "react-error-boundary";
import { NotificationsProvider } from "./common/components/atoms/notifications/NotificationProvider";
import { Provider } from "react-redux";
import { store } from "./common/redux/store";
import { ProvideLocale } from "./locales/ProvideLocale";
import { FloatingTree } from "@floating-ui/react";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router.js";

export const App = () => {
	return (
		<FloatingTree>
			<Provider store={store}>
				<ErrorBoundary FallbackComponent={AppError}>
					<NotificationsProvider>
						<ProvideLocale>
							<RouterProvider router={router} />
						</ProvideLocale>
					</NotificationsProvider>
				</ErrorBoundary>
			</Provider>
		</FloatingTree>
	);
};
