import { clsx } from "clsx";
import { Text, type TextProps } from "components/typography/Text";
import {
	forwardRef,
	type ReactNode,
	type ComponentPropsWithoutRef,
} from "react";
import { SortingArrowButton2 } from "src/features/planning/components/SortingArrowButton";
import type { UseSortColumnProps } from "src/common/utils/hooks/useSort";

type GridHeaderProps = ComponentPropsWithoutRef<"div"> & {
	children?: ReactNode | undefined;
};

export const GridHeader = forwardRef<HTMLDivElement, GridHeaderProps>(
	function GridHeader({ children, className, ...props }, ref) {
		return (
			<div
				ref={ref}
				className={clsx(
					"sticky top-0 z-20 border-b border-b-grey-200 bg-grey-50 px-3",
					className,
				)}
				{...props}
			>
				{children}
			</div>
		);
	},
);

type GridHeaderLabelProps = ComponentPropsWithoutRef<"div"> & {
	endAdornment?: ReactNode;
	children?: ReactNode | undefined;
	align?: "left" | "right";
	afterLabel?: ReactNode;
	textWeight?: TextProps["weight"] | undefined;
};

export const GridHeaderLabel = forwardRef<HTMLDivElement, GridHeaderLabelProps>(
	function GridHeaderLabel(
		{
			children,
			endAdornment,
			afterLabel,
			align = "left",
			textWeight = "medium",
			...props
		},
		ref,
	) {
		return (
			<GridHeader ref={ref} {...props}>
				<div
					className={clsx(
						"flex w-full items-center gap-1 py-3",
						align === "left" ? "justify-start" : "justify-end",
					)}
				>
					<Text
						size="xs"
						weight={textWeight}
						color="text-grey-500"
						className="truncate"
					>
						{children}
					</Text>
					{endAdornment}
				</div>
				{afterLabel}
			</GridHeader>
		);
	},
);

type GridHeaderLabelWithSortingButtonProps = GridHeaderLabelProps & {
	sortingProps?: UseSortColumnProps | undefined;
};

export const GridHeaderLabelWithSortingButton = forwardRef<
	HTMLDivElement,
	GridHeaderLabelWithSortingButtonProps
>(function GridHeaderLabelWithSortingButton(
	{ sortingProps, endAdornment, children, className, ...props },
	ref,
) {
	return (
		<GridHeaderLabel
			{...props}
			className={clsx("group", className)}
			ref={ref}
			endAdornment={
				<>
					{endAdornment}
					{sortingProps && <SortingArrowButton2 {...sortingProps} />}
				</>
			}
		>
			{children}
		</GridHeaderLabel>
	);
});
